<template>
  <div id="componentServices">
    <ServicesTable
      ref="serviceTable"
      :data="tableObject"
      :method="serviceupsertActions"
      :syncmethod="syncServices"
    />
    <ServiceDialog
      v-if="flages.displayDialog"
      ref="serviceDialog"
      :flages="flages"
      :handleCancel="toggleDialog"
      :handleSave="upsertTaskservice"
      :data="dialogObject"
    />
    <ConfirmationDialog
      v-else-if="flages.confirmdisplayDialog"
      ref="confirmLawfirmDialog"
      :flages="flages"
      :handleCancel="toggleConfirmationDialog"
      :handleSave="activateDeactivateService"
      :data="dialogObject"
    />
    <ViewDialog
      v-else-if="flages.viewDialog"
      ref="viewDialog"
      :flages="flages"
      :handleCancel="toggleViewDialog"
      :data="dialogObject"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import _ from "lodash";
import ServicesTable from "../Shared/FetchDataTable";
import ServiceDialog from "../Shared/UpsertDialog";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import ViewDialog from "../Shared/ViewDialog";
export default {
  name: "servicesComponent",
  created() {
    this.fetchServices();
    this.fetchSubOfferingTypes();
    this.fetchLawfirms();
  },
  components: { ServicesTable, ServiceDialog, ConfirmationDialog, ViewDialog },
  methods: {
    fetchCountries() {
      var that = this;
      this.$store
        .dispatch("country/fetchCountries")
        .then(() => {
          that.countries = this.$store.getters["country/getCountries"];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchIncidentTypes() {
      var that = this;
      this.$store
        .dispatch("incidentType/fetchincidentTypes")
        .then(() => {
          that.incidentTypes = this.$store.getters[
            "incidentType/getIncidentTypes"
          ];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchLawfirms() {
      let that = this;
      this.$store
        .dispatch("lawfirm/fetchlawfirms")
        .then(() => {
          that.lawfirms = this.$store.getters["lawfirm/getlawfirms"];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchSubOfferingTypes() {
      var that = this;
      this.$store
        .dispatch("subofferingType/fetchSubofferingTypes")
        .then(() => {
          that.subofferingTypes = this.$store.getters[
            "subofferingType/getSubofferingTypes"
          ];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchServices() {
      var that = this;
      that.tableObject.isloading = true;
      that.tableObject.records = null;
      this.$store.dispatch("incidentType/fetchincidentTypes").then(() => {
        that.incidentTypes =
          that.$store.getters["incidentType/getIncidentTypes"];
        that.$store
          .dispatch("service/fetchServices")
          .then(() => {
            that.response = this.$store.getters["service/getServices"];
            _.each(that.response, (element) => {
              _.each(that.incidentTypes, (melement) => {
                if (melement.slug == element.incidentTypeSlug) {
                  element.fieldTitle = melement.title;
                }
              });
            });
            that.tableObject.records = _.clone(that.response);
            that.tableObject.isloading = false;
            console.log(that.tableObject);
          })
          .catch((error) => {
            console.log(that.tableObject);
          });
      });
    },
    syncServices() {
      var that = this;
      that.tableObject.isloading = true;
      that.tableObject.records = null;
      this.$store
        .dispatch("service/syncServices")
        .then(() => {
          this.fetchServices();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateService(service) {
      let that = this;
      this.$store
        .dispatch("service/updateService", service)
        .then(() => {
          this.flages.displayDialog = false;
          that.fetchServices();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleDialog() {
      this.flages.displayDialog = !this.flages.displayDialog;
      this.flages.viewDialog = false;
    },
    toggleViewDialog() {
      this.flages.viewDialog = !this.flages.viewDialog;
    },
    toggleConfirmationDialog() {
      this.flages.confirmdisplayDialog = !this.flages.confirmdisplayDialog;
      this.flages.viewDialog = false;
    },
    toggleNestedDisplayDialog(overwrite, type, rootdata, nestedtype) {
      let that = this;
      this.dialogObject.record = _.clone(overwrite);
      let lawfirms = this.lawfirms;
      let incidentTypes = this.incidentTypes;
      let subofferingTypes = this.subofferingTypes;
      let lawfirm = this.dialogObject.record.lawfirmSlug;
      let incidentType = this.dialogObject.record.incidentTypeSlug;
      let subofferingType = this.dialogObject.record.subofferingTypeSlug;
      this.flages.displayDialog = !this.flages.displayDialog;
      this.dialogObject.rootObject = rootdata;
      this.dialogObject.lawfirms = lawfirms;
      this.dialogObject.incidentTypes = incidentTypes;
      this.dialogObject.subofferingTypes = subofferingTypes;
      this.dialogObject.actionType = type;
      this.dialogObject.nestedtype = nestedtype;
      // dropdown
    },
    toggleNestedConfirmationDialog(overwrite, type, rootdata, nestedtype) {
      this.flages.confirmdisplayDialog = !this.flages.confirmdisplayDialog;
      this.dialogObject.record = overwrite;
      this.dialogObject.rootObject = rootdata;
      this.dialogObject.actionType = type;
      this.dialogObject.nestedtype = nestedtype;
    },
    serviceupsertActions(service, type, nestedtype) {
      let that = this;
      let selectedService = _.clone(service);
      that.dialogObject.record = _.clone(selectedService);
      that.dialogObject.actionType = type;
      that.dialogObject.options = that.incidentTypes;
      that.dialogObject.lawfirms = that.lawfirms;
      that.dialogObject.type = type;
      that.dialogObject.subofferingTypes = that.subofferingTypes;
      that.invokeServiceAction(type, selectedService, "");
    },
    upsertTaskservice(data, records, tablerecords, nestedtype) {
      //we are using tablerecords only for overviews
      let that = this;
      let actionType = _.clone(data.actionType);
      let selectedservice = _.clone(data.record);
      let type = actionType == "Add" ? "saveTaskservice" : "updateTaskservice";
      let service =
        actionType == "nestededit" || actionType == "nestedadd"
          ? _.clone(
              data.rootObject["data"]
                ? data.rootObject["data"]
                : data.rootObject
            )
          : _.clone(data.record);
      service.actionType = actionType;
      if (actionType == "nestededit") {
        // No Two-way binding so we are doing here
        selectedservice.incidentTypeSlug =
          typeof selectedservice.incidentTypeSlug != "string"
            ? selectedservice.incidentTypeSlug.slug
            : selectedservice.incidentTypeSlug;
        selectedservice.subofferingTypeSlug =
        selectedservice.subofferingTypeSlug?
          typeof selectedservice.subofferingTypeSlug != "string"
            ? selectedservice.subofferingTypeSlug.slug
            : selectedservice.subofferingTypeSlug:selectedservice.subofferingTypeSlug;
        selectedservice.lawfirmSlug =
        selectedservice.lawfirmSlug?
          typeof selectedservice.lawfirmSlug != "string"
            ? selectedservice.lawfirmSlug.slug
            : selectedservice.lawfirmSlug:selectedservice.lawfirmSlug;
        selectedservice.isDisabled =
          selectedservice.isDisabled == "Active"
            ? true
            : selectedservice.isDisabled == "InActive"
            ? false
            : selectedservice.isDisabled;
        if (nestedtype == "overwritesview") {
          //service overview overwrites
          console.log(service, data, tablerecords);
          _.each(
            service["rootObject"].overViewOverWrites,
            (overViewOverWrite, index) => {
              if (
                overViewOverWrite.incidentTypeSlug ==
                selectedservice.incidentTypeSlug
              ) {
                service["rootObject"].overViewOverWrites[
                  index
                ] = selectedservice;
              }
            }
          );
        } else {
          //service title overwrites
          _.each(service.titleOverWrites, (overwrite, index) => {
            if (
              overwrite.incidentTypeSlug == selectedservice.incidentTypeSlug
            ) {
              service.titleOverWrites[index] = selectedservice;
            }
          });
        }
      } else if (actionType == "Edit") {
        service.incidentTypeSlug = service.fieldTitle?.slug;
      } else if (actionType == "nestedadd") {
        _.each(tablerecords, (record) => {
          service.overViewOverWrites = service.overViewOverWrites || [];
          service.overViewOverWrites.push(record);
        });
      }
      that.invokeServiceAction(
        type,
        service,
        records,
        tablerecords,
        nestedtype
      );
    },
    updateTaskservice(type, service, records, tablerecords, nestedtype) {
      let that = this;
      if (nestedtype == "overwritesview") {
        let selectedservice = service.rootObject;
        console.log(selectedservice);
        that.saveTaskservice(type, selectedservice);
      } else {
        service.order = parseInt(service.order);
        if (service.actionType == "Add Title Overwrite") {
          service.titleOverWrites = service.titleOverWrites || [];
          _.each(tablerecords, (overwtite) => {
            service.titleOverWrites.push(overwtite);
          });
        } else if (service.actionType == "Add Overview") {
          service.overView = service.overView || [];
          _.each(records, (overview) => {
            service.overView.push(overview.title);
          });
        }
        //  else if (service.actionType == "nestededit") {
        //   service = service.rootObject;
        // }
        that.saveTaskservice(type, service);
      }
    },
    saveTaskservice(type, service, records) {
      let that = this;
      this.$store
        .dispatch("service/updateService", service)
        .then(() => {
          this.flages.displayDialog = false;
          this.flages.viewDialog = false;
          that.fetchServices();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    activateDeactivateService(data) {
      let that = this;
      let overwriterecord = _.clone(data.record);
      let response = data;
      let type = data.actionType;
      let selectedrecord = that.dialogObject.record;
      if (data.actionType == "nesteddelete") {
        let rootservice = response.rootObject["data"]
          ? response.rootObject["data"]
          : response.rootObject.rootObject;
        if (data.nestedtype == "overwritesview") {
          _.each(rootservice.overViewOverWrites, (toverwrite) => {
            toverwrite.title == overwriterecord.title
              ? (toverwrite.isActive = !toverwrite.isActive)
              : toverwrite;
          });
        } else {
          _.each(rootservice.titleOverWrites, (toverwrite) => {
            toverwrite.incidentTypeSlug == overwriterecord.incidentTypeSlug &&
            toverwrite.title == overwriterecord.title
              ? (toverwrite.isActive = !toverwrite.isActive)
              : toverwrite;
          });
        }
        that.saveTaskservice(type, rootservice);
        that.flages.confirmdisplayDialog = false;
      } else {
        let service = _.clone(data.record);
        service.isActive = !service.isActive;
        that.saveTaskservice(type, service);
        that.flages.confirmdisplayDialog = false;
      }
    },
    invokeServiceAction(type, service, records, nestedtype) {
      let that = this;
      switch (type) {
        case "ActivateDeactive":
          that.toggleConfirmationDialog();
          break;
        case "updateTaskservice":
          if (
            service.actionType != "nestedadd" &&
            service.actionType != "nestededit"
          ) {
            if (that.isDuplicateRecords(service, records)) {
              that.errorMessage();
            } else {
              that.updateTaskservice(type, service, records, nestedtype);
            }
          } else {
            that.updateTaskservice(type, service, records, nestedtype);
          }
          break;
        case "saveTaskservice":
          that.saveTaskservice(type, service, records);
          break;
        case "view":
          that.toggleViewDialog();
          break;
        case "overwritesview":
          that.toggleViewDialog();
          break;
        default:
          that.toggleDialog();
          break;
      }
    },
    isDuplicate(servicerecord, type) {
      let that = this;
      let record = servicerecord;
      let oldrecords = that.selectedService.titleOverWrites || [];
      let response = _.some(
        oldrecords,
        (oldrecord) => oldrecord.incidentTypeSlug == record.incidentTypeSlug && oldrecord.subofferingTypeSlug ==record.subofferingTypeSlug
      );
      response ? (that.error = "Incident Type and Suboffering Type combination should be unique") : (that.error = "");
      return response;
    },
    isDuplicateRecords(service, records) {
      let that = this;
      let results = [];
      let servicerecords =
        service.actionType == "nestedadd"
          ? service.overViewOverWrites
          : records;
      that.selectedService = service;
      _.each(servicerecords, (record) =>
        results.push(that.isDuplicate(record, service.actionType))
      );
      let response = _.some(results);
      return response;
    },
    errorMessage() {
      this.$toast.add({
        severity: "error",
        summary: "Error Message",
        detail: `${this.error}`,
        life: 2000,
      });
    },
  },
  props: {},
  watch: {},
  data() {
    return {
      response: null,
      error: null,
      selectedService: null,
      countries: null,
      incidentTypes: null,
      lawfirms: null,
      subofferingTypes: null,
      tableObject: {
        syncbtn: "Sync Services",
        header: "Services With Sowlution Application",
        columns: [
          { field: "slug", header: "Slug", class: "sslug" },
          { field: "title", header: "Name", class: "sname" },
          { field: "order", header: "Order", class: "order" },
          { field: "isActive", header: "Status", class: "status" },
          { field: "actions", header: "Actions", class: "actions" },
        ],
        records: null,
        isexpandable: true,
        isloading: false,
        issync: true,
        isedit: true,
        isview: true,
        addbtntooltip: "Add Title Overwrite",
        component: "service",
        incidentTypes: null,
      },
      flages: {
        displayDialog: false,
        confirmdisplayDialog: false,
        nesteddisplayDialog: false,
        viewDialog: false,
      },
      dialogObject: {
        record: null,
        options: [],
        lawfirms: null,
        subofferingTypes: [],
        incidentTypes: [],
        actionType: null,
        component: "Service",
        isdrop: true,
        type: null,
        nestedtype: null,
      },
    };
  },
};
</script>

<style scoped>
</style>