import Api from './Api';
const endPoint = "serviceconfig";

const getServiceConfigs = serviceconfigs => Api.get(`${endPoint}`, serviceconfigs);
const postServiceConfig = serviceconfig => Api.post(`${endPoint}`, serviceconfig);
const putServiceConfig= serviceconfig => Api.put(`${endPoint}`, serviceconfig);

export{
    getServiceConfigs,
    postServiceConfig,
    putServiceConfig
}