<template>
  <div id="componentLawfirms">
    <LawfirmsTable
      ref="lawfirmsTable"
      :data="tableObject"
      :method="lawfirmActions"
      :syncmethod="syncLawfirms"
    />
    <LawfirmDialog
      v-if="flages.displayDialog"
      ref="lawfrimDialog"
      :flages="flages"
      :handleCancel="toggleDialog"
      :handleSave="upsertLawfirm"
      :data="dialogObject"
    />
    <ConfirmationDialog
      v-else-if="flages.confirmdisplayDialog"
      ref="confirmLawfirmDialog"
      :flages="flages"
      :handleCancel="toggleConfirmationDialog"
      :handleSave="activateDeactivateLawfirm"
      :data="dialogObject"
    />
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import _ from "lodash";
import LawfirmsTable from "../Shared/FetchDataTable";
import LawfirmDialog from "../Shared/UpsertDialog";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
export default {
  name: "lawfirmsComponent",
  created() {
    this.fetchLawfirms();
  },
  components: { LawfirmsTable, LawfirmDialog, ConfirmationDialog },
  methods: {
    handleSave() {
      alert("F");
    },
    fetchLawfirms() {
      let that = this;
      let lawfirmfilter;
      that.tableObject.isloading = true;
      that.tableObject.records = null;
      this.$store.dispatch("country/fetchCountries").then(() => {
        that.countryResponse = this.$store.getters["country/getCountries"];
        this.$store
          .dispatch("lawfirm/fetchlawfirms", lawfirmfilter)
          .then(() => {
            that.response = this.$store.getters["lawfirm/getlawfirms"];
            that.tableObject.records = _.clone(that.response);
            _.each(that.response, (element) => {
              _.each(that.countryResponse, (melement) => {
                if (melement.slug == element.countrySlug) {
                  element.fieldTitle = melement.title;
                }
              });
            });
            that.tableObject.isloading = false;
          })
          .catch((error) => {
            console.log(error);
          });
        that.dialogObject.options = _.clone(that.countryResponse);
      });
    },
    fetchCountries() {
      let that = this;
      this.$store.dispatch("country/fetchCountries").then(() => {
        that.countryResponse = this.$store.getters["country/getCountries"];
        that.dialogObject.options = _.clone(that.countryResponse);
      });
    },
    lawfirmActions(lawfirm, type) {
      let that = this;
      let selectedLawfirm = _.clone(lawfirm);
      that.fetchCountries();
      that.dialogObject.record = _.clone(selectedLawfirm);
      that.dialogObject.actionType = type;
      that.invokeLawfirm(type, selectedLawfirm, "");
    },
    toggleDialog(data) {
      this.flages.displayDialog = !this.flages.displayDialog;
      if (data.actionType == "nestededit") {
        this.fetchLawfirms();
      }
    },
    toggleNestedDisplayDialog(attorney, type, rootdata,index) {
      this.flages.displayDialog = !this.flages.displayDialog;
      this.dialogObject.record = attorney;
      this.dialogObject.rootObject = rootdata;
      this.dialogObject.actionType = type;
      this.selectedindex=index;
    },
    toggleNestedConfirmationDialog(attorney, type, rootdata) {
      this.flages.confirmdisplayDialog = !this.flages.confirmdisplayDialog;
      this.dialogObject.record = attorney;
      this.dialogObject.rootObject = rootdata;
      this.dialogObject.actionType = type;
    },
    toggleConfirmationDialog() {
      this.flages.confirmdisplayDialog = !this.flages.confirmdisplayDialog;
    },
    upsertLawfirm(data, records) {
      let that = this;
      let actionType = _.clone(data.actionType);
      let type = actionType == "Add" ? "savelawfirm" : "updatelawfirm";
      let lawfirm =
        actionType == "nestededit"
          ? _.clone(data.rootObject["data"])
          : _.clone(data.record);
      let attorneys = _.clone(records);
      if (actionType == "nestededit") {
        if (!that.isAttorneyError(attorneys)) {
          // For Nested Edit Based on index
          that.invokeLawfirm(type, lawfirm, attorneys, actionType);
        }
      } else {
        that.invokeLawfirm(type, lawfirm, attorneys, actionType);
      }
    },
    activateDeactivateLawfirm(data) {
      let that = this;
      let response = data;
      let lawfirmrecord = data.record;
      if (data.actionType == "nesteddelete") {
        let rootlawfirm = response.rootObject["data"];
        let attorney = lawfirmrecord;
        attorney.isActive = !attorney.isActive;
        _.each(rootlawfirm.attorneys, (lawattorney) => {
          lawattorney.name == attorney.name
            ? (lawattorney = attorney)
            : lawattorney;
        });
        that.saveLawfirms(rootlawfirm);
      } else {
        let lawfirm = lawfirmrecord;
        lawfirm.isActive = !lawfirm.isActive;
        that.saveLawfirms(lawfirm);
      }
      that.toggleConfirmationDialog();
    },
    updateLawfirm(lawfirm, type, actionType) {
      // Toupdate Lawfirm we need country
      let that = this;
      let selectedLawfirm = _.clone(lawfirm);
      let countrySlug =
        actionType == "Edit" ? lawfirm.fieldTitle?.slug : lawfirm.countrySlug;
      if (countrySlug != null) {
        // that.dialogObject.record = _.clone(selectedLawfirm);
        that.saveLawfirms(lawfirm, type, lawfirm.attorneys,actionType);
        // that.dialogObject.actionType = type;
      }
    },
    syncLawfirms() {
      var that = this;
      that.tableObject.isloading = true;
      that.tableObject.records = null;
      this.$store
        .dispatch("lawfirm/synclawfirms")
        .then(() => {
          this.fetchLawfirms();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveLawfirms(lawfirm, type, attorneys,actionType) {
      var that = this;
      if(actionType!="nestededit"){
      lawfirm.countrySlug =
        type == "updatelawfirm"
          ? lawfirm.fieldTitle.slug
            ? lawfirm.fieldTitle.slug
            : lawfirm.fieldTitle
          : lawfirm.countrySlug;
      }
      if (type == "savelawfirm") {
        lawfirm.attorneys = lawfirm.attorneys || [];
        _.each(attorneys, (attorney) => {
          lawfirm.attorneys.push(attorney);
        });
      }
      this.$store
        .dispatch("lawfirm/updatelawfirm", lawfirm)
        .then(() => {
          that.flages.displayDialog = false;
          that.fetchLawfirms();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    invokeLawfirm(type, lawfirm, attorneys, actionType) {
      let that = this;
      that.selectedLawfirm = lawfirm;
      switch (type) {
        case "ActivateDeactive":
          that.toggleConfirmationDialog();
          break;
        case "updatelawfirm":
          that.updateLawfirm(lawfirm, type, actionType);
          break;
        case "savelawfirm":
        that.saveLawfirms(lawfirm, type, attorneys);
          break;
        default:
          that.toggleDialog();
          break;
      }
    },
    isAttorneyError(attorneys) {
      return attorneys.some(
        (record) =>
          record.city != "" &&
          record.email != "" &&
          record.name != "" &&
          record.state != "" &&
          record.streetAddress != "" &&
          record.title != "" &&
          record.zipcode != "" &&
          record.city != undefined &&
          record.email != undefined &&
          record.name != undefined &&
          record.state != undefined &&
          record.streetAddress != undefined &&
          record.title != undefined &&
          record.zipcode != undefined &&
          record.city != null &&
          record.email != null &&
          record.name != null &&
          record.state != null &&
          record.streetAddress != null &&
          record.title != null &&
          record.zipcode != null
      );
    },
    // isDuplicate(attorney) {
    //   let that = this;
    //   let record = attorney;
    //   let oldrecords = that.selectedLawfirm.attorneys||[];
    //   let response = _.some(oldrecords, (oldrecord) =>
    //       oldrecord.title == record.title
    //   );
    //   response
    //     ? (that.error = "Title or Slug should be unique")
    //     : (that.error = "");
    //   return response;
    // },
    // isDuplicateRecords(attorneys) {
    //   let that = this;
    //   let results = [];
    //   let records = attorneys;
    //   _.each(records, (record) => results.push(that.isDuplicate(record)));
    //   let response = _.some(results);
    //   return response;
    // },
    errorMessage() {
      this.$toast.add({
        severity: "error",
        summary: "Error Message",
        detail: `${this.error}`,
        life: 2000,
      });
    },
  },
  props: {},
  watch: {},
  data() {
    return {
      error: null,
      response: null,
      selectedindex:null,
      countryResponse: null,
      selectedLawfirm: null,
      tableObject: {
        header: "Lawfirms With Sowlution Application",
        columns: [
          { field: "slug", header: "Slug" },
          { field: "title", header: "Name" },
          { field: "fieldTitle", header: "Country" },
          { field: "isActive", header: "Status" },
          { field: "actions", header: "Actions" },
        ],
        records: null,
        isexpandable: true,
        isloading: true,
        issync: true,
        isedit: true,
        component: "lawfirm",
      },
      flages: {
        displayDialog: false,
        confirmdisplayDialog: false,
        nesteddisplayDialog: false,
      },
      dialogObject: {
        record: null,
        options: [],
        actionType: null,
        component: "Lawfirm",
        isdrop: true,
        nestedtype: null,
      },
    };
  },
};
</script>

<style scoped>
</style>