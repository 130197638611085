import Api from './Api';
const endPoint = "Lawfirms";

const getlawfirms = lawfirmfilter => Api.get(`${endPoint}/lawfirms`,{ params: {filter: lawfirmfilter }});
const synclawfirms = synclawfirms => Api.get(`${endPoint}`, synclawfirms);
const putlawfirm = synclawfirm => Api.put(`${endPoint}`, synclawfirm);

export {
    getlawfirms,
    synclawfirms,
    putlawfirm
}