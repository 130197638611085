<template>
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar :class="topbarClass" @menu-toggle="onMenuToggle" />

        <transition name="layout-sidebar">
            <div :class="sidebarClass" @click="onSidebarClick" v-show="isSidebarVisible()">
                <AppProfile />
                <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
            </div>
        </transition>

        <div class="layout-main">
            <router-view />
        </div>

        <AppFooter />
    </div>
</template>
<script>
    import AppTopBar from '@/components/layout/AppTopbar.vue';
    import AppProfile from '@/components/layout/AppProfile.vue';
    import AppMenu from '@/components/layout/AppMenu.vue';
    import AppFooter from '@/components/layout/AppFooter.vue';

    export default {
        name: 'Default',
        methods: {
            onWrapperClick() {
                if (!this.menuClick) {
                    this.overlayMenuActive = false;
                    this.mobileMenuActive = false;
                }

                this.menuClick = false;
            },
            onMenuToggle() {
                this.menuClick = true;

                if (this.isDesktop()) {
                    if (this.layoutMode === 'overlay') {
                        this.overlayMenuActive = !this.overlayMenuActive;
                    }
                    else if (this.layoutMode === 'static') {
                        this.staticMenuInactive = !this.staticMenuInactive;
                    }
                }
                else {
                    this.mobileMenuActive = !this.mobileMenuActive;
                }

                event.preventDefault();
            },
            onSidebarClick() {
                this.menuClick = true;
            },
            onMenuItemClick(event) {
                if (event.item && !event.item.items) {
                    this.overlayMenuActive = false;
                    this.mobileMenuActive = false;
                }
            },
            addClass(element, className) {
                if (element.classList)
                    element.classList.add(className);
                else
                    element.className += ' ' + className;
            },
            removeClass(element, className) {
                if (element.classList)
                    element.classList.remove(className);
                else
                    element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            },
            isDesktop() {
                return window.innerWidth > 1024;
            },
            isSidebarVisible() {
                if (this.isDesktop()) {
                    if (this.layoutMode === 'static')
                        return !this.staticMenuInactive;
                    else if (this.layoutMode === 'overlay')
                        return this.overlayMenuActive;
                    else
                        return true;
                }
                else {
                    return true;
                }
            },
        },
        computed: {
            containerClass() {
                return ['layout-wrapper', {
                    'layout-overlay': this.layoutMode === 'overlay',
                    'layout-static': this.layoutMode === 'static',
                    'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                    'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                    'layout-mobile-sidebar-active': this.mobileMenuActive
                }];
            },
            sidebarClass() {
                return ['layout-sidebar', {
                    'layout-sidebar-dark': this.layoutColorMode === 'dark',
                    'layout-sidebar-light': this.layoutColorMode === 'light'
                }];
            },
            topbarClass() {
                return ['layout-topbar', {
                    'layout-topbar-dark': this.layoutColorMode === 'dark',
                }];
            }
        },
        data() {
            return {
                layoutMode: 'static',
                layoutColorMode: 'light',
                staticMenuInactive: false,
                overlayMenuActive: false,
                mobileMenuActive: false,
                menu: [
                    { label: 'Countries', icon: 'pi pi-globe', to: '/countries' },
                    { label: 'Addresses', icon: 'pi pi-map-marker', to: '/addresses' },
                    { label: 'Authorities', icon: 'pi pi-user-plus', to: '/authorities' },
                    { label: 'DocumentTypes', icon: 'pi pi-file', to: '/documenttypes' },
                    { label: 'IncidentTypes', icon: 'pi pi-key', to: '/incidenttypes' },
                    { label: 'SubOfferings', icon: 'pi pi-tags', to: '/subofferings' },
                    { label: 'Lawfirms', icon: 'pi pi-users', to: '/lawfirms' },
                    { label: 'Services', icon: 'pi pi-th-large', to: '/services' },
                    { label: 'MasterTemplates', icon: 'pi pi-lock', to: '/mastertemplates' },
                    { label: 'MasterServiceConfig', icon: 'pi pi-th-large', to: '/masterserviceconfig' },
                    { label: 'TemplateConfig', icon: 'pi pi-lock', to: '/templateconfiguration' },
                    { label: 'BAA&NDAConfig', icon: 'pi pi-lock', to: '/baandaconfiguration' },
                    { label: 'ServiceConfig', icon: 'pi pi-th-large', to: '/serviceconfiguration' }

                ]
            }
        },
        watch: {
            $route() {
                this.menuActive = false;
                this.$toast.removeAllGroups();
            }
        },
        beforeUpdate() {
            if (this.mobileMenuActive)
                this.addClass(document.body, 'body-overflow-hidden');
            else
                this.removeClass(document.body, 'body-overflow-hidden');
        },
        components: {
            'AppTopBar': AppTopBar,
            'AppProfile': AppProfile,
            'AppMenu': AppMenu,
            'AppFooter': AppFooter,
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
