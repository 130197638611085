import Api from './Api';
const endPoint = "authority";

const getAuthorities = authority => Api.get(`${endPoint}/authorities`, authority);
const postAuthorities = authority => Api.post(`${endPoint}`, authority);
const putAuthorities = authority => Api.put(`${endPoint}`, authority);

export{
    getAuthorities,
    postAuthorities,
    putAuthorities
}