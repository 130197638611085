<template>
  <div class="layout-profile">
    <div>
      <img src="@/assets/layout/images/arete-icon.png" class="avatar" />
    </div>
    <span class="p-link layout-profile-link layout-menu-title">
      Welcome, {{ username }}
    </span>
    <p class="logout" @click="this.logout"><span class="pi pi-sign-out"> Logout </span></p>
  </div>
</template>

<script>
export default {
  computed: {
    username() {
      return this.$store.getters["login/getUsername"];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("login/logout").then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>

<style scoped>
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
p.logout{
  cursor: pointer !important;
}
</style>