/* eslint-disable no-unused-vars */
import {
    getSubofferingTypes,
    postSubofferingTypes,
    putSubofferingType
} from '../../services/SubofferingTypes.Service'

const state = {
    subofferingTypes: {}
}
const getters = {
    getSubofferingTypes(state) {
        return state.subofferingTypes;
    }
}
const actions = {
    fetchSubofferingTypes({ commit }) {
        return new Promise((resolve, reject) => {
            getSubofferingTypes().then(response => {
                if (response.status == 200) {
                    commit('setSubofferingTypes', response.data.data);
                    resolve();
                }
            }).catch((error) => {
                reject(error)
            })
        });
    },
    saveSubofferingTypes({ commit }, subofferingTypes) {
        return new Promise((resolve, reject) => {
            postSubofferingTypes(subofferingTypes).then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error)
            });
        });
    },
    updateSubofferingType({ commit }, document) {
        return new Promise((resolve, reject) => {
            putSubofferingType(document).then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error);
            })
        });
    }

}
const mutations = {
    setSubofferingTypes(state, response) {
        state.subofferingTypes = response;
    }
}
export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}