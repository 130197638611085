/* eslint-disable no-unused-vars */
import {
    getIncidentTypes,
    syncincidentTypes,
    putIncidentType
} from '../../services/IncidentTypes.Service';

const state = {
    incidentTypes: {}
}
const getters = {
    getIncidentTypes(state) {
        return state.incidentTypes;
    }
}
const actions = {
    fetchincidentTypes({ commit }) {
        return new Promise((resolve, reject) => {
            getIncidentTypes().then(response => {
                if (response.status == 200) {
                    commit('setincidentTypes', response.data.data);
                    resolve();
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    syncincidentTypes({ commit }) {
        return new Promise((resolve, reject) => {
            syncincidentTypes().then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    updateIncidentType({ commit }, incidentType) {
        return new Promise((resolve, reject) => {
            putIncidentType(incidentType).then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error);
            })
        });
    }    
}
const mutations = {
    setincidentTypes(state, response) {
        state.incidentTypes = response;
    }
}

export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}