<template>
  <div id="servicescopesTable">
    <DataTable
      :scrollable="true"
      scrollHeight="flex"
      sortMode="multiple"
      removableSort
      :value="data.records ? data.records : data.inputs"
      :expandedRows.sync="data.expandedRows"
      dataKey="title"
      class="p-datatable-gridlines"
    >
      <Column
        :expander="true"
        headerStyle="width: 3rem"
        :headerClass="'thheader'"
      />
      <Column
        field="slug"
        header="Slug"
        :sortable="true"
        :headerClass="'thheader'"
      >
      </Column>
      <Column
        field="title"
        header="Title"
        :sortable="true"
        :headerClass="'thheader'"
      >
      </Column>
      <Column
        field="description"
        header="Description"
        :sortable="true"
        :headerClass="'thheader'"
      >
      </Column>
      <Column
        field="shouldBeHidden"
        header="ShouldBeHidden"
        :sortable="true"
        :headerClass="'thheader'"
      >
      </Column>
      <Column :headerClass="'thheader'" header="Actions">
        <template #body="slotProps">
          <Button
            icon="pi pi-plus"
            v-tooltip.top="'Add Inputs'"
            @click="add(slotProps.data)"
            class="p-button-raised p-button-details p-mr-3"
          />
          <Button
            icon="pi pi-pencil"
            v-tooltip.top="'Edit Section'"
            @click="edit(slotProps.data)"
            class="p-button-raised p-button-info p-mr-2"
          />
          <Button
            icon="pi pi-trash"
            v-if="slotProps.data.isActive"
            v-tooltip.top="'Deactivate Scope'"
            @click="activatedeactive(slotProps.data)"
            class="p-button-raised p-button-warning p-mr-2"
          />
          <Button
            v-else-if="!slotProps.data.isActive"
            icon="pi pi-check-circle"
            v-tooltip.top="'Activate Scope'"
            @click="activatedeactive(slotProps.data)"
            class="p-button-raised p-button-success p-mr-2"
          />
        </template>
      </Column>
      <template #expansion="slotProps">
        <NestedScopeDataTable
          ref="nestedScopeDataTable"
          :data="slotProps.data"
          :add="handleAddScopeInputInputs"
          :edit="handleEditScopeInput"
          :activatedeactive="handleActivateDeactive"
        />
      </template>
    </DataTable>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import NestedScopeDataTable from "../Shared/NestedScopeDataTable";
import _ from "lodash";
export default {
  name: "ServiceScopes",
  components: { NestedScopeDataTable },
  beforeMount() {},
  created() {},
  methods: {
    onRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Service Tasks Expanded",
        detail: event.data.title,
        life: 3000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Service Tasks Collapsed",
        detail: event.data.title,
        life: 3000,
      });
    },
    handleAddScopeInputInputs(servicescope) {
      this.$parent.$parent.handleAddScopeInputs(servicescope);
    },
    handleEditScopeInput(servicescope) {
      this.$parent.$parent.handleEditScopeInput(servicescope);
    },
    handleActivateDeactive(servicescope) {
      this.$parent.$parent.handleActivateDeactive(servicescope);
    },
  },
  props: {
    data: { type: Object },
    add: { type: Function },
    edit: { type: Function },
    activatedeactive: { type: Function },
  },
  watch: {},
  data() {
    return {};
  },
};
</script>
<style>
</style>
