/* eslint-disable no-unused-vars */
import Vue from 'vue';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './assets/layout/layout.scss';

//prime vue components
import Ripple from 'primevue/ripple';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup'; 
import InputSwitch from 'primevue/inputswitch';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import TreeTable from 'primevue/treetable';
import Calendar from 'primevue/calendar';
import Sidebar from 'primevue/sidebar';
import OverlayPanel from 'primevue/overlaypanel';
import RadioButton from 'primevue/radiobutton';
import AutoComplete from 'primevue/autocomplete';
import MultiSelect from 'primevue/multiselect';
import Toolbar from 'primevue/toolbar';
import Panel from 'primevue/panel';
import Card from 'primevue/card';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import Paginator from 'primevue/paginator';
import Message from 'primevue/message';
import ToggleButton from 'primevue/togglebutton';

Vue.directive('tooltip', Tooltip);
Vue.component('Button', Button);
Vue.component('Menu', Menu);
Vue.component('Toast', Toast);
Vue.component('DataTable', DataTable);
Vue.component('Column', Column);
Vue.component('ColumnGroup', ColumnGroup);
Vue.component('InputSwitch', InputSwitch);
Vue.component('Dialog', Dialog);
Vue.component('InputText', InputText);
Vue.component('Dropdown', Dropdown);
Vue.component('Dialog', Dialog);
Vue.component('Textarea', Textarea);
Vue.component('TreeTable', TreeTable);
Vue.component('Calendar', Calendar);
Vue.component('Sidebar', Sidebar);
Vue.component('OverlayPanel', OverlayPanel);
Vue.component('TabView', TabView);
Vue.component('TabPanel', TabPanel);
Vue.component('RadioButton', RadioButton);
Vue.component('AutoComplete', AutoComplete);
Vue.component('MultiSelect', MultiSelect);
Vue.component('Toolbar', Toolbar);
Vue.component('Panel', Panel);
Vue.component('Card', Card);
Vue.component('BlockUI',BlockUI)
Vue.component('ProgressSpinner',ProgressSpinner)
Vue.component('Paginator',Paginator)
Vue.component('Message',Message)
Vue.component('ToggleButton',ToggleButton)
Vue.use(ToastService);
//Vue.directive('ripple', Ripple);

export default {
    name: "primevue"
};