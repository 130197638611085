<template>
<div id="confimationdialogComponent">
    <Dialog :visible.sync="flages.confirmdisplayDialog" :style="{width: '450px'}" header="Confirm" :modal="true" :closable="false">
    <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Are you sure you want to {{data.record.isActive ? 'Delete' :'Activate'}} <b>{{data.record.title}}</b>?</span>
    </div>
    <template #footer>
        <Button label="Canel" icon="pi pi-times" class="p-button-text" @click="handleCancel()"/>
        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="handleSave(data)" />
    </template>
</Dialog>
</div>
</template>
<script>
export default {
  name: "confimationdialogComponent",
  components: { },
  created() {},
  methods: {},
  props: {
    flages: { type: Object },
    handleCancel: { type: Function },
    handleSave: { type: Function },
    data: { type: Object },
  },
  watch: {},
  data() {
    return {
    };
  },
};
</script>
<style>

</style>