<template>
  <div id="componentSubofferingTypes">
    <SubofferingTypesTable
      ref="subofferingTypesTable"
      :data="tableObject"
      :method="subofferingTypeActions"
    />
    <SubofferingTypeDialog
      v-if="flages.displayDialog"
      ref="subofferingDialog"
      :flages="flages"
      :handleCancel="toggleDialog"
      :handleSave="upsertSubOfferingType"
      :data="dialogObject"
    />
    <ConfirmationDialog
      v-else-if="flages.confirmdisplayDialog"
      ref="confirmSubTypeDialog"
      :flages="flages"
      :handleCancel="toggleConfirmationDialog"
      :handleSave="activateDeativateSubOfferingType"
      :data="dialogObject"
    />
  </div>
</template>

<script>
import _ from "lodash";
import SubofferingTypesTable from "../Shared/FetchDataTable";
import SubofferingTypeDialog from "../Shared/UpsertDialog";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
export default {
  name: "subofferingTypesComponent",
  created() {
    this.fetchSubOfferingTypes();
  },
  components: {
    SubofferingTypesTable,
    SubofferingTypeDialog,
    ConfirmationDialog,
  },
  methods: {
    fetchSubOfferingTypes() {
      var that = this;
      that.flages.displayDialog =false;
      that.tableObject.isloading = true;
      that.tableObject.records = null;
      this.$store.dispatch("incidentType/fetchincidentTypes").then(() => {
        that.incidentTypeResponse = this.$store.getters[
          "incidentType/getIncidentTypes"
        ];
        this.$store
          .dispatch("subofferingType/fetchSubofferingTypes")
          .then(() => {
            that.response = this.$store.getters[
              "subofferingType/getSubofferingTypes"
            ];
            _.each(that.response, (element) => {
              _.each(that.incidentTypeResponse, (melement) => {
                if (melement.slug == element.incidentTypeSlug) {
                  element.fieldTitle = melement.title;
                }
              });
            });
            that.tableObject.records = _.clone(that.response);
            that.tableObject.isloading = false;
          })
          .catch((error) => {
            console.log(error);
          });
        that.dialogObject.options = _.clone(that.incidentTypeResponse);
      });
    },
    fetchIncidentTypes() {
      let that = this;
      this.$store.dispatch("incidentType/fetchincidentTypes").then(() => {
        that.incidentTypeResponse = this.$store.getters[
          "incidentType/getIncidentTypes"
        ];
        that.dialogObject.options = _.clone(that.incidentTypeResponse);
      });
    },
    subofferingTypeActions(subType, type) {
      let that = this;
      let selectedSubType = _.clone(subType);
      that.dialogObject.record = _.clone(selectedSubType);
      that.dialogObject.actionType = type;
      that.fetchIncidentTypes();
      that.invokeSubOfferingType(type, subType, "");
    },
    toggleDialog() {
      this.flages.displayDialog = !this.flages.displayDialog;
    },
    toggleConfirmationDialog() {
      this.flages.confirmdisplayDialog = !this.flages.confirmdisplayDialog;
    },
    upsertSubOfferingType(data, records) {
      let that = this;
      let subType = _.clone(data);
      let record = subType.record;
      let subTypes = _.clone(records);
      let actionType = _.clone(subType.actionType);
      let type = actionType == "Add" ? "savesubtypes" : "updatesubtype";
      if (record.title != "") {
        that.invokeSubOfferingType(type, subType, subTypes);
      }
    },
    activateDeativateSubOfferingType(data) {
      let that = this;
      let subType = _.clone(data.record);
      subType.isActive = !subType.isActive;
      that.updateSubOfferingType(subType);
      that.toggleConfirmationDialog();
    },
    updateSubOfferingType(subType) {
      let that = this;
      //From Object To String
      subType.incidentTypeSlug
        ? (subType.incidentTypeSlug = subType.fieldTitle.slug
            ? subType.fieldTitle.slug
            : subType.incidentTypeSlug)
        : subType;
      this.$store
        .dispatch("subofferingType/updateSubofferingType", subType)
        .then(() => {
          that.fetchSubOfferingTypes();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveSubOfferingTypes(subType) {
      let that = this;
      this.$store
        .dispatch("subofferingType/saveSubofferingTypes", subType)
        .then(() => {
          that.toggleDialog();
          that.fetchSubOfferingTypes();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    invokeSubOfferingType(type, subType, subTypes) {
      let that = this;
      switch (type) {
        case "ActivateDeactive":
          that.toggleConfirmationDialog();
          break;
        case "updatesubtype":
          if (that.isDuplicate(subType.record,"update")) {
            that.errorMessage();
          } else {
            that.updateSubOfferingType(subType.record);
          }
          break;
        case "savesubtypes":
          if (that.isDuplicateRecords(subTypes)) {
            that.errorMessage();
          } else {
            that.saveSubOfferingTypes(subTypes);
          }
          break;
        default:
          that.toggleDialog();
          break;
      }
    },
    isDuplicate(subType,type) {
      let that = this;
      let record = subType;
      let oldrecords = that.tableObject.records;
      let response = _.some(
        oldrecords,
        (oldrecord) =>        
        type == "update"
          ? oldrecord.title == record.title
          : oldrecord.slug == record.slug || oldrecord.title == record.title
      );
      response
        ? (that.error = "Title or Slug should be unique")
        : (that.error = "");
      return response;
    },
    isDuplicateRecords(subTypes) {
      let that = this;
      let results = [];
      let records = subTypes;
      _.each(records, (record) => results.push(that.isDuplicate(record)));
      let response = _.some(results);
      return response;
    },
    errorMessage() {
      this.$toast.add({
        severity: "error",
        summary: "Error Message",
        detail: `${this.error}`,
        life: 2000,
      });
    },
  },
  props: {},
  watch: {},
  data() {
    return {
      response: null,
      error: null,
      incidentTypeResponse: null,
      tableObject: {
        header: "Suboffering Types With Sowlution Application",
        columns: [
          { field: "slug", header: "Slug" },
          { field: "title", header: "Name" },
          { field: "fieldTitle", header: "IncidentTypeTitle" },
          { field: "isActive", header: "Status" },
          { field: "actions", header: "Actions" },
        ],
        records: null,
        isexpandable: false,
        isloading: true,
        isysnc: false,
        isedit: true,
        component: "subofferingtype",
      },
      flages: {
        displayDialog: false,
        confirmdisplayDialog: false,
      },
      dialogObject: {
        record: null,
        options: [],
        actionType: null,
        component: "SubofferingType",
        isdrop: true,
      },
    };
  },
};
</script>

<style scoped>
</style>