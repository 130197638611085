/* eslint-disable no-unused-vars */
import { getMasterServiceConfigs, postMasterServiceConfig, } from '../../services/MasterServiceConfig.Service';

const state = {
    masterserviceconfigs: {}
}
const getters = {
    getMasterServiceConfigs(state) {
        return state.masterserviceconfigs
    }
}
const actions = {
    saveMasterServiceConfig({ commit }, masterserviceconfigs) {
        return new Promise((resolve, reject) => {
            postMasterServiceConfig(masterserviceconfigs).then(response => {
                if (response.status== 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error)
            });
        });
    },
    fetchMasterServiceConfigs({ commit }) {
        return new Promise((resolve, reject) => {
            getMasterServiceConfigs().then(response => {
                if (response.status ==200) {
                    commit('setMasterServiceConfigs', response.data.data);
                    resolve();
                }
            }).catch(error => {
                reject(error);
            })
        });
    }
}
const mutations = {
    setMasterServiceConfigs(state, response) {
        state.masterserviceconfigs = response;
    },

}

export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}