import Vue from 'vue';
import router from './router';
import App from './App.vue';
import icons from "./icons";
import primevue from "./primevue";
import store from './store/index';
import LoadingBars from './components/LoadingBars';
import LoadingArete from './components/LoadingArete';
import LoginLoadingArete from './components/LoginLoadingArete';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import BootstrapVue from "bootstrap-vue";

import { parseISO, format } from 'date-fns';

Vue.filter("formatDateTime", value => {
    if (value) {
        return format(parseISO(value), 'MM/dd/yy hh:mm a');
    }
});
Vue.filter("formatStatus", value => {
    return value ? 'Active' : 'InActive';
});
Vue.filter("disableStatus", value => {
    return value ? 'Active' : 'InActive';
});
Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.filter("lawfirmCountry", value => {
    console.log(value);
    return "USA"
})
Vue.filter("changeComponent", value => {
    if (value == "SubofferingType" || value == "Service") {
        return "IncidentType"
    } else if (value == "Lawfirm") {
        return "Country";
    } else if (value == "IncidentType") {
        return "Authority"
    } else {
        return value;
    }
})
icons.load();
Vue.component("primevue", primevue);
Vue.component('Loading', LoadingBars);
Vue.component('LoadingArete', LoadingArete);
Vue.component('LoginLoadingArete', LoginLoadingArete);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(BootstrapVue);
Vue.config.productionTip = false;
Vue.config.devtools = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
