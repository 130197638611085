import Vue from 'vue';
import Vuex from 'vuex';
import LoginModule from './modules/login.module';
import CountryModule from './modules/Countries.module';
import DocumentTypeModule from './modules/DocumentTypes.module';
import IncidentTypeModule from './modules/IncidentTypes.module';
import SubofferingTypeModule from './modules/SubofferingTypes.module';
import LawfirmModule from './modules/Lawfirms.module';
import MasterTemplateModule from './modules/MasterTemplate.module';
import ServiceModule from './modules/Services.module';
import TemplateConfigurationModule from './modules/TemplateConfigs.module';
import BaaNdaModule from './modules/BaaNdaConfig.module'
import ServiceConfigModule from './modules/ServiceConfigs.module';
import AuthoritiesModule from './modules/Authorities.module';
import MasterServiceConfigModule from './modules/MasterServiceConfig.module';
import AddressAndBankDetailsModule from './modules/AddressAndBankDetails.module';
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        login: LoginModule,
        country:CountryModule,
        documentType:DocumentTypeModule,
        incidentType:IncidentTypeModule,
        subofferingType:SubofferingTypeModule,
        lawfirm:LawfirmModule,
        masterTemplate:MasterTemplateModule,
        service:ServiceModule,
        templateConfiguration:TemplateConfigurationModule,
        baanda:BaaNdaModule,
        serviceconfig:ServiceConfigModule,
        authority:AuthoritiesModule,
        addressandbankdetails: AddressAndBankDetailsModule,
        masterserviceconfig:MasterServiceConfigModule,
    }
});
