/* eslint-disable no-unused-vars */
import { add } from 'lodash';
import {
    getDetails, putDetails, postDetails
} from '../../services/AddressAndBankDetails.Service'

const state = {
    details: {}
}
const getters = {
    getDetail(state) {
        return state.details;
    }
}
const actions = {
    fetchDetails({ commit }) {
        return new Promise((resolve, reject) => {
            getDetails().then(response => {
                if (response.status == 200) {
                    commit('setDetails', response.data.data);
                    resolve();
                }
            }).catch((error) => {
                reject(error)
            })
        });
    },
    saveDetails({ commit }, details) {
        return new Promise((resolve, reject) => {
            postDetails(details).then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error)
            });
        });
    },
    updateDetails({ commit }, details) {
        return new Promise((resolve, reject) => {
            putDetails(details).then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error);
            })
        });
    }

}
const mutations = {
    setDetails(state, response) {
        state.details = response;
    }
}
export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}