/* eslint-disable no-unused-vars */
import { getDocumentType, postDocumentType, putDocumentType } from '../../services/DocumentTypes.Service';

const state = {
    documenttypes: {},
    selectedDocumentTypes: {}
}
const getters = {
    getDocumentType(state) {
        return state.documenttypes
    },
    getSelectedDocument(state) {
        return state.selectedDocumentTypes
    }
}
const actions = {
    saveDocumentType({ commit }, documents) {
        return new Promise((resolve, reject) => {
            postDocumentType(documents).then(response => {
                if (response.status== 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error)
            });
        });
    },
    fetchDocumentType({ commit }) {
        return new Promise((resolve, reject) => {
            getDocumentType().then(response => {
                if (response.status ==200) {
                    commit('setDocumentTypes', response.data.data);
                    resolve();
                }
            }).catch(error => {
                reject(error);
            })
        });
    },
    updateDocumentType({ commit }, document) {
        return new Promise((resolve, reject) => {
            putDocumentType(document).then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error);
            })
        });
    }
}
const mutations = {
    setDocumentTypes(state, response) {
        state.documenttypes = response;
    },
    selectedDocumentTypes(state, document) {
        state.selectedDocumentTypes = document;
    }
}

export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}