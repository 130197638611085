<template>
  <div id="componentCountries">
    <CountriesTable
      ref="countriesTable"
      :data="tableObject"
      :method="countriesActions"
      :syncmethod="syncCountries"
    />
    <ConfirmationDialog
      v-if="flages.confirmdisplayDialog"
      ref="confidocumentTypeDialog"
      :flages="flages"
      :handleCancel="toggleConfirmationDialog"
      :handleSave="activateDeactivateCountry"
      :data="dialogObject"
    />
  </div>
</template>

<script>
import _ from "lodash";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import CountriesTable from "../Shared/FetchDataTable";
export default {
  name: "countriesComponent",
  created() {
    this.fetchCountries();
  },
  components: { CountriesTable, ConfirmationDialog },
  methods: {
    fetchCountries() {
      var that = this;
      that.tableObject.isloading = true;
      that.tableObject.records = null;
      this.$store
        .dispatch("country/fetchCountries")
        .then(() => {
          that.response = this.$store.getters["country/getCountries"];
          that.tableObject.records = _.clone(that.response);
          that.tableObject.isloading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    syncCountries() {
      var that = this;
      that.tableObject.isloading = true;
      that.tableObject.records = null;
      this.$store
        .dispatch("country/syncCountries")
        .then(() => {
          this.fetchCountries();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleConfirmationDialog() {
      this.flages.confirmdisplayDialog = !this.flages.confirmdisplayDialog;
    },
    countriesActions(country, type) {
      let that = this;
      let selectedCountry = _.clone(country);
      that.dialogObject.record = _.clone(selectedCountry);
      that.dialogObject.actionType = type;
      that.toggleConfirmationDialog();
    },
    activateDeactivateCountry(data) {
      let that = this;
      let country = _.clone(data.record);
      country.isActive = !country.isActive;
      that.updateCountry(country);
      that.toggleConfirmationDialog();
    },
    updateCountry(country) {
      let that = this;
      this.$store
        .dispatch("country/updateCountry", country)
        .then(() => {
          that.fetchCountries();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  props: {},
  watch: {},
  data() {
    return {
      response: null,
      tableObject: {
        header: "Countries With Sowlution Application",
        columns: [
          { field: "slug", header: "Slug" },
          { field: "title", header: "Name" },
          { field: "isActive", header: "Status" },
          { field: "actions", header: "Actions" },
        ],
        records: null,
        isexpandable: false,
        isloading: true,
        issync: true,
        isedit: false,
        component: "country",
      },
      flages: {
        displayDialog: false,
        confirmdisplayDialog: false,
      },
      dialogObject: {
        record: null,
        actionType: null,
        component: "DocumentType",
      },
    };
  },
};
</script>

<style scoped>
</style>