/* eslint-disable no-unused-vars */
import {
    getTemplateConfiguration,
    postTemplateConfiguration,
    putTemplateConfiguration
} from '../../services/TemplateConfigs.Service'

const state = {
    templateConfigurations: {}
}
const getters = {
    getTemplateConfigurations(state) {
        return state.templateConfigurations;
    }
}
const actions = {
    fetchTemplateConfigurations({ commit }) {
        return new Promise((resolve, reject) => {
            getTemplateConfiguration().then(response => {
                if (response.status == 200) {
                    commit('setTemplateConfigurations', response.data.data);
                    resolve();
                }
            }).catch((error) => {
                reject(error)
            })
        });
    },
    saveTemplateConfiguration({ commit }, templateConfiguration) {
        return new Promise((resolve, reject) => {
            postTemplateConfiguration(templateConfiguration).then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error)
            });
        });
    },
    updateTemplateConfiguration({ commit }, templateConfiguration) {
        return new Promise((resolve, reject) => {
            putTemplateConfiguration(templateConfiguration).then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error);
            })
        });
    }

}
const mutations = {
    setTemplateConfigurations(state, response) {
        state.templateConfigurations = response;
    }
}
export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}