import { library } from "@fortawesome/fontawesome-svg-core";
import './assets/layout/layout.scss';

import {
    faUserSecret,
    faFax,
    faClipboardCheck,
    faCircle,
    faGlobe,
    faFileAlt,
    faEye
  } from "@fortawesome/free-solid-svg-icons";
  import "bootstrap/dist/css/bootstrap.css";
  import "bootstrap-vue/dist/bootstrap-vue.css";
  export default {
    load: function() {
      library.add(faUserSecret);
      library.add(faFax);
      library.add(faClipboardCheck);
      library.add(faCircle);
      library.add(faGlobe);
      library.add(faFileAlt);
      library.add(faEye)
    },
  };


