<template>
    <div class="arete-loading-overlay">
        <img src="../assets/layout/images/arete-icon-transparent.svg" class="arete-loading" />
    </div>
</template>
<script>
    export default {
    }
</script>
<style scoped>
    .arete-loading-overlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        z-index: 5;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .arete-loading {
        -webkit-animation: anim 2s infinite linear;
        animation: anim 2s infinite linear;
        border-radius: 50%;
        width: 60px;
        height: 60px;
    }

    @-webkit-keyframes anim {
        from {
            -webkit-transform: rotateY(0deg);
        }

        to {
            -webkit-transform: rotateY(360deg);
        }
    }

    @keyframes anim {
        from {
            transform: rotateY(0deg);
        }

        to {
            transform: rotateY(360deg);
        }
    }
</style>
