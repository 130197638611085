/* eslint-disable no-unused-vars */
import { getCountries, syncCountries,putCountries } from '../../services/Countries.Service';

const state = {
    countries: {}
}
const getters = {
    getCountries(state) {
        return state.countries;
    }
}
const actions = {
    fetchCountries({ commit }) {
        return new Promise((resolve, reject) => {
            getCountries().then(response => {
                if (response.status == 200) {
                    commit('setCountries', response.data.data);
                    resolve();
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    syncCountries({ commit }) {
        return new Promise((resolve, reject) => {
            syncCountries().then(response => {
                if (response.status == 200) {
                    commit('setCountries', response.data.data);
                    resolve();
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    updateCountry({ commit }, country) {
        return new Promise((resolve, reject) => {
            putCountries(country).then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error);
            })
        });
    }    
}
const mutations = {
    setCountries(state, response) {
        state.countries = response;
    }
}

export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}