/* eslint-disable no-unused-vars */

import {
    getlawfirms,
    synclawfirms,
    putlawfirm
} from '../../services/Lawfirms.Service';

const state = {
    lawfirms: {}
}
const getters = {
    getlawfirms(state) {
        return state.lawfirms;
    }
}
const actions = {
    fetchlawfirms({ commit },lawfirmfilter) {
        return new Promise((resolve, reject) => {
            getlawfirms(lawfirmfilter).then(response => {
                if (response.status == 200) {
                    commit('setlawfirms', response.data.data);
                    resolve();
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    synclawfirms({ commit }) {
        return new Promise((resolve, reject) => {
            synclawfirms().then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    updatelawfirm({ commit },lawfirm) {
        return new Promise((resolve, reject) => {
            putlawfirm(lawfirm).then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
}
const mutations = {
    setlawfirms(state, response) {
        state.lawfirms = response;
    }
}
export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}