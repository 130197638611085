import Api from './Api';
const endPoint = "countries";
const fieldsendPoint = "customfields"

const getCountries = countries => Api.get(`${endPoint}`, countries);
const syncCountries = syncCountries => Api.get(`${fieldsendPoint}`, syncCountries);
const putCountries = countries => Api.put(`${endPoint}`, countries);
export {
    getCountries,
    syncCountries,
    putCountries
}