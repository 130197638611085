<template>
  <div id="componentmasterTemplateConfigs" style="height: calc(100vh - 165px)">
    <DataTable
      :scrollable="true"
      scrollHeight="flex"
      :loading="isloading"
      :filters="filters"
      sortMode="multiple"
      removableSort
      :value="masterTemplates"
      :paginator="true"
      :rows="20"
      :rowsPerPageOptions="[20, 40, 60, 80]"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      data-key="id"
      class="p-datatable-gridlines"
      :expandedRows.sync="expandedRows"
    >
      <template #header>
        <div class="p-grid">
          <div class="p-col-3 p-text-center mt-2 p-text-left">
            <span class=""><b>Master Template</b></span>
          </div>
          <div class="p-col-6 text-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                style="width: 567px !important"
                v-model="filters['global']"
                placeholder="Global Search"
              />
            </span>
          </div>
          <div class="p-col-2">
            <Button
              icon="pi pi-plus"
              label="Add New"
              v-tooltip.top="'Add New'"
              class="p-button-raised p-button-info p-mr-3"
              @click="openNew()"
            />
          </div>
        </div>
      </template>
      <template #empty> No records found. </template>
      <Column
        :expander="true"
        :headerClass="'thheader'"
        headerStyle="width: 3rem"
      />
      <Column
        field="title"
        :bodyClass="'configtitle'"
        :headerClass="'configtitle thheader'"
        header="Title"
        :sortable="true"
      ></Column>
      <Column header="Sow Documents" :headerClass="'thheader'">
        <template #body="slotProps">
          <Button
            icon="pi pi-eye"
            v-tooltip.top="'View SoW'"
            @click="documentView(slotProps.data.sowFileInfo.webURl)"
            class="p-button-raised p-button-secondary p-mr-2"
          />
          <Button
            icon="pi pi-download"
            v-tooltip.top="'Download SoW'"
            @click="handleDownload(slotProps.data.sowFileInfo)"
            class="p-button-raised p-button-secondary p-mr-2"
          />
        </template>
      </Column>
      <Column header="MSA Documents" :headerClass="'thheader'">
        <template #body="slotProps">
          <div v-if="slotProps.data.msaFileInfo != null">
            <Button
              icon="pi pi-eye"
              v-tooltip.top="'View MSA'"
              @click="documentView(slotProps.data.msaFileInfo.webURl)"
              class="p-button-raised p-button-secondary p-mr-2"
            />
            <Button
              icon="pi pi-download"
              v-tooltip.top="'Download MSA'"
              @click="handleDownload(slotProps.data.msaFileInfo)"
              class="p-button-raised p-button-secondary p-mr-2"
            />
          </div>
          <div v-else>No Documents</div>
        </template>
      </Column>
      <Column header="Amendment Documents" :headerClass="'thheader'">
        <template #body="slotProps">
          <div v-if="slotProps.data.amendmentFileInfo != null">
            <Button
              icon="pi pi-eye"
              v-tooltip.top="'View Amendment'"
              @click="documentView(slotProps.data.amendmentFileInfo.webURl)"
              class="p-button-raised p-button-secondary p-mr-2"
            />
            <Button
              icon="pi pi-download"
              v-tooltip.top="'Download Amendment'"
              @click="handleDownload(slotProps.data.amendmentFileInfo)"
              class="p-button-raised p-button-secondary p-mr-2"
            />
          </div>
          <div v-else>No Documents</div>
        </template>
      </Column>
      <Column :headerClass="'thheader'" header="Actions">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            v-tooltip.top="'Edit Template'"
            @click="editTemplate(slotProps.data)"
            class="p-button-raised p-button-info p-mr-2"
          />
          <Button
            icon="pi pi-trash"
            v-tooltip.top="'Deactivate SoW'"
            v-if="slotProps.data.isActive"
            @click="handleactivateDeactivateTemplate(slotProps.data)"
            class="p-button-raised p-button-warning p-mr-2"
          />
          <Button
            v-if="!slotProps.data.isActive"
            icon="pi pi-check-circle"
            v-tooltip.top="'Activate SoW'"
            @click="handleactivateDeactivateTemplate(slotProps.data)"
            class="p-button-raised p-button-success p-mr-2"
          />
          <Button
            icon="pi pi-plus"
            v-tooltip.top="'Add New Placehoder'"
            class="p-button-raised p-button-primary p-mr-2"
            @click="addPlaceholder(slotProps.data, 'addphmode')"
          />
          <Button
            icon="pi pi-eye"
            v-tooltip.top="'View ServiceOverview'"
            @click="viewServiceOverview(slotProps.data)"
            class="p-button-raised p-button-help p-mr-2"
          />
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="placeholdertable">
          <DataTable :value="slotProps.data.placeHolders">
            <Column
              field="title"
              header="Title"
              sortable
              :headerClass="'nthheader'"
            ></Column>
            <Column
              field="slug"
              header="Slug"
              sortable
              :headerClass="'nthheader'"
            ></Column>
            <Column
              field="type"
              header="Type"
              sortable
              :headerClass="'nthheader'"
            >
              <template #body="slotProps">{{
                formatType(slotProps.data.type)
              }}</template>
            </Column>
            <Column
              field="customField"
              header="Custom Field"
              :headerClass="'nthheader'"
              sortable
            >
              <template #body="slotProps">{{
                formatCustomFields(slotProps.data.customField)
              }}</template>
            </Column>
            <Column
              field="values"
              header="Values"
              sortable
              :headerClass="'nthheader'"
            ></Column>
            <Column :headerClass="'nthheader'">
              <template #body="slotPropPlaceholder">
                <Button
                  icon="pi pi-pencil"
                  v-tooltip.top="'Edit Placeholder'"
                  @click="editPlaceholder(slotProps, slotPropPlaceholder)"
                  class="p-button-rounded p-button-help p-mr-2"
                />
                <Button
                  icon="pi pi-trash"
                  v-tooltip.top="'Deactivate Placeholder'"
                  v-if="slotPropPlaceholder.data.isActive"
                  @click="
                    handleactivateDeactivatePlaceholder(
                      slotProps,
                      slotPropPlaceholder
                    )
                  "
                  class="p-button-rounded p-button-warning p-mr-2"
                />
                <Button
                  v-if="!slotPropPlaceholder.data.isActive"
                  icon="pi pi-check-circle"
                  v-tooltip.top="'Activate Placeholder'"
                  @click="
                    handleactivateDeactivatePlaceholder(
                      slotProps,
                      slotPropPlaceholder
                    )
                  "
                  class="p-button-rounded p-button-success p-mr-2"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
    <Dialog
      maximizable
      :visible.sync="mastertemplateDialog"
      :style="{ width: '70vw' }"
      :header="editTemplateMode ? 'Edit Template' : 'Add Template'"
      :modal="true"
      class="p-fluid"
    >
      <br />
      <div class="p-field p-col">
        <span class="p-float-label">
          <InputText
            id="title"
            v-model.trim="template.title"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !template.title }"
          />
          <label for="name">Title<span class="p-invalid">*</span></label>
        </span>
        <small class="p-error" v-if="submitted && !template.title"
          >Name is required.</small
        >
      </div>
      <div class="p-field p-col" v-if="addTemplateMode">
        <span class="p-float-label">
          <InputText
            id="slug"
            v-model.trim="template.slug"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !template.slug }"
          />
          <label for="name">Slug<span class="p-invalid">*</span></label>
        </span>
        <small class="p-error" v-if="submitted && !template.slug"
          >Slug is required.</small
        >
      </div>
      <div class="p-field p-col">
        <label for="template"
          >Sow Template<span class="p-invalid">*</span></label
        >
        <div>
          <b-form-file
            v-model="template.sowUploadFile"
            accept=".docx, .doc"
            placeholder="Choose a template file or drop it here..."
            drop-placeholder="Drop file here..."
            :class="{
              'p-invalid':
                submitted &&
                (!template.sowUploadFile || template.sowFileInfo) &&
                (template.sowUploadFile || !template.sowFileInfo),
            }"
          ></b-form-file>
          <small
            class="p-error"
            v-if="
              submitted &&
              (!template.sowUploadFile || template.sowFileInfo) &&
              (template.sowUploadFile || !template.sowFileInfo)
            "
            >Sow Upload is required.</small
          >
        </div>
      </div>
      <div class="p-field p-col">
        <label for="template"
          >MSA Template</label
        >
        <div>
          <b-form-file
            v-model="template.msaUploadFile"
            accept=".docx, .doc"
            placeholder="Choose a template file or drop it here..."
            drop-placeholder="Drop file here..."
            :class="{
              'p-invalid': submitted && !template.sowUploadFile,
            }"
          ></b-form-file>
          <small class="p-error"></small>
        </div>
      </div>
      <div class="p-field p-col">
        <label for="template">Amendment Template</label>
        <div>
          <b-form-file
            v-model="template.amendmentUploadFile"
            accept=".docx, .doc"
            placeholder="Choose a template file or drop it here..."
            drop-placeholder="Drop file here..."
            :class="{ 'p-invalid': submitted && !template.sowUploadFile }"
          ></b-form-file>
          <span class="error-message"></span>
        </div>
      </div>
      <div class="p-field p-col" v-if="addTemplateMode">
        <label class="p-mb-3">Pick DefaultPlaceholders For</label>
        <div class="p-formgrid p-grid">
          <div class="p-field-radiobutton p-col-2">
            <RadioButton
              name="type"
              value="IR"
              v-model="servicetype"
              v-on:change="defaultplaholderSelect"
            />
            <label for="yes">IR</label>
          </div>
          <div class="p-field-radiobutton p-col-2">
            <RadioButton
              name="type"
              value="Post-IR"
              v-model="servicetype"
              v-on:change="defaultplaholderSelect"
            />
            <label for="No">Post-IR</label>
          </div>
          <div class="p-field-radiobutton p-col-2">
            <RadioButton
              name="type"
              value="MDR"
              v-model="servicetype"
              v-on:change="defaultplaholderSelect"
            />
            <label for="No">MDR</label>
          </div>
        </div>
      </div>
      <div class="p-grid" v-if="servicetype && addTemplateMode">
        <div class="p-col-4">
          <ToggleButton
            v-model="checkedplacholders"
            onLabel="Hide Default Placeholders"
            offLabel="View Default Placeholders"
            @click="
              checkedplacholders
                ? (checkedsrvoverviews = true)
                : (checkedsrvoverviews = false)
            "
          />
        </div>
        <div class="p-col-4">
          <ToggleButton
            v-model="checkedsrvoverviews"
            onLabel="Hide Default Services Overview"
            offLabel="View Default Services Overview"
            @click="
              checkedsrvoverviews
                ? (checkedplacholders = true)
                : (checkedplacholders = false)
            "
          />
        </div>
      </div>
      <DataTable :value="this.template.placeHolders" v-if="checkedplacholders">
        <Column field="title" header="Title" sortable></Column>
        <Column field="slug" header="Slug" sortable></Column>
        <Column field="type" header="Type" sortable></Column>
      </DataTable>
      <DataTable
        :value="this.mainServiceConfigurations"
        :expandedRows.sync="expandedRows"
        data-key="id"
        v-if="checkedsrvoverviews"
      >
        <Column :expander="true" headerStyle="width: 3rem" />
        <Column field="title" header="Title" sortable></Column>
        <Column field="description" header="Description" sortable></Column>
        <template #expansion="slotProps">
          <div class="orders-subtable">
            <DataTable :value="slotProps.data.items">
              <Column field="title" header="Title" sortable></Column>
              <Column
                field="description"
                header="Description"
                sortable
              ></Column>
            </DataTable>
          </div>
        </template>
      </DataTable>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-warning"
          @click="hideDialog"
        />
        <Button label="Save" icon="pi pi-arrow-circle-up" @click="handleSave" />
      </template>
    </Dialog>
    <Dialog
      maximizable
      :visible.sync="confirmationDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span
          >Are you sure you want to
          {{ template.isActive ? "Deactivate" : "Activate" }}
          <b>{{ template.title }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideconDialog"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="activateDeactivateTemplate(template)"
        />
      </template>
    </Dialog>
    <Dialog
      maximizable
      :visible.sync="confirmationPlaceholderDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span
          >Are you sure you want to
          {{ placeHolders.isActive ? "Deactivate" : "Activate" }}
          <b>{{ placeHolders.title }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="confirmationPlaceholderDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="activateDeactivatePlaceholder()"
        />
      </template>
    </Dialog>
    <Dialog
      maximizable
      :visible.sync="serviceOverviewDialog"
      :style="{ width: '120vh' }"
      header="Service Overview"
      :modal="true"
      class="p-fluid"
    >
      <DataTable
        :value="mainServiceConfigurations"
        :expandedRows.sync="expandedRows"
        class="p-datatable-gridlines"
        data-key="title"
      >
        <Column
          :expander="true"
          headerStyle="width: 3rem"
          :headerClass="'thheader'"
        />
        <Column
          field="title"
          header="Title"
          sortable
          :headerClass="'thheader'"
        ></Column>
        <Column
          field="description"
          header="Description"
          sortable
          :headerClass="'thheader'"
        ></Column>
        <Column :headerClass="'thheader'">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              v-tooltip.top="'Edit Service Overview'"
              @click="handleserviceOverview(slotProps.data, 'main')"
              class="p-button-rounded p-button-help p-mr-2"
            />
          </template>
        </Column>
        <template #expansion="slotProps">
          <div class="orders-subtable">
            <DataTable :value="slotProps.data.items">
              <Column
                field="title"
                header="Title"
                sortable
                :headerClass="'nthheader'"
              ></Column>
              <Column
                field="description"
                header="Description"
                :headerClass="'nthheader'"
                sortable
              ></Column>
              <Column :headerClass="'nthheader'">
                <template #body="ncslotProps">
                  <Button
                    icon="pi pi-pencil"
                    v-tooltip.top="'Edit Service Overview Item'"
                    @click="editServiceItems(ncslotProps, slotProps)"
                    class="p-button-rounded p-button-help p-mr-2"
                  />
                  <Button
                    icon="pi pi-trash"
                    v-tooltip.top="'Deactivate Service Overview Item'"
                    v-if="ncslotProps.data.isActive"
                    @click="
                      handleactivateDeactivateServiceItem(
                        ncslotProps,
                        slotProps
                      )
                    "
                    class="p-button-rounded p-button-warning p-mr-2"
                  />
                  <Button
                    v-if="!ncslotProps.data.isActive"
                    icon="pi pi-check-circle"
                    v-tooltip.top="'Activate Service Overview Item'"
                    @click="
                      handleactivateDeactivateServiceItem(
                        ncslotProps,
                        slotProps
                      )
                    "
                    class="p-button-rounded p-button-success p-mr-2"
                  />
                  <Button
                    icon="pi pi-plus"
                    v-tooltip.top="'Add New Service Overview Item'"
                    class="p-button-rounded p-button-success p-mr-2"
                    @click="addServiceItems(ncslotProps, slotProps)"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </DataTable>
      <div v-if="editServiceOvMode">
        <h4>Edit Service overview</h4>
        <br />
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="title"
              v-model.trim="serviceOverview.title"
              required="true"
              autofocus
              :class="{ 'p-invalid': svsubmitted && !serviceOverview.title }"
            />
            <label for="name">Title<span class="p-invalid">*</span></label>
          </span>
          <small class="p-error" v-if="svsubmitted && !serviceOverview.title"
            >Title is required.</small
          >
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="title"
              v-model.trim="serviceOverview.description"
              required="true"
              :class="{
                'p-invalid': svsubmitted && !serviceOverview.description,
              }"
            />
            <label for="name"
              >Description<span class="p-invalid">*</span></label
            >
          </span>
          <small
            class="p-error"
            v-if="svsubmitted && !serviceOverview.description"
            >Description is required.</small
          >
        </div>
      </div>
      <div v-if="editServiceOvItemsMode || addserviceOvItemsMode">
        <h4>
          {{
            editServiceOvItemsMode
              ? "Edit Service overview Item"
              : "Add Service overview Item"
          }}
        </h4>
        <br />
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="title"
              v-model.trim="serviceOverviewItem.title"
              required="true"
              autofocus
            />
            <label for="name">Title</label>
          </span>
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="title"
              v-model.trim="serviceOverviewItem.description"
              required="true"
              :class="{
                'p-invalid': svisubmitted && !serviceOverviewItem.description,
              }"
            />
            <label for="name"
              >Description<span class="p-invalid">*</span></label
            >
          </span>
          <small
            class="p-error"
            v-if="svisubmitted && !serviceOverviewItem.description"
            >Description is required.</small
          >
        </div>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideserviceDialog()"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="handleserviceUpsert()"
        />
      </template>
    </Dialog>
    <Dialog
      maximizable
      :visible.sync="placeholdersDialog"
      :style="{ width: '60vh' }"
      header="Placeholders"
      :modal="true"
      class="p-fluid"
    >
      <br />
      <small class="p-error" v-if="errormsg">{{ errormsg }}</small>
      <div class="p-field p-col">
        <span class="p-float-label">
          <InputText
            id="title"
            v-model.trim="placeHolders.title"
            required="true"
            autofocus
            :class="{ 'p-invalid': plsubmitted && !placeHolders.title }"
          />
          <label for="name">Title<span class="p-invalid">*</span></label>
        </span>
        <small class="p-error" v-if="plsubmitted && !placeHolders.title"
          >Title is required.</small
        >
      </div>
      <div class="p-field p-col" v-if="addPlaceholderFlag">
        <span class="p-float-label">
          <InputText
            id="title"
            v-model.trim="placeHolders.slug"
            required="true"
            autofocus
            :class="{ 'p-invalid': plsubmitted && !placeHolders.slug }"
          />
          <label for="Slug">Slug<span class="p-invalid">*</span></label>
        </span>
        <small class="p-error" v-if="plsubmitted && !placeHolders.slug"
          >Slug is required.</small
        >
      </div>
      <div class="p-field p-col">
        <span class="p-float-label">
          <InputText
            id="description"
            v-model.trim="placeHolders.description"
            required="true"
            autofocus
          />
          <label for="name">Description</label>
        </span>
      </div>
      <div class="p-field p-col">
        <span class="p-float-label">
          <Dropdown
            appendTo="body"
            v-model="placeHolders.type"
            :options="placeholderoptions"
            optionLabel="text"
            :filter="true"
            :class="{ 'p-invalid': plsubmitted && !placeHolders.type }"
          >
            <template #value="slotProps">
              <div class="p-dropdown-car-value" v-if="slotProps.value">
                <span>
                  {{
                    slotProps.value.text
                      ? slotProps.value.text
                      : slotProps.value
                  }}
                </span>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="p-dropdown-car-option">
                <span>{{ slotProps.option.text }}</span>
              </div>
            </template>
          </Dropdown>
          <label for="name">Type<span class="p-invalid">*</span></label>
        </span>
        <small class="p-error" v-if="plsubmitted && !placeHolders.type"
          >Placeholder Type is required.</small
        >
      </div>
      <div class="p-field p-col">
        <span class="p-float-label">
          <InputText
            id="DefaultValue"
            v-model.trim="placeHolders.defaultValue"
            required="true"
            autofocus
          />
          <label for="name">DefaultValue</label>
        </span>
      </div>
      <div
        class="p-field p-col"
        v-if="
          placeHolders.type.text == 'Select' || placeHolders.type == 'Select'
        "
      >
        <span class="p-float-label">
          <InputText
            id="DefaultValue"
            v-model.trim="placeHolders.values"
            required="true"
            autofocus
          />
          <label for="name">Values (comma seperated)</label>
        </span>
      </div>
      <div class="p-field p-col">
        <span class="p-float-label">
          <Dropdown
            appendTo="body"
            v-model.trim="placeHolders.customField"
            :options="customFields"
            optionLabel="name"
            :filter="true"
          >
            <template #value="slotProps">
              <div class="p-dropdown-car-value" v-if="slotProps.value">
                <span
                  >{{
                    slotProps.value.name
                      ? slotProps.value.name
                      : slotProps.value
                  }}
                </span>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="p-dropdown-car-option">
                <span>{{ slotProps.option.name }}</span>
              </div>
            </template>
          </Dropdown>
          <label for="name">Custom Field</label>
        </span>
      </div>
      <div class="p-field p-col">
        <label class="p-mb-3">Is Readonly</label>
        <div class="p-formgrid p-grid">
          <div class="p-field-radiobutton p-col-6">
            <RadioButton
              name="IsReaonly"
              value="true"
              v-model="placeHolders.isReadonly"
            />
            <label for="yes">Yes</label>
          </div>
          <div class="p-field-radiobutton p-col-6">
            <RadioButton
              name="IsReaonly"
              value="false"
              v-model="placeHolders.isReadonly"
            />
            <label for="No">No</label>
          </div>
        </div>
      </div>
      <div class="p-field p-col">
        <label class="p-mb-3">Is Optional</label>
        <div class="p-formgrid p-grid">
          <div class="p-field-radiobutton p-col-6">
            <RadioButton
              name="IsReaonly"
              value="true"
              v-model="placeHolders.isOptional"
            />
            <label for="yes">Yes</label>
          </div>
          <div class="p-field-radiobutton p-col-6">
            <RadioButton
              name="IsReaonly"
              value="false"
              v-model="placeHolders.isOptional"
            />
            <label for="No">No</label>
          </div>
        </div>
      </div>
      <div class="p-field p-col">
        <label class="p-mb-3">Is Customfield</label>
        <div class="p-formgrid p-grid">
          <div class="p-field-radiobutton p-col-6">
            <RadioButton
              name="IsReaonly"
              value="true"
              v-model="placeHolders.isCustomfield"
            />
            <label for="yes">Yes</label>
          </div>
          <div class="p-field-radiobutton p-col-6">
            <RadioButton
              name="IsReaonly"
              value="false"
              v-model="placeHolders.isCustomfield"
            />
            <label for="No">No</label>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideplaceholderDialog()"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="handlePlaceholderUpsert()"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  templateervice: null,
  created() {
    this.fetchCustomFields();
    this.fetchMasterTemplates();
  },
  mounted() {},
  methods: {
    defaultplaholderSelect() {
      let that = this;
      that.template.placeHolders =
        this.servicetype == "MDR"
          ? that.MDRPlaceHolders
          : this.servicetype == "Post-IR"
          ? that.PostIRPlaceHolders
          : that.DefaultPlaceholders;
    },
    hideserviceDialog() {
      let that = this;
      (that.template = {}),
        (that.serviceOverview = {}),
        (that.serviceOverviewItem = {}),
        (that.editServiceOvItemsMode = false);
      that.editServiceOvMode = false;
      that.addserviceOvItemsMode = false;
      that.serviceOverviewDialog = false;
    },
    handleactivateDeactivateServiceItem(serviceOverviewItem) {
      let that = this;
      serviceOverviewItem.data.isActive = !serviceOverviewItem.data.isActive;
      console.log(serviceOverviewItem);
      that.serviceOverviewItem = Object.assign({}, serviceOverviewItem.data);
      that.editServiceOvItemsMode = false;
      that.editServiceOvMode = false;
      that.addserviceOvItemsMode = false;
      that.currentrow = serviceOverviewItem.index;
      that.template.serviceOverview.items.splice(
        this.currentrow,
        1,
        Object.assign({}, that.serviceOverviewItem)
      );
      that.handleSave();
    },
    handleserviceOverview(serviceOverview, type) {
      let that = this;
      console.log(type);
      this.editServiceOvItemsMode = false;
      this.editServiceOvMode = true;
      this.addserviceOvItemsMode = false;
      that.serviceOverview = Object.assign({}, serviceOverview);
    },
    editServiceItems(serviceOverviewItem) {
      let that = this;
      console.log(serviceOverviewItem);
      that.serviceOverviewItem = Object.assign({}, serviceOverviewItem.data);
      this.editServiceOvItemsMode = true;
      this.editServiceOvMode = false;
      this.addserviceOvItemsMode = false;
      this.currentrow = serviceOverviewItem.index;
      // that.serviceOverview = Object.assign({}, serviceOverview);
    },
    addServiceItems() {
      let that = this;
      that.serviceOverviewItem = {};
      that.editServiceOvItemsMode = false;
      that.editServiceOvMode = false;
      that.addserviceOvItemsMode = true;
    },
    handleserviceUpsert() {
      let that = this;
      if (that.editServiceOvMode) {
        that.svsubmitted = true;
        if (that.serviceOverview.title && that.serviceOverview.description) {
          that.template.serviceOverview = that.serviceOverview;
          that.handleSave();
          that.svsubmitted = false;
        }
      } else if (that.editServiceOvItemsMode) {
        that.svisubmitted = true;
        if (that.serviceOverviewItem.description) {
          that.template.serviceOverview.items.splice(
            this.currentrow,
            1,
            Object.assign({}, that.serviceOverviewItem)
          );
          that.handleSave();
          that.svisubmitted = false;
        }
      } else if (that.addserviceOvItemsMode) {
        that.svisubmitted = true;
        if (that.serviceOverviewItem.description) {
          that.serviceOverviewItem.isActive = true;
          that.template.serviceOverview.items.push(that.serviceOverviewItem);
          that.handleSave();
          that.svisubmitted = false;
        }
      }
    },
    handleactivateDeactivatePlaceholder(template, placeholder) {
      let that = this;
      that.placeHolders = placeholder.data;
      that.template = template.data;
      that.confirmationPlaceholderDialog = true;
    },
    activateDeactivatePlaceholder() {
      let that = this;
      that.placeHolders.isActive = !that.placeHolders.isActive;
      that.handleSave();
      that.confirmationPlaceholderDialog = false;
    },
    togglebtn() {
      this.checkedsrvoverviews
        ? (this.checkedsrvoverviews = false)
        : (this.checkedplacholders = true);
    },
    formatType(value) {
      let that = this;
      let type = _.filter(that.placeholderoptions, (mr) => mr.value === value);
      return (value = type.length ? type[0].text : value);
    },
    formatCustomFields(customval) {
      let that = this;
      let type = _.filter(that.customFields, (mr) => mr.id === customval);
      return (customval = type.length ? type[0].name : "");
    },
    formatCustomFieldsId(vals) {
      let that = this;
      let type = _.filter(that.customFields, (mr) => mr.name === vals);
      return (vals = type.length ? type[0].id : "");
    },
    addPlaceholder(template) {
      let that = this;
      this.placeholdersDialog = true;
      that.template = {};
      (that.placeHolders = {
        type: "",
        defaultValue: "",
        isReadonly: "false",
        isOptional: "false",
        slug: "",
        title: "",
        description: "",
        isActive: true,
        isCustomfield: "false",
        customField: "",
        values: "",
        order: "",
      }),
        (this.template = template);
      that.fetchCustomFields();
      that.addPlaceholderFlag = true;
      that.editPlaceholderFlag = false;
    },
    editPlaceholder(template, placeholder) {
      let that = this;
      that.addPlaceholderFlag = false;
      that.editPlaceholderFlag = true;
      that.placeHolders = Object.assign({}, placeholder.data);
      that.template = _.clone(template.data);
      that.fetchCustomFields();
      that.placeholdersDialog = true;
      that.placeHolders.values = that.placeHolders.values
        ? JSON.parse(placeholder.data.values).toString()
        : that.placeHolders.values;
      that.placeHolders.type = this.formatType(that.placeHolders.type);
      that.placeHolders.customField = this.formatCustomFields(
        that.placeHolders.customField
      );
      that.placeHolders.isCustomfield =
        that.placeHolders.isCustomfield == true ? "true" : "false";
      that.placeHolders.isOptional =
        that.placeHolders.isOptional == true ? "true" : "false";
      that.placeHolders.isReadonly =
        that.placeHolders.isReadonly == true ? "true" : "false";
    },
    handlePlaceholderUpsert() {
      let that = this;
      that.plsubmitted = true;
      if (
        that.placeHolders.title &&
        that.placeHolders.slug &&
        that.placeHolders.type
      ) {
        let trecord = _.filter(
          that.template.placeHolders,
          (m) => m.title == that.placeHolders.title
        );
        let tcrecord = _.filter(
          that.template.placeHolders,
          (m) => m.slug == that.placeHolders.slug
        );
        if (
          (trecord.length == 0 || that.editPlaceholderFlag) &&
          (tcrecord.length == 0 || that.editPlaceholderFlag)
        ) {
          that.placeHolders.values = that.placeHolders.values
            ? JSON.stringify(that.placeHolders.values.split(","))
            : "";
          that.placeHolders.type = that.placeHolders.type.text
            ? that.placeHolders.type.text
            : that.placeHolders.type
            ? that.placeHolders.type
            : "";
          that.placeHolders.customField = that.placeHolders.customField.id
            ? that.placeHolders.customField.id
            : that.placeHolders.customField
            ? this.formatCustomFieldsId(that.placeHolders.customField)
            : 0;
          that.placeHolders.isCustomfield =
            that.placeHolders.isCustomfield == ("true" || true) ? true : false;
          that.placeHolders.isOptional =
            that.placeHolders.isOptional == ("true" || true) ? true : false;
          that.placeHolders.isReadonly =
            that.placeHolders.isReadonly == ("true" || true) ? true : false;
          if (that.addPlaceholderFlag) {
            that.template.placeHolders.push(that.placeHolders);
          } else {
            let setindex = that.template.placeHolders.findIndex(
              (mtmpl) => mtmpl.slug === that.placeHolders.slug
            );
            that.template.placeHolders.splice(setindex, 1, that.placeHolders);
            // that.template.placeHolders.map(
            //   (obj) => that.placeHolders.find((o) => o.slug === obj.slug) || obj
            // );
          }
          console.log(this.template);
          that.handleSave();
          this.errormsg = "";
        } else {
          this.errormsg = "Placeholder with this Slug or Title already exists";
        }
      }
    },
    hideplaceholderDialog() {
      let that = this;
      that.plsubmitted = false;
      that.errormsg = "";
      that.placeholdersDialog = false;
      (that.addPlaceholderFlag = true),
        (that.editPlaceholderFlag = false),
        (that.template = {});
      that.placeHolders = {
        type: "",
        defaultValue: "",
        isReadonly: false,
        isOptional: false,
        slug: "",
        title: "",
        description: "",
        isActive: true,
        isCustomfield: false,
        customField: "",
        values: "",
        order: 22,
      };
    },
    viewServiceOverview(template) {
      let that = this;
      that.template = template;
      that.mainServiceConfigurations[0] = Object.assign(
        [],
        template.serviceOverview
      );
      that.serviceOverviewDialog = true;
    },
    onRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Master Template Expanded",
        detail: event.data.title,
        life: 3000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Master Template Collapsed",
        detail: event.data.title,
        life: 3000,
      });
    },
    fetchMasterTemplates() {
      var that = this;
      that.isloading = true;
      this.$store
        .dispatch("masterTemplate/fetchMasterTemplates")
        .then(() => {
          that.response =
            this.$store.getters["masterTemplate/getMasterTemplates"];
          this.masterTemplates = _.clone(that.response);
          that.isloading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleDownload(fileInfo) {
      let that = this;
      // let FileName = fileInfo.fileName;
      this.$store
        .dispatch("masterTemplate/downloadMasterTemplate", fileInfo)
        .then(() => {
          that.response =
            this.$store.getters["masterTemplate/getDownloadMasterTemplates"];
          var fileURL = window.URL.createObjectURL(
            new Blob([that.response.data], { type: "application/octet-stream" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileInfo.originalName);
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchCustomFields() {
      var that = this;
      this.$store
        .dispatch("masterTemplate/fetchCustomFields")
        .then(() => {
          that.response = this.$store.getters["masterTemplate/getCustomFields"];
          this.customFields = _.clone(that.response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSave() {
      let that = this;
      this.submitted = true;
      if (
        that.template.title &&
        (that.template.sowUploadFile || that.template.sowFileInfo)
      ) {
        that.isloading = true;
        const formData = new FormData();
        if (that.template.id) {
          formData.append("Id", that.template.id);
        }
        if (that.template.isActive != undefined) {
          formData.append("IsActive", that.template.isActive);
        }
        formData.append("Title", that.template.title);
        formData.append("Slug", that.template.slug);
        if (that.template.sowUploadFile) {
          formData.append("SowUploadFile", that.template.sowUploadFile);
        }
        if (that.template.msaUploadFile) {
          formData.append("MSAUploadFile", that.template.msaUploadFile);
        }
        if (that.template.amendmentUploadFile) {
          formData.append(
            "AmendmentUploadFile",
            that.template.amendmentUploadFile
          );
        }
        if (that.template.sowFileInfo) {
          formData.append(
            "SowFileInfo.FileId",
            that.template.sowFileInfo.fileId
          );
          formData.append(
            "SowFileInfo.WebURL",
            that.template.sowFileInfo.webURl
          );
          formData.append(
            "SowFileInfo.OriginalName",
            that.template.sowFileInfo.originalName
          );
        }
        if (that.template.msaFileInfo) {
          formData.append(
            "MSAFileInfo.FileId",
            that.template.msaFileInfo.fileId
          );
          formData.append(
            "MSAFileInfo.WebURL",
            that.template.msaFileInfo.webURl
          );
          formData.append(
            "MSAFileInfo.OriginalName",
            that.template.msaFileInfo.originalName
          );
        }
        if (that.template.amendmentFileInfo) {
          formData.append(
            "AmendmentFileInfo.FileId",
            that.template.amendmentFileInfo.fileId
          );
          formData.append(
            "AmendmentFileInfo.WebURL",
            that.template.amendmentFileInfo.webURl
          );
          formData.append(
            "AmendmentFileInfo.OriginalName",
            that.template.amendmentFileInfo.originalName
          );
        }
        that.template.placeHolders.forEach((plholders, index) => {
          formData.append(`PlaceHolders[${index}].Type`, plholders.type);
          formData.append(
            `PlaceHolders[${index}].DefaultValue`,
            plholders.defaultValue ? plholders.defaultValue : ""
          );
          formData.append(
            `PlaceHolders[${index}].IsReadonly`,
            plholders.isReadonly
          );
          formData.append(
            `PlaceHolders[${index}].IsOptional`,
            plholders.isOptional
          );
          formData.append(`PlaceHolders[${index}].Slug`, plholders.slug);
          formData.append(`PlaceHolders[${index}].Title`, plholders.title);
          formData.append(
            `PlaceHolders[${index}].Description`,
            plholders.description ? plholders.description : ""
          );
          formData.append(
            `PlaceHolders[${index}].IsActive`,
            plholders.isActive
          );
          formData.append(
            `PlaceHolders[${index}].IsCustomfield`,
            plholders.isCustomfield
          );
          formData.append(
            `PlaceHolders[${index}].CustomField`,
            parseInt(plholders.customField)
          );
          formData.append(
            `PlaceHolders[${index}].Values`,
            plholders.values ? plholders.values : ""
          );
          formData.append(
            `PlaceHolders[${index}].Order`,
            plholders.order ? parseInt(plholders.order) : index + 1
          );
        });
        formData.append(
          "ServiceOverview.Title",
          that.template.serviceOverview.title
            ? that.template.serviceOverview.title
            : ""
        );
        formData.append(
          "ServiceOverview.Description",
          that.template.serviceOverview.description
            ? that.template.serviceOverview.description
            : ""
        );
        formData.append(
          "ServiceOverview.IsActive",
          that.template.serviceOverview.isActive
        );
        that.template.serviceOverview.items.forEach((srvitems, index) => {
          formData.append(
            `ServiceOverview.Items[${index}].Title`,
            srvitems.title ? srvitems.title : ""
          );
          formData.append(
            `ServiceOverview.Items[${index}].Description`,
            srvitems.description ? srvitems.description : ""
          );
          formData.append(
            `ServiceOverview.Items[${index}].IsActive`,
            srvitems.isActive
          );
        });
        that.hideserviceDialog();
        that.hideplaceholderDialog();
        that.hideDialog();
        this.$store
          .dispatch("masterTemplate/saveMasterTemplates", formData)
          .then(() => {
            that.fetchMasterTemplates();
            that.isloading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    documentView(url) {
      window.open(url);
    },
    editTemplate(template) {
      console.log(template);
      this.editTemplateMode = true;
      this.addTemplateMode = false;
      this.template = _.clone(template);
      this.mastertemplateDialog = true;
    },
    openNew() {
      this.template = {};
      this.submitted = false;
      this.editTemplateMode = false;
      this.addTemplateMode = true;
      this.template.placeHolders = this.DefaultPlaceholders;
      this.template.serviceOverview = this.DefaultServicesOverview;
      this.mainServiceConfigurations[0] = Object.assign(
        [],
        this.template.serviceOverview
      );
      this.mastertemplateDialog = true;
    },
    hideDialog() {
      let that = this;
      this.servicetype = null;
      this.mastertemplateDialog = false;
      this.editTemplateMode = true;
      this.addTemplateMode = false;
      that.template = {};
      that.submitted = false;
    },
    handleactivateDeactivateTemplate(template) {
      let that = this;
      that.template = template;
      this.confirmationDialog = true;
    },
    activateDeactivateTemplate(template) {
      let that = this;
      that.template.isActive = !template.isActive;
      this.handleSave();
      this.confirmationDialog = false;
    },
    handleUpdate(formData) {
      let that = this;
      this.$store
        .dispatch("masterTemplate/updateMasterTemplate", formData)
        .then(() => {
          that.fetchMasterTemplates();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hideconDialog() {
      let that = this;
      that.confirmationDialog = false;
    },
  },
  data() {
    return {
      servicetype: null,
      expandedRows: [],
      currentrow: 0,
      addTemplateMode: false,
      editTemplateMode: false,
      editServiceOvItemsMode: false,
      editServiceOvMode: false,
      addserviceOvItemsMode: false,
      masterTemplates: null,
      customFields: null,
      mastertemplateDialog: false,
      checkedplacholders: false,
      checkedsrvoverviews: false,
      confirmationDialog: false,
      confirmationPlaceholderDialog: false,
      deletetemplateDialog: false,
      serviceOverviewDialog: false,
      placeholdersDialog: false,
      errormsg: null,
      addPlaceholderFlag: true,
      editPlaceholderFlag: false,
      product: {},
      placeHolders: {
        type: "",
        defaultValue: "",
        isReadonly: false,
        isOptional: false,
        slug: "",
        title: "",
        description: "",
        isActive: true,
        isCustomfield: false,
        customField: "",
        values: "",
        order: "",
      },
      serviceOverview: {},
      serviceOverviewItem: {},
      selectedtemplate: null,
      filters: {},
      template: {},
      submitted: false,
      plsubmitted: false,
      svsubmitted: false,
      svisubmitted: false,
      isloading: false,
      mainServiceConfigurations: [],
      DefaultServicesOverview: {
        slug: "",
        title: "Services Overview",
        description:
          "Arete Advisors will perform forensics investigative services including",
        isActive: true,
        items: [
          {
            items: "",
            slug: "",
            title: "",
            description:
              "Conduct a forensics analysis to determine scope of attack, whether there was exfiltration of data, and assure a clean bill of health.",
            isActive: true,
          },
        ],
      },
      placeholderoptions: [
        { value: 0, text: "Text" },
        { value: 1, text: "Description" },
        { value: 2, text: "Select" },
        { value: 3, text: "Bool" },
        { value: 4, text: "Email" },
        { value: 5, text: "PhNo" },
        { value: 6, text: "Date" },
      ],
      DefaultPlaceholders: [
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "lawfirmname",
          title: "Lawfirm Name",
          description: "",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 1,
        },
        {
          type: "Select",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "lawfirmattorney",
          title: "Lawfirm Attorney",
          description: "",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 2,
        },
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "lawfirmattorneyaddress",
          title: "Attorney Address Line",
          description: "e.g., 500 N. Meridian Street, Suite 400",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 3,
        },
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "attorneyaddresscity",
          title: "Attorney Address City",
          description: "e.g., Indianapolis",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 4,
        },
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "attorneyaddressstate",
          title: "Attorney Address State",
          description: "e.g., IN",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 5,
        },
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "attorneyaddresszip",
          title: "Attorney Address Zip",
          description: "e.g., 46204",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 6,
        },
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "lawfirmattorneytitle",
          title: "Attorney Title",
          description: "",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 7,
        },
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "lawfirmattentionemail",
          title: "Lawfirm Attention Email",
          description: "",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 8,
        },
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "clientname",
          title: "Client Name",
          description: "",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 9,
        },
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "clientaddressline",
          title: "Client Address Line",
          description: "e.g., 500 N. Meridian Street, Suite 400",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 10,
        },
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "clientaddresscity",
          title: "Client Address City",
          description: "e.g., Indianapolis",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 11,
        },
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "clientaddressstate",
          title: "Client Address State",
          description: "e.g., IN",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 12,
        },
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "clientaddresszip",
          title: "Client Address Zip",
          description: "e.g., 46204",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 13,
        },
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "clientcontactname",
          title: "Client Contact Name",
          description: "",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 14,
        },
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "clientcontacttitle",
          title: "Client Contact Title",
          description: "",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 15,
        },
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "clientcontactemail",
          title: "Client Contact Email",
          description: "",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 16,
        },
        {
          type: "Text",
          defaultValue: "",
          isReadonly: false,
          isOptional: false,
          slug: "clientcontactnumber",
          title: "Client Contact Number",
          description: "",
          isActive: true,
          isCustomfield: false,
          customField: 0,
          values: "",
          order: 17,
        },
      ],
      MDRPlaceHolders: [
        {
          type: "Date",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "sowdate",
          title: "Sow Date",
          description: "",
          values: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 1,
        },
        {
          type: "Date",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "msadate",
          title: "MSA Date",
          values: "",
          description: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 2,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientname",
          title: "Client Name",
          values: "",
          description: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 3,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "endpointagentsnumber",
          title: "No of S1 Endpoint Agents",
          values: "",
          description: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 4,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "agentamount",
          title: "Amount Per Agent",
          values: "",
          description: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 5,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientaddressline",
          title: "Client Address Line",
          description: "e.g., 500 N. Meridian Street, Suite 400",
          values: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 6,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientaddresscity",
          title: "Client Address City",
          description: "e.g., Indianapolis",
          values: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 7,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientaddressstate",
          title: "Client Address State",
          values: "",
          description: "e.g., IN",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 8,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientaddresszip",
          title: "Client Address Zip",
          description: "e.g., 46204",
          values: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 9,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientcontactname",
          title: "Client Contact Name",
          values: "",
          description: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 10,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientcontacttitle",
          title: "Client Contact Title",
          values: "",
          description: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 11,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientcontactemail",
          title: "Client Contact Email",
          values: "",
          description: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 12,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientcontactnumber",
          title: "Client Contact Number",
          values: "",
          description: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 13,
        },
      ],
      PostIRPlaceHolders: [
        {
          type: "Select",
          defaultValue: null,
          values: '["3 Months", "6 Months", "8 Months", "12 Months"]',
          isReadonly: false,
          isOptional: false,
          slug: "term",
          title: "Term",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 1,
        },
        {
          type: "Date",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "sowdate",
          title: "Sow Date",
          description: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 2,
        },
        {
          type: "Date",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "msadate",
          title: "MSA Date",
          description: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 3,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientname",
          title: "Client Name",
          description: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 4,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientaddressline",
          title: "Client Address Line",
          description: "e.g., 500 N. Meridian Street, Suite 400",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 5,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientaddresscity",
          title: "Client Address City",
          description: "e.g., Indianapolis",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 6,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientaddressstate",
          title: "Client Address State",
          description: "e.g., IN",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 7,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientaddresszip",
          title: "Client Address Zip",
          description: "e.g., 46204",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 8,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientcontactname",
          title: "Client Contact Name",
          description: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 9,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientcontacttitle",
          title: "Client Contact Title",
          description: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 10,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientcontactemail",
          title: "Client Contact Email",
          description: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 11,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientcontactnumber",
          title: "Client Contact Number",
          description: "",
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: 12,
        },
      ],
    };
  },
};
</script>

<style scoped>
.p-datatable-flex-scrollable .p-datatable-scrollable-body {
  background-color: white !important;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  background-color: #f8f9fa !important;
}
</style>