<template>
  <div id="componentDocumentTypes">
    <DocumentTypesTable
      ref="documentTypesTable"
      :data="tableObject"
      :method="documentTypeActions"
    />
    <DocumentTypeDialog
      v-if="flages.displayDialog"
      ref="documentTypeDialog"
      :flages="flages"
      :handleCancel="toggleDialog"
      :handleSave="upsertDocumentType"
      :data="dialogObject"
    />
    <ConfirmationDialog
      v-else-if="flages.confirmdisplayDialog"
      ref="confirmdocumentTypeDialog"
      :flages="flages"
      :handleCancel="toggleConfirmationDialog"
      :handleSave="activateDeactivateDocumentType"
      :data="dialogObject"
    />
  </div>
</template>

<script>
import _ from "lodash";
import DocumentTypesTable from "../Shared/FetchDataTable";
import DocumentTypeDialog from "../Shared/UpsertDialog";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
export default {
  name: "documentTypesComponent",
  created() {
    this.fetchDocumentTypes();
  },
  components: { DocumentTypesTable, DocumentTypeDialog, ConfirmationDialog },
  methods: {
    fetchDocumentTypes() {
      var that = this;
      that.tableObject.isloading = true;
      that.tableObject.records = null;
      this.$store
        .dispatch("documentType/fetchDocumentType")
        .then(() => {
          that.response = this.$store.getters["documentType/getDocumentType"];
          that.tableObject.records = _.clone(that.response);
          that.tableObject.isloading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    documentTypeActions(documentType, type) {
      let that = this;
      let selectedDocumentType = _.clone(documentType);
      that.dialogObject.record = _.clone(selectedDocumentType);
      that.dialogObject.actionType = type;
      that.invokeDocumentType(type, documentType, "");
    },
    toggleDialog() {
      this.flages.displayDialog = !this.flages.displayDialog;
    },
    toggleConfirmationDialog() {
      this.flages.confirmdisplayDialog = !this.flages.confirmdisplayDialog;
    },
    upsertDocumentType(data, records) {
      let that = this;
      let documentType = _.clone(data);
      let record = documentType.record;
      let documentTypes = _.clone(records);
      let actionType = _.clone(documentType.actionType);
      let type = actionType == "Add" ? "savedocuments" : "updatedocument";
      if (record.title != "" && record.slug != "") {
        that.invokeDocumentType(type, documentType.record, documentTypes);
      }
    },
    activateDeactivateDocumentType(data) {
      let that = this;
      let documentType = _.clone(data.record);
      documentType.isActive = !documentType.isActive;
      that.updateDocumentType(documentType);
      that.toggleConfirmationDialog();
    },
    updateDocumentType(documentType) {
      let that = this;
      this.$store
        .dispatch("documentType/updateDocumentType", documentType)
        .then(() => {
                this.flages.displayDialog=false;
          that.fetchDocumentTypes();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveDocumentTypes(documentType) {
      let that = this;
      this.$store
        .dispatch("documentType/saveDocumentType", documentType)
        .then(() => {
          that.toggleDialog();
          that.fetchDocumentTypes();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    errorMessage() {
      this.$toast.add({
        severity: "error",
        summary: "Error Message",
        detail: `${this.error}`,
        life: 2000,
      });
    },
    invokeDocumentType(type, documentType, documentTypes) {
      let that = this;
      switch (type) {
        case "ActivateDeactive":
          that.toggleConfirmationDialog();
          break;
        case "updatedocument":
          if (that.isDuplicate(documentType, "update")) {
            that.errorMessage();
          } else {
            that.updateDocumentType(documentType);
          }
          break;
        case "savedocuments":
          if (that.isDuplicateRecords(documentTypes)) {
            that.errorMessage();
          } else {
            that.saveDocumentTypes(documentTypes);
          }
          break;
        default:
          that.toggleDialog();
          break;
      }
    },
    isDuplicate(documentType, type) {
      let that = this;
      let record = documentType;
      let oldrecords = that.tableObject.records;
      let response = _.some(oldrecords, (oldrecord) =>
        type == "update"
          ? oldrecord.title == record.title
          : oldrecord.slug == record.slug || oldrecord.title == record.title
      );
      response
        ? (that.error = "Title or Slug should be unique")
        : (that.error = "");
      return response;
    },
    isDuplicateRecords(documentTypes) {
      let that = this;
      let results = [];
      let records = documentTypes;
      _.each(records, (record) => results.push(that.isDuplicate(record)));
      let response = _.some(results);
      return response;
    },
  },
  props: {},
  watch: {},
  data() {
    return {
      response: null,
      error: null,
      tableObject: {
        header: "Document Types With Sowlution Application",
        columns: [
          { field: "slug", header: "Slug" },
          { field: "title", header: "Name" },
          { field: "isActive", header: "Status" },
          { field: "actions", header: "Actions" },
        ],
        records: null,
        isexpandable: false,
        isloading: true,
        issync: false,
        isedit: true,
        component: "documenttype",
      },
      flages: {
        displayDialog: false,
        confirmdisplayDialog: false,
      },
      dialogObject: {
        record: null,
        actionType: null,
        component: "DocumentType",
      },
    };
  },
};
</script>

<style scoped>
</style>