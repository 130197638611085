/* eslint-disable no-unused-vars */
import {
    getBaaNdaTemplates,
    upsertBaaNdaTemplate
} from '../../services/BaaNdaConfig.Service'

const state = {
    baaNdaTemplates: {}
}
const getters = {
    getBaaNdaTemplates(state) {
        return state.baaNdaTemplates;
    }
}
const actions = {
    fetchBaaNdaTemplates({ commit }) {
        return new Promise((resolve, reject) => {
            getBaaNdaTemplates().then(response => {
                if (response.status == 200) {
                    commit('setBaaNdaTemplates', response.data.data);
                    resolve();
                }
            }).catch((error) => {
                reject(error)
            })
        });
    },
    upsertBaaNdaTemplate({ commit },formData) {
        return new Promise((resolve, reject) => {
            upsertBaaNdaTemplate(formData).then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch((error) => {
                reject(error)
            })
        });
    },
       
}
const mutations = {
    setBaaNdaTemplates(state, response) {
        state.baaNdaTemplates = response;
    }
}
export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}
