/* eslint-disable no-unused-vars */
import { getServices, syncServices, putService } from '../../services/Services.Service';

const state = {
    Services: {}
}
const getters = {
    getServices(state) {
        return state.Services;
    }
}
const actions = {
    fetchServices({ commit }) {
        return new Promise((resolve, reject) => {
            getServices().then(response => {
                if (response.status == 200) {
                    commit('setServices', response.data.data);
                    resolve();
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    syncServices({ commit }) {
        return new Promise((resolve, reject) => {
            syncServices().then(response => {
                if (response.status == 200) {
                    commit('setServices', response.data.data);
                    resolve();
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    updateService({ commit }, services) {
        return new Promise((resolve, reject) => {
            putService(services).then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error);
            })
        });
    }
}
const mutations = {
    setServices(state, response) {
        state.Services = response;
    }
}

export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}