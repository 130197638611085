import Api from './Api';
const endPoint = "incidenttypes";
const fieldsendPoint = "customfields"

const getIncidentTypes = incidentTypes => Api.get(`${endPoint}/incidenttypes`, incidentTypes);
const syncincidentTypes = syncincidentTypes => Api.get(`${fieldsendPoint}`, syncincidentTypes);
const putIncidentType = incidentTypes => Api.put(`${endPoint}`, incidentTypes);

export {
    getIncidentTypes,
    syncincidentTypes,
    putIncidentType
}