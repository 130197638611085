<template>
  <div id="componentServiceConfigs">
    <div
      id="serviceconfigTable"
      style="height: calc(100vh - 175px)"
      class="col-12"
    >
      <DataTable
        :scrollable="true"
        scrollHeight="flex"
        :loading="isloading"
        :filters="filters"
        sortMode="multiple"
        removableSort
        :value="serviceConfigs"
        :paginator="true"
        :rows="20"
        :rowsPerPageOptions="[20, 40, 60, 80]"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        data-key="id"
        class="p-datatable-gridlines"
        :expandedRows.sync="expandedRows"
      >
        <template #header>
          <div class="p-grid">
            <div class="p-col-3 p-text-center mt-2 p-text-left">
              <span class=""><b>Service Configurations</b></span>
            </div>
            <div class="p-col-6 text-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  style="width: 567px !important"
                  v-model="filters['global']"
                  placeholder="Global Search"
                />
              </span>
            </div>
            <div class="p-col-2">
              <Button
                icon="pi pi-plus"
                label="Add New"
                v-tooltip.left="'ServiceConfig'"
                class="p-button-raised p-button-info p-mr-3"
                @click="handleAddServiceConfig()"
              />
            </div>
          </div>
        </template>
        <template #empty> No records found. </template>
        <Column
          :expander="true"
          headerStyle="width: 3rem"
          :headerClass="'thheader'"
        />
        <Column
          field="serviceTitle"
          header="Service Title"
          :sortable="true"
          :headerClass="'thheader'"
        ></Column>
        <Column
          field="masterServiceConfigTitle"
          header="Master Service Config"
          :sortable="true"
          :headerClass="'scstype thheader'"
          :bodyClass="'scstype'"
        >
          <template #body="slotProps">{{
            formatMasterServiceConfig(slotProps.data.masterServiceConfig)
          }}</template></Column
        >
        <!-- <Column
          field="lawfirm"
          header="Lawfirm"
          :sortable="true"
          :headerClass="'sclawfirm thheader'"
          :bodyClass="'sclawfirm'"
        ></Column>
        <Column
          field="subOfferingType"
          header="SType"
          :sortable="true"
          :headerClass="'scstype thheader'"
          :bodyClass="'scstype'"
        ></Column> -->
        <Column :headerClass="'thheader'" header="Actions">
          <template #body="slotProps">
            <Button
              icon="pi pi-plus"
              v-tooltip.top="'Add Taks'"
              @click="handleAddTasks(slotProps.data)"
              class="p-button-raised p-button-details p-mr-3"
            />
            <Button
              icon="pi pi-plus"
              v-tooltip.top="'Add Scopes'"
              @click="handleAddScopes(slotProps.data)"
              class="p-button-raised p-button-help p-mr-3"
            />
            <Button
              icon="pi pi-plus"
              v-tooltip.top="'Add PreSection'"
              @click="handleAddPreSections(slotProps.data)"
              class="p-button-raised p-button-secondary p-mr-2"
            />
            <Button
              icon="pi pi-plus"
              v-tooltip.top="'Add PostSection'"
              @click="handleAddPostSections(slotProps.data)"
              class="p-button-raised p-button-primary p-mr-2"
            />
            <Button
              icon="pi pi-pencil"
              v-tooltip.top="'Edit ServiceConfig'"
              @click="editServiceConfig(slotProps.data)"
              class="p-button-raised p-button-info p-mr-2"
            />
            <Button
              icon="pi pi-eye"
              v-tooltip.top="'View Scopes'"
              @click="handleViewScopes(slotProps.data)"
              class="p-button-raised p-button-help p-mr-3"
            />
            <Button
              icon="pi pi-eye"
              v-tooltip.top="'View PreSections'"
              @click="handleViewPreSections(slotProps.data)"
              class="p-button-raised p-button-secondary p-mr-2"
            />
            <Button
              icon="pi pi-eye"
              v-tooltip.top="'View PostSections'"
              @click="handleViewPostSections(slotProps.data)"
              class="p-button-raised p-button-primary p-mr-2"
            />
            <Button
              icon="pi pi-trash"
              v-tooltip.top="'Deactivate ServiceConfig'"
              v-if="slotProps.data.isActive"
              @click="handleactivateDeactivateServiceConfig(slotProps.data)"
              class="p-button-raised p-button-warning p-mr-2"
            />
            <Button
              v-if="!slotProps.data.isActive"
              icon="pi pi-check-circle"
              v-tooltip.top="'Activate ServiceConfig'"
              @click="handleactivateDeactivateServiceConfig(slotProps.data)"
              class="p-button-raised p-button-success p-mr-2"
            />
          </template>
        </Column>
        <template #expansion="slotProps">
          <div class="">
            <DataTable
              sortMode="multiple"
              :scrollable="true"
              removableSort
              data-key="title"
              class="p-datatable-gridlines"
              :expandedRows.sync="expandedNestRows"
              :value="slotProps.data.tasks"
            >
              <template #header>
                <div class="p-grid">
                  <div class="p-col-4 p-text-left"></div>
                  <div class="p-col-4">
                    <span class="p-text-center"><b>Service Tasks</b></span>
                  </div>
                  <div class="p-col-4 p-text-right"></div>
                </div>
              </template>
              <Column
                :expander="true"
                headerStyle="width: 3rem"
                :headerClass="'nthheader'"
              />
              <Column
                field="title"
                header="Title"
                sortable
                :headerClass="'nthheader'"
              ></Column>
              <Column
                field="slug"
                header="Slug"
                sortable
                :headerClass="'nthheader'"
              ></Column>
              <Column
                field="description"
                header="Description"
                sortable
                :headerClass="'nthheader'"
              ></Column>
              <Column :headerClass="'nthheader'" header="Actions">
                <template #body="slotPropTask">
                  <Button
                    icon="pi pi-plus"
                    v-tooltip.top="'Add Task Items'"
                    @click="
                      handleAddTaskItem(slotProps.data, slotPropTask.data)
                    "
                    class="p-button-rounded p-button-sec p-mr-2"
                  />
                  <Button
                    icon="pi pi-pencil"
                    v-tooltip.top="'Edit Task'"
                    @click="
                      handleeditServiceTask(slotProps.data, slotPropTask.data)
                    "
                    class="p-button-rounded p-button-help p-mr-2"
                  />
                  <Button
                    icon="pi pi-trash"
                    v-tooltip.top="'Deactivate Task'"
                    v-if="slotPropTask.data.isActive"
                    @click="
                      handleactivateDeactivateTask(
                        slotProps.data,
                        slotPropTask.data
                      )
                    "
                    class="p-button-rounded p-button-warning p-mr-2"
                  />
                  <Button
                    v-if="!slotPropTask.data.isActive"
                    icon="pi pi-check-circle"
                    v-tooltip.top="'Activate Task'"
                    @click="
                      handleactivateDeactivateTask(
                        slotProps.data,
                        slotPropTask.data
                      )
                    "
                    class="p-button-rounded p-button-success p-mr-2"
                  />
                </template>
              </Column>
              <template #expansion="slotPropTask">
                <div class="">
                  <DataTable
                    :scrollable="true"
                    :expandedRows.sync="expandedNestexpandedRows"
                    :value="slotPropTask.data.items"
                  >
                    <template #header>
                      <div class="p-grid">
                        <div class="p-col-4 p-text-left"></div>
                        <div class="p-col-4">
                          <span class="p-text-center"
                            ><b>Service Task Items</b></span
                          >
                        </div>
                        <div class="p-col-4 p-text-right"></div>
                      </div>
                    </template>
                    <Column
                      :expander="true"
                      headerStyle="width: 3rem"
                      :headerClass="'nthheader'"
                    />
                    <Column
                      field="title"
                      header="Title"
                      sortable
                      :headerClass="'nthheader'"
                    ></Column>
                    <Column
                      field="slug"
                      header="Slug"
                      sortable
                      :headerClass="'nthheader'"
                    ></Column>
                    <Column
                      field="description"
                      header="Description"
                      sortable
                      :headerClass="'nthheader'"
                    ></Column>
                    <Column :headerClass="'nthheader'" header="Actions">
                      <template #body="slotPropTaskitem">
                        <Button
                          icon="pi pi-plus"
                          v-tooltip.top="'Add Task Nested Items'"
                          @click="
                            handleAddTaskNestedItem(
                              slotProps.data,
                              slotPropTask.data,
                              slotPropTaskitem.data
                            )
                          "
                          class="p-button-rounded p-button-info p-mr-2"
                        />
                        <Button
                          icon="pi pi-pencil"
                          v-tooltip.top="'Edit Task Item'"
                          @click="
                            handleeditServiceTaskItem(
                              slotProps.data,
                              slotPropTask.data,
                              slotPropTaskitem.data
                            )
                          "
                          class="p-button-rounded p-button-secondary p-mr-2"
                        />
                        <Button
                          icon="pi pi-trash"
                          v-tooltip.top="'Deactivate Task'"
                          v-if="slotPropTaskitem.data.isActive"
                          @click="
                            handleActivateDeactiveTaskitem(
                              slotProps.data,
                              slotPropTask.data,
                              slotPropTaskitem.data
                            )
                          "
                          class="p-button-rounded p-button-warning p-mr-2"
                        />
                        <Button
                          v-if="!slotPropTaskitem.data.isActive"
                          icon="pi pi-check-circle"
                          v-tooltip.top="'Activate Task'"
                          @click="
                            handleActivateDeactiveTaskitem(
                              slotProps.data,
                              slotPropTask.data,
                              slotPropTaskitem.data
                            )
                          "
                          class="p-button-rounded p-button-success p-mr-2"
                        />
                      </template>
                    </Column>
                    <template #expansion="slotPropTaskNestedItem">
                      <div class="">
                        <h6>Service Tak Nested Items</h6>
                        <DataTable
                          :value="slotPropTaskNestedItem.data.items"
                          :scrollable="true"
                        >
                          <Column
                            field="title"
                            header="Title"
                            sortable
                            :headerClass="'nthheader'"
                          ></Column>
                          <Column
                            field="slug"
                            header="Slug"
                            sortable
                            :headerClass="'nthheader'"
                          ></Column>
                          <Column
                            field="description"
                            header="Description"
                            sortable
                            :headerClass="'nthheader'"
                          ></Column>
                          <Column :headerClass="'nthheader'" header="Actions">
                            <template #body="slotPropTaskNestedItem">
                              <Button
                                icon="pi pi-pencil"
                                v-tooltip.top="'Edit Nested Task Item'"
                                @click="
                                  handleeditNestedTaskItem(
                                    slotProps.data,
                                    slotPropTask.data,
                                    slotPropTaskNestedItem.data
                                  )
                                "
                                class="p-button-rounded p-button-sec p-mr-2"
                              />
                              <Button
                                icon="pi pi-trash"
                                v-tooltip.top="'Deactivate Task'"
                                v-if="slotPropTaskNestedItem.data.isActive"
                                @click="
                                  handleActivateDeactiveNestedTaskitem(
                                    slotProps.data,
                                    slotPropTask.data,
                                    slotPropTaskNestedItem.data
                                  )
                                "
                                class="p-button-rounded p-button-warning p-mr-2"
                              />
                              <Button
                                v-if="!slotPropTaskNestedItem.data.isActive"
                                icon="pi pi-check-circle"
                                v-tooltip.top="'Activate Task'"
                                @click="
                                  handleActivateDeactiveNestedTaskitem(
                                    slotProps.data,
                                    slotPropTask.data,
                                    slotPropTaskitem.data.slotPropTaskNestedItem
                                      .data
                                  )
                                "
                                class="p-button-rounded p-button-success p-mr-2"
                              />
                            </template>
                          </Column>
                        </DataTable>
                      </div>
                    </template>
                  </DataTable>
                </div>
              </template>
            </DataTable>
          </div>
        </template>
      </DataTable>
    </div>
    <div id="addserviceconfig">
      <Dialog
        v-if="serviceConfigDialog"
        maximizable
        :visible.sync="serviceConfigDialog"
        :closable="false"
        :style="{ width: '70vw' }"
        :header="
          editServiceConfigMode ? 'Edit ServiceConfig' : 'Add ServiceConfig'
        "
        :modal="true"
        class="p-fluid"
      >
        <div class="p-mt-4">
          <div class="p-field p-col">
            <!-- <span class="p-float-label">
              <Dropdown
                appendTo="body"
                v-model.trim="serviceConfig.template"
                :options="templates"
                optionLabel="title"
                :filter="true"
                placeholder=""
                :class="{ 'p-invalid': submitted && !serviceConfig.template }"
              >
                <template #value="slotProps">
                  <div class="p-dropdown-car-value" v-if="slotProps.value">
                    <span
                      >{{
                        slotProps.value.title
                          ? slotProps.value.title
                          : slotProps.value
                      }}
                    </span>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="p-dropdown-car-option">
                    <span>{{ slotProps.option.title }}</span>
                  </div>
                </template>
              </Dropdown>
              <label for="name">Select Template</label>
            </span> -->
            <span class="p-float-label">
              <Dropdown
                appendTo="body"
                v-model.trim="serviceConfig.masterServiceConfig"
                :options="masterServiceConfigs"
                optionLabel="title"
                :filter="true"
                placeholder=""
                :class="{
                  'p-invalid': submitted && !serviceConfig.masterServiceConfig,
                }"
              >
                <template #value="slotProps">
                  <div class="p-dropdown-car-value" v-if="slotProps.value">
                    <span
                      >{{
                        slotProps.value.title
                          ? slotProps.value.title
                          : slotProps.value
                      }}
                    </span>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="p-dropdown-car-option">
                    <span>{{ slotProps.option.title }}</span>
                  </div>
                </template>
              </Dropdown>
              <label for="name">Select Master ServiceConfig</label>
            </span>
            <span
              class="error-message"
              v-if="submitted && !serviceConfig.masterServiceConfig"
              >Master Service config is required.</span
            >
          </div>
          <div class="p-field p-col" v-if="addServiceConfigMode">
            <span class="p-float-label">
              <Dropdown
                appendTo="body"
                v-model.trim="serviceConfig.service"
                :options="services"
                optionLabel="title"
                @change="handleChange"
                :filter="true"
                placeholder=""
                :class="{ 'p-invalid': submitted && !serviceConfig.service }"
              >
                <template #value="slotProps">
                  <div class="p-dropdown-car-value" v-if="slotProps.value">
                    <span
                      >{{
                        slotProps.value.title
                          ? slotProps.value.title
                          : slotProps.value
                      }}
                    </span>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <div class="p-dropdown-car-option">
                    <span>{{ slotProps.option.title }}</span>
                  </div>
                </template>
              </Dropdown>
              <label for="name">Select Service</label>
            </span>
            <span
              class="error-message"
              v-if="submitted && !serviceConfig.service"
              >Service is required.</span
            >
          </div>
          <div class="p-field p-col" v-if="addServiceConfigMode">
            <span class="p-float-label">
              <InputText
                id="slug"
                v-model.trim="serviceConfig.slug"
                :class="{ 'p-invalid': submitted && !serviceConfig.slug }"
              />
              <label for="name">Slug</label>
            </span>
            <span
              :class="{ 'p-invalid': !serviceConfig.slug }"
              v-if="submitted && !serviceConfig.slug"
              >Slug is required.</span
            >
          </div>
          <div class="p-field p-col">
            <span class="p-float-label">
              <InputText
                id="slug"
                v-model.trim="serviceConfig.serviceTitle"
                :class="{
                  'p-invalid': submitted && !serviceConfig.serviceTitle,
                }"
              />
              <label for="name">Service Title</label>
            </span>
            <span
              :class="{ 'p-invalid': !serviceConfig.serviceTitle }"
              v-if="submitted && !serviceConfig.serviceTitle"
              >Service Title is required.</span
            >
          </div>
          <div class="p-field p-col">
            <span class="p-float-label">
              <InputText
                id="slug"
                v-model.trim="serviceConfig.serviceDescription"
                :class="{
                  'p-invalid': submitted && !serviceConfig.serviceDescription,
                }"
              />
              <label for="name">Service Description</label>
            </span>
            <span
              :class="{ 'p-invalid': !serviceConfig.serviceDescription }"
              v-if="submitted && !serviceConfig.serviceDescription"
              >Service Description is required.</span
            >
          </div>
          <div class="p-field p-col">
            <span class="p-float-label">
              <InputText id="slug" v-model.trim="serviceConfig.title" />
              <label for="name">Serviceconfig Title</label>
            </span>
          </div>
          <div class="p-field p-col">
            <span class="p-float-label">
              <InputText id="slug" v-model.trim="serviceConfig.description" />
              <label for="name">Serviceconfig Description</label>
            </span>
          </div>
          <div class="p-field p-col">
            <label class="p-mb-3">Has Sub Tasks</label>
            <div class="p-formgrid p-grid">
              <div class="p-field-radiobutton p-col-6">
                <RadioButton
                  name="hassubtasks"
                  :value="true"
                  v-model="serviceConfig.hasSubTasks"
                />
                <label for="yes">Yes</label>
              </div>
              <div class="p-field-radiobutton p-col-6">
                <RadioButton
                  name="hassubtasks"
                  :value="false"
                  v-model="serviceConfig.hasSubTasks"
                />
                <label for="No">No</label>
              </div>
            </div>
          </div>
        </div>
        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            class="p-button-warning"
            @click="hideDialog"
          />
          <Button
            label="Save"
            icon="pi pi-arrow-circle-up"
            @click="
              isModified ? handleServiceConfigUpdate() : saveServiceConfigs()
            "
          />
        </template>
      </Dialog>
    </div>
    <div id="serviceconfigcnf">
      <Dialog
        v-if="confirmationDialog"
        maximizable
        :visible.sync="confirmationDialog"
        :closable="false"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span
            >Are you sure you want to
            <span v-if="isTask">{{
              serviceTask.isActive ? "Deactivate" : "Activate"
            }}</span>
            <span v-else-if="isTaskItem">{{
              serviceTaskItem.isActive ? "Deactivate" : "Activate"
            }}</span>
            <span v-else>{{
              serviceConfig.isActive ? "Deactivate" : "Activate"
            }}</span>
            <b v-if="isTask">{{ serviceTask.title }}</b>
            <b v-else-if="isTaskItem">{{ serviceTaskItem.title }}</b>
            <b v-else>{{ serviceConfig.serviceTitle }}</b>
            ?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="hideDialog"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="
              isTask
                ? activateDeactivateTask()
                : isTaskItem
                ? activateDeactivateTaskItem()
                : activateDeactivateServiceConfig(serviceConfig)
            "
          />
        </template>
      </Dialog>
    </div>
    <div id="addTasksScopes">
      <Dialog
        v-if="serviceTaskScopeDialog"
        maximizable
        :visible.sync="serviceTaskScopeDialog"
        :closable="false"
        :style="{ width: '70vw' }"
        :header="taskHeader"
        :modal="true"
        class="p-fluid"
      >
        <div class="p-mt-4 p-grid">
          <div class="p-field p-col p-col-6">
            <span class="p-float-label">
              <InputText
                id="slug"
                v-model.trim="serviceTaskScope.slug"
                :class="{ 'p-invalid': submitted && !serviceTaskScope.slug }"
              />
              <label for="name">Slug</label>
            </span>
            <span
              :class="{ 'p-invalid': !serviceTaskScope.slug }"
              v-if="submitted && !serviceTaskScope.slug"
              >Slug is required.</span
            >
          </div>
          <div class="p-field p-col p-col-6">
            <span class="p-float-label">
              <InputText
                id="slug"
                v-model.trim="serviceTaskScope.title"
                :class="{ 'p-invalid': submitted && !serviceTaskScope.title }"
              />
              <label for="name">Title</label>
            </span>
            <span
              :class="{ 'p-invalid': !serviceTaskScope.title }"
              v-if="submitted && !serviceTaskScope.title"
              >Title is required.</span
            >
          </div>
          <div class="p-field p-col p-col-12">
            <span class="p-float-label">
              <InputText
                id="slug"
                v-model.trim="serviceTaskScope.description"
              />
              <label for="name">Description</label>
            </span>
          </div>
          <div class="p-field p-col p-col-12" v-if="isTask">
            <span class="p-float-label">
              <InputText id="slug" v-model.trim="serviceTaskScope.order" />
              <label for="name">Order</label>
            </span>
          </div>
          <div class="p-field p-col p-col-3" v-if="!isTask">
            <label class="p-mb-3">ShouldBeHidden</label>
            <div class="p-formgrid p-grid">
              <div class="p-field-radiobutton p-col-6">
                <RadioButton
                  name="IsReaonly"
                  :value="true"
                  v-model="serviceTaskScope.shouldBeHidden"
                />
                <label for="yes">Yes</label>
              </div>
              <div class="p-field-radiobutton p-col-6">
                <RadioButton
                  name="IsReaonly"
                  :value="false"
                  v-model="serviceTaskScope.shouldBeHidden"
                />
                <label for="No">No</label>
              </div>
            </div>
          </div>
          <div class="p-field p-col p-col-3" v-if="!isTask">
            <label class="p-mb-3">Is SubTask Scope</label>
            <div class="p-formgrid p-grid">
              <div class="p-field-radiobutton p-col-6">
                <RadioButton
                  name="IsSubTaskScope"
                  :value="true"
                  v-model="serviceTaskScope.isSubTaskScope"
                />
                <label for="yes">Yes</label>
              </div>
              <div class="p-field-radiobutton p-col-6">
                <RadioButton
                  name="issubtaskscope"
                  :value="false"
                  v-model="serviceTaskScope.isSubTaskScope"
                />
                <label for="No">No</label>
              </div>
            </div>
          </div>
          <div class="p-col-4 p-offset-4" v-if="addServiceConfigMode">
            <Button
              v-if="isTask"
              :label="'Add Task'"
              icon="pi pi-plus-circle"
              @click="addServiceTaskScope"
              autofocus
            />
            <Button
              v-else
              :label="'Add Scope'"
              icon="pi pi-plus-circle"
              @click="addServiceTaskScope"
              autofocus
            />
          </div>
        </div>
        <DataTable
          :scrollable="true"
          v-if="serviceTasksScopes.length > 0"
          scrollHeight="flex"
          sortMode="multiple"
          removableSort
          :value="serviceTasksScopes"
          data-key="title"
          class="p-datatable-gridlines"
        >
          <Column
            field="slug"
            header="Slug"
            :sortable="true"
            :headerClass="'thheader'"
          >
          </Column>
          <Column
            field="title"
            header="Title"
            :sortable="true"
            :headerClass="'thheader'"
          >
          </Column>
          <Column
            field="description"
            header="Description"
            :sortable="true"
            :headerClass="'thheader'"
          >
          </Column>
          <Column :headerClass="'thheader'">
            <template #body="slotProps">
              <Button
                icon="pi pi-trash"
                v-tooltip.top="'Delete Section'"
                @click="deleteTaskScope(slotProps.data)"
                class="p-button-raised p-button-warning p-mr-2"
              />
            </template>
          </Column>
        </DataTable>
        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            class="p-button-warning"
            @click="hideDialog"
          />
          <Button
            v-if="serviceTasksScopes.length > 0 || !addServiceConfigMode"
            label="Save"
            icon="pi pi-arrow-circle-up"
            @click="isTask ? saveTask() : saveScope()"
          />
        </template>
      </Dialog>
    </div>
    <div id="sections">
      <Dialog
        v-if="serviceSectionDialog"
        maximizable
        :visible.sync="serviceSectionDialog"
        :closable="false"
        :style="{ width: '70vw' }"
        :header="this.sectionHeader"
        :modal="true"
        class="p-fluid"
      >
        <div class="p-mt-4">
          <div class="p-field p-col" v-if="addServiceSectionMode">
            <span class="p-float-label">
              <InputText
                id="slug"
                v-model.trim="serviceSection.slug"
                :class="{ 'p-invalid': submitted && !serviceSection.slug }"
              />
              <label for="name">Slug</label>
            </span>
            <span
              :class="{ 'p-invalid': !serviceSection.slug }"
              v-if="submitted && !serviceSection.slug"
              >Slug is required.</span
            >
          </div>
          <div class="p-field p-col">
            <span class="p-float-label">
              <InputText
                id="slug"
                v-model.trim="serviceSection.title"
                :class="{ 'p-invalid': submitted && !serviceSection.title }"
              />
              <label for="name">Title</label>
            </span>
            <span
              :class="{ 'p-invalid': !serviceSection.title }"
              v-if="submitted && !serviceSection.title"
              >Title is required.</span
            >
          </div>
          <div class="p-field p-col">
            <span class="p-float-label">
              <InputText
                id="slug"
                v-model.trim="serviceSection.description"
                :class="{
                  'p-invalid': submitted && !serviceSection.description,
                }"
              />
              <label for="name">Description</label>
            </span>
            <span
              :class="{ 'p-invalid': !serviceSection.description }"
              v-if="submitted && !serviceSection.description"
              >Description is required.</span
            >
          </div>
        </div>
        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            class="p-button-warning"
            @click="hideDialog"
          />
          <Button
            label="Save"
            icon="pi pi-arrow-circle-up"
            @click="saveSection()"
          />
        </template>
      </Dialog>
    </div>
    <div id="sectionTable">
      <Dialog
        v-if="viewserviceSectionDialog"
        maximizable
        :visible.sync="viewserviceSectionDialog"
        :closable="false"
        :style="{ width: '70vw' }"
        :header="this.sectionHeader"
        :modal="true"
        class="p-fluid"
      >
        <DataTable
          :scrollable="true"
          scrollHeight="flex"
          sortMode="multiple"
          removableSort
          :value="serviceSections"
          data-key="title"
          class="p-datatable-gridlines"
        >
          <Column
            field="slug"
            header="Slug"
            :sortable="true"
            :headerClass="'nthheader'"
          >
          </Column>
          <Column
            field="title"
            header="Title"
            :sortable="true"
            :headerClass="'nthheader'"
          >
          </Column>
          <Column
            field="description"
            header="Description"
            :sortable="true"
            :headerClass="'nthheader'"
          >
          </Column>
          <Column :headerClass="'nthheader'" header="Actions">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                v-tooltip.top="'Edit Section'"
                @click="editsection(slotProps.data)"
                class="p-button-raised p-button-info p-mr-2"
              />
              <Button
                icon="pi pi-trash"
                v-if="slotProps.data.isActive"
                v-tooltip.top="'Deactivate Section'"
                @click="handleActivateDeactiveSection(slotProps.data)"
                class="p-button-raised p-button-warning p-mr-2"
              />
              <Button
                v-else-if="!slotProps.data.isActive"
                icon="pi pi-check-circle"
                v-tooltip.top="'Activate Section'"
                @click="handleActivateDeactiveSection(slotProps.data)"
                class="p-button-raised p-button-success p-mr-2"
              />
            </template>
          </Column>
        </DataTable>
        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            class="p-button-warning"
            @click="hideDialog"
          />
        </template>
      </Dialog>
    </div>
    <div id="taskitems">
      <Dialog
        v-if="serviceTaskItemDialog"
        maximizable
        :visible.sync="serviceTaskItemDialog"
        :closable="false"
        :style="{ width: '70vw' }"
        :header="this.sectionHeader"
        :modal="true"
        class="p-fluid"
      >
        <div class="p-mt-4">
          <div class="p-field p-col" v-if="addServiceTaskItemMode">
            <span class="p-float-label">
              <InputText
                id="slug"
                v-model.trim="serviceTaskItem.slug"
                :class="{ 'p-invalid': submitted && !serviceTaskItem.slug }"
              />
              <label for="name">Slug</label>
            </span>
            <span
              :class="{ 'p-invalid': !serviceTaskItem.slug }"
              v-if="submitted && !serviceSection.slug"
              >Slug is required.</span
            >
          </div>
          <div class="p-field p-col">
            <span class="p-float-label">
              <InputText
                id="slug"
                v-model.trim="serviceTaskItem.title"
                :class="{ 'p-invalid': submitted && !serviceTaskItem.title }"
              />
              <label for="name">Title</label>
            </span>
            <span
              :class="{ 'p-invalid': !serviceTaskItem.title }"
              v-if="submitted && !serviceTaskItem.title"
              >Title is required.</span
            >
          </div>
          <div class="p-field p-col">
            <span class="p-float-label">
              <InputText
                id="slug"
                v-model.trim="serviceTaskItem.description"
                :class="{
                  'p-invalid': submitted && !serviceTaskItem.description,
                }"
              />
              <label for="name">Description</label>
            </span>
            <span
              :class="{ 'p-invalid': !serviceTaskItem.description }"
              v-if="submitted && !serviceTaskItem.description"
              >Description is required.</span
            >
          </div>
          <div class="p-field p-col p-col-12">
            <span class="p-float-label">
              <InputText id="slug" v-model.trim="serviceTaskItem.order" />
              <label for="name">Order</label>
            </span>
          </div>
          <div class="p-col-4 p-offset-4">
            <Button
              :label="'Add Task Item'"
              icon="pi pi-plus-circle"
              @click="addServiceTaskItem"
              autofocus
            />
          </div>
        </div>
        <DataTable
          :scrollable="true"
          v-if="serviceTaskItems.length > 0"
          scrollHeight="flex"
          sortMode="multiple"
          removableSort
          :value="serviceTaskItems"
          data-key="title"
          class="p-datatable-gridlines"
        >
          <Column
            field="slug"
            header="Slug"
            :sortable="true"
            :headerClass="'thheader'"
          >
          </Column>
          <Column
            field="title"
            header="Title"
            :sortable="true"
            :headerClass="'thheader'"
          >
          </Column>
          <Column
            field="description"
            header="Description"
            :sortable="true"
            :headerClass="'thheader'"
          >
          </Column>
          <Column :headerClass="'thheader'">
            <template #body="slotProps">
              <Button
                icon="pi pi-trash"
                v-tooltip.top="'Delete Section'"
                @click="deleteTaskScopeItem(slotProps.data)"
                class="p-button-raised p-button-warning p-mr-2"
              />
            </template>
          </Column>
        </DataTable>
        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            class="p-button-warning"
            @click="hideDialog"
          />
          <Button
            label="Save"
            icon="pi pi-arrow-circle-up"
            @click="saveServiceItems()"
          />
        </template>
      </Dialog>
    </div>
    <div id="serviceconfigsectioncnf">
      <Dialog
        v-if="sectionconfirmationDialog"
        maximizable
        :visible.sync="sectionconfirmationDialog"
        :closable="false"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span
            >Are you sure you want to
            <span>{{
              serviceSection.isActive ? "Deactivate" : "Activate"
            }}</span>
            <b>{{ serviceSection.title }}</b>
            ?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="hideDialog"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="activateDeactivateSection()"
          />
        </template>
      </Dialog>
    </div>
    <div id="viewservicescopes">
      <Dialog
        v-if="viewserviceScopeDialog"
        maximizable
        :visible.sync="viewserviceScopeDialog"
        :closable="false"
        :style="{ width: '70vw' }"
        :header="this.scopeHeader"
        :modal="true"
        class="p-fluid"
      >
        <ServiceScopeTable
          ref="serviceScopesTable"
          :data="tableObject"
          :add="handleAddScopeInputs"
          :edit="handleEditScope"
          :activatedeactive="handleActivateDeactive"
        />
        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            class="p-button-warning"
            @click="hideDialog"
          />
        </template>
      </Dialog>
    </div>
    <div id="scopeinputDialog">
      <Dialog
        v-if="serviceScopeinputDialog"
        maximizable
        :visible.sync="serviceScopeinputDialog"
        :closable="false"
        :style="{ width: '70vw' }"
        :header="this.sectionHeader"
        :modal="true"
        class="p-fluid"
      >
        <div class="p-mt-4">
          <div class="p-grid">
            <div class="p-field p-col p-col-6">
              <span class="p-float-label">
                <Dropdown
                  appendTo="body"
                  v-model="serviceScopeInput.type"
                  :options="inputoptions"
                  optionLabel="Type"
                  :filter="true"
                  :class="{ 'p-invalid': submitted && !serviceScopeInput.type }"
                >
                  <template #value="slotProps">
                    <div class="p-dropdown-car-value" v-if="slotProps.value">
                      <span>
                        {{
                          slotProps.value.text
                            ? slotProps.value.text
                            : slotProps.value
                        }}
                      </span>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="p-dropdown-car-option">
                      <span>{{ slotProps.option.text }}</span>
                    </div>
                  </template>
                </Dropdown>
                <label for="name">Type</label>
              </span>
              <span class="p-error" v-if="submitted && !serviceScopeInput.type"
                >Type is required.</span
              >
            </div>
            <div class="p-field p-col p-col-6">
              <span class="p-float-label">
                <InputText
                  id="slug"
                  v-model.trim="serviceScopeInput.slug"
                  :class="{
                    'p-invalid': submitted && !serviceScopeInput.slug,
                  }"
                />
                <label for="name">Slug</label>
              </span>
              <span
                :class="{ 'p-invalid': !serviceScopeInput.slug }"
                v-if="submitted && !serviceScopeInput.slug"
                >Slug is required.</span
              >
            </div>
            <div
              class="p-field p-col p-col-6"
              v-if="
                typeof serviceScopeInput.type == 'string'
                  ? serviceScopeInput.type == 'Select'
                  : serviceScopeInput.type
                  ? serviceScopeInput.type.text == 'Select'
                  : false
              "
            >
              <span class="p-float-label">
                <InputText id="slug" v-model.trim="serviceScopeInput.value" />
                <label for="name">Value</label>
              </span>
            </div>
            <div class="p-field p-col p-col-6" v-else>
              <span class="p-float-label">
                <InputText
                  id="slug"
                  v-model.trim="serviceScopeInput.defaultValue"
                />
                <label for="name">DefaultValue</label>
              </span>
            </div>
            <div class="p-field p-col p-col-6">
              <span class="p-float-label">
                <InputText
                  id="slug"
                  v-model.trim="serviceScopeInput.title"
                  :class="{
                    'p-invalid': submitted && !serviceScopeInput.title,
                  }"
                />
                <label for="name">Title</label>
              </span>
              <span
                :class="{ 'p-invalid': !serviceScopeInput.title }"
                v-if="submitted && !serviceScopeInput.title"
                >Title is required.</span
              >
            </div>
            <div class="p-field p-col p-col-6">
              <span class="p-float-label">
                <InputText
                  id="slug"
                  v-model.trim="serviceScopeInput.description"
                />
                <label for="name">Description</label>
              </span>
            </div>
            <div class="p-field p-col p-col-6">
              <span class="p-float-label">
                <Dropdown
                  appendTo="body"
                  v-model="serviceScopeInput.rateCardMapping"
                  :options="cardoptions"
                  optionLabel="Type"
                  :filter="true"
                >
                  <template #value="slotProps">
                    <div class="p-dropdown-car-value" v-if="slotProps.value">
                      <span>
                        {{
                          slotProps.value.text
                            ? slotProps.value.text
                            : slotProps.value
                        }}
                      </span>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="p-dropdown-car-option">
                      <span>{{ slotProps.option.text }}</span>
                    </div>
                  </template>
                </Dropdown>
                <label for="name">RateCardMapping</label>
              </span>
            </div>
            <div class="p-field p-col p-col-6">
              <span class="p-float-label">
                <InputText id="slug" v-model.trim="serviceScopeInput.notes" />
                <label for="name">Notes</label>
              </span>
            </div>
            <div class="p-field p-col p-col-6" v-if="addServiceScopeInputMode">
              <Button
                :label="'Add Scope Input'"
                icon="pi pi-plus-circle"
                @click="addServiceScopeInput"
                autofocus
              />
            </div>
          </div>
        </div>
        <DataTable
          :scrollable="true"
          v-if="serviceScopeInputs.length > 0"
          scrollHeight="flex"
          sortMode="multiple"
          removableSort
          :value="serviceScopeInputs"
          data-key="title"
          class="p-datatable-gridlines"
        >
          <Column
            field="type"
            header="Type"
            :sortable="true"
            :headerClass="'thheader'"
          >
          </Column>
          <Column
            field="slug"
            header="Slug"
            :sortable="true"
            :headerClass="'thheader'"
          >
          </Column>
          <Column
            field="title"
            header="Title"
            :sortable="true"
            :headerClass="'thheader'"
          >
          </Column>
          <Column header="Value" :sortable="true" :headerClass="'thheader'">
            <template #body="slotProps">
              <span v-if="slotProps.data.typeval.text == 'Select'">
                {{ slotProps.data.value }}
              </span>
              <span v-else>{{ slotProps.data.defaultvalue }}</span>
            </template>
          </Column>
          <Column
            field="description"
            header="Description"
            :sortable="true"
            :headerClass="'thheader'"
          >
          </Column>
          <Column
            field="rateCardMapping"
            header="RCard"
            :sortable="true"
            :headerClass="'thheader'"
          >
          </Column>
          <Column :headerClass="'thheader'" header="Actions">
            <template #body="slotProps">
              <Button
                icon="pi pi-trash"
                v-tooltip.top="'Delete ScopeItem'"
                @click="deleteScopeItem(slotProps.data)"
                class="p-button-raised p-button-warning p-mr-2"
              />
            </template>
          </Column>
        </DataTable>
        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            class="p-button-warning"
            @click="hideDialog"
          />
          <Button
            label="Save"
            icon="pi pi-arrow-circle-up"
            @click="saveServiceScope"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import _ from "lodash";
import ServiceScopeTable from "../Shared/ServiceScopeTable";
export default {
  name: "serviceConfigsComponent",
  created() {
    let that = this;
    that.fetchTemplats();
    that.fetchLawfirms();
    that.fetchSubOfferingTypes();
    that.fetchServices();
    that.fetchMasterServiceConfigs();
    that.fatchServiceConfigs();
  },
  components: { ServiceScopeTable },
  methods: {
    deleteTaskScope(serviceTaskScope) {
      let that = this;
      let taskscope = serviceTaskScope;
      that.serviceTasksScopes = _.reject(
        that.serviceTasksScopes,
        (st) => st.title == taskscope.title
      );
    },
    deleteTaskScopeItem(serviceTaskScope) {
      let that = this;
      let taskscope = serviceTaskScope;
      that.serviceTaskItems = _.reject(
        that.serviceTaskItems,
        (st) => st.title == taskscope.title
      );
    },
    deleteScopeItem(scopeitme) {
      let that = this;
      let scopeItem = scopeitme;
      that.serviceScopeInputs = _.reject(
        that.serviceScopeInputs,
        (st) => st.title == scopeItem.title
      );
    },
    handleActivateDeactiveSection(section) {
      let that = this;
      that.sectionconfirmationDialog = true;
      that.serviceSection = section;
      that.isModified = true;
    },
    activateDeactivateSection() {
      let that = this;
      let serviceConfig = that.serviceConfig;
      let serviceSection = that.serviceSection;
      serviceSection.isActive = !serviceSection.isActive;
      that.sectionconfirmationDialog = false;
      that.updateServiceConfig(serviceConfig);
    },
    fetchTemplats() {
      var that = this;
      this.$store
        .dispatch("templateConfiguration/fetchTemplateConfigurations")
        .then(() => {
          that.templates =
            this.$store.getters[
              "templateConfiguration/getTemplateConfigurations"
            ];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchServices() {
      var that = this;
      that.$store
        .dispatch("service/fetchServices")
        .then(() => {
          let service = this.$store.getters["service/getServices"];
          that.services = _.filter(service, (ser) => ser.isActive);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchMasterServiceConfigs() {
      var that = this;
      this.$store
        .dispatch("masterserviceconfig/fetchMasterServiceConfigs")
        .then(() => {
          that.response =
            this.$store.getters["masterserviceconfig/getMasterServiceConfigs"];
          this.masterServiceConfigs = _.clone(that.response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fatchServiceConfigs() {
      var that = this;
      that.isloading = true;
      that.$store
        .dispatch("serviceconfig/fetchServiceConfigs")
        .then(() => {
          let sevConfig = this.$store.getters["serviceconfig/getServiceConfigs"];
          that.serviceConfigs =_.sortBy(sevConfig, [function(sc) { return sc.masterServiceConfig; }]);
          _.each(that.serviceConfigs, (serviceConfig) => {
            _.each(that.masterServiceConfigs, (ms) => {
              if (serviceConfig.masterServiceConfig == ms.slug) {
                serviceConfig.masterServiceConfigTitle = that.formatMasterServiceConfig(ms.slug);
              }
            });
          });
          that.isloading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchLawfirms() {
      var that = this;
      this.$store.dispatch("lawfirm/fetchlawfirms").then(() => {
        that.response = this.$store.getters["lawfirm/getlawfirms"];
        this.lawfirms = _.clone(that.response);
      });
    },
    formatLawfirms(value) {
      let that = this;
      let type = _.filter(that.lawfirms, (mr) => mr.slug === value);
      return (value = type.length ? type[0].title : value);
    },
    formatSuboffering(value) {
      let that = this;
      let type = _.filter(that.subOfferingTypes, (mr) => mr.slug === value);
      return (value = type.length ? type[0].title : value);
    },
    fetchSubOfferingTypes() {
      var that = this;
      this.$store.dispatch("subofferingType/fetchSubofferingTypes").then(() => {
        that.response =
          this.$store.getters["subofferingType/getSubofferingTypes"];
        this.subOfferingTypes = _.clone(that.response);
      });
    },
    saveServiceConfigs() {
      var that = this;
      that.submitted = true;
      this.serviceConfig.masterServiceConfig = this.serviceConfig
        .masterServiceConfig.slug
        ? this.serviceConfig.masterServiceConfig.slug
        : this.serviceConfig.masterServiceConfig;
      let serviceConfig = this.serviceConfig;
      const length = Object.values(serviceConfig).length > 0;
      const isValid = Object.values(serviceConfig).some(
        (x) => x !== null && x !== ""
      );
      if (length && isValid) {
        that.serviceConfigDialog = false;
        that.$store
          .dispatch("serviceconfig/saveServiceConfig", serviceConfig)
          .then(() => {
            that.hideDialog();
            that.fatchServiceConfigs();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleServiceConfigUpdate() {
      let that = this;
      that.submitted = true;
      let serviceConfig = this.serviceConfig;
      if (
        serviceConfig.serviceDescription != "" &&
        serviceConfig.serviceTitle != "" &&
        serviceConfig.title &&
        serviceConfig.description &&
        serviceConfig.masterServiceConfig
      ) {
        this.serviceConfig.masterServiceConfig = that.serviceConfig
          .masterServiceConfig.slug
          ? that.serviceConfig.masterServiceConfig.slug
          : _.filter(
              that.masterServiceConfigs,
              (mr) => mr.title === that.serviceConfig.masterServiceConfig
            )[0].slug;
        that.updateServiceConfig(serviceConfig);
      }
    },
    saveSection() {
      let that = this;
      that.submitted = true;
      let section = _.clone(that.serviceSection);
      const length = Object.values(section).length > 0;
      const isValid = Object.values(section).some(
        (x) => x !== null && x !== ""
      );
      const issectionvalid = section.title != "" && section.description != "";
      if (length && isValid && issectionvalid) {
        let serviceconfig = that.serviceConfig;
        let isPresection = that.isPresection;
        that.serviceSectionDialog = false;
        serviceconfig.postSections = serviceconfig.postSections || [];
        serviceconfig.preSections = serviceconfig.preSections || [];
        if (!that.isModified) {
          isPresection
            ? serviceconfig.preSections.push(section)
            : serviceconfig.postSections.push(section);
        }
        that.updateServiceConfig(serviceconfig);
      }
    },
    saveTask() {
      let that = this;
      let serviceTasksScopes = that.serviceTasksScopes;
      let serviceTaskScope = that.serviceTaskScope;
      let serviceConfig = that.serviceConfig;
      serviceConfig.tasks = serviceConfig.tasks || [];
      if (serviceTasksScopes.length > 0) {
        _.each(serviceTasksScopes, (task) => serviceConfig.tasks.push(task));
        that.updateServiceConfig(serviceConfig);
      } else if (
        serviceTaskScope.slug.length > 0 &&
        serviceTaskScope.title.length > 0
      ) {
        that.updateServiceConfig(serviceConfig);
      }
    },
    saveServiceItems() {
      let that = this;
      let serviceConfig = that.serviceConfig;
      let serviceTask = that.serviceTask;
      let serviceTaskItems = that.serviceTaskItems;
      serviceTask.items = serviceTask.items || [];
      _.each(serviceTaskItems, (item) => serviceTask.items.push(item));
      //two-way binding
      that.updateServiceConfig(serviceConfig);
    },
    saveScope() {
      let that = this;
      let serviceTasksScopes = that.serviceTasksScopes;
      let serviceConfig = that.serviceConfig;
      serviceConfig.scopes = serviceConfig.scopes || [];
      if (serviceTasksScopes.length > 0) {
        _.each(serviceTasksScopes, (scope) => serviceConfig.scopes.push(scope));
      }
      that.updateServiceConfig(serviceConfig);
    },
    updateServiceConfig(serviceConfig) {
      var that = this;
      that.hideDialog();
      that.$store
        .dispatch("serviceconfig/updateServiceConfig", serviceConfig)
        .then(() => {
          that.fatchServiceConfigs();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editServiceConfig(serviceConfig) {
      this.submitted = true;
      this.editServiceConfigMode = true;
      this.addServiceConfigMode = false;
      this.isModified = true;
      serviceConfig.masterServiceConfig = this.formatMasterServiceConfig(
        serviceConfig.masterServiceConfig
      );
      this.serviceConfig = _.clone(serviceConfig);
      this.serviceConfigDialog = true;
    },
    handleeditServiceTask(serviceConfig, serviceConfigTask) {
      let that = this;
      that.editServiceConfigMode = true;
      that.addServiceConfigMode = false;
      that.serviceConfig = serviceConfig;
      that.serviceTaskScopeDialog = true;
      that.isTask = true;
      that.isModified = true;
      that.taskHeader = "Edit Task";
      that.serviceTaskScope = serviceConfigTask;
    },
    handleeditServiceTaskItem(
      serviceConfig,
      serviceConfigTask,
      serviceConfigTaskItem
    ) {
      let that = this;
      that.editServiceConfigMode = true;
      that.addServiceConfigMode = false;
      that.serviceConfig = serviceConfig;
      that.serviceTaskScopeDialog = true;
      that.isTask = true;
      that.isModified = true;
      that.taskHeader = "Edit Task Item";
      that.serviceTaskScope = serviceConfigTaskItem;
    },
    handleeditNestedTaskItem(serviceConfig, Task, NestedTaskItem) {
      let that = this;
      that.editServiceConfigMode = true;
      that.addServiceConfigMode = false;
      that.serviceConfig = serviceConfig;
      that.serviceTaskScopeDialog = true;
      that.isTask = true;
      that.isModified = true;
      that.taskHeader = "Edit Task Item";
      that.serviceTaskScope = NestedTaskItem;
    },
    onRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Service Tasks Expanded",
        detail: event.data.title,
        life: 3000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Service Tasks Collapsed",
        detail: event.data.title,
        life: 3000,
      });
    },
    onNestRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Service Taks Items Expanded",
        detail: event.data.title,
        life: 3000,
      });
    },
    onNestRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Service Taks Items Collapsed",
        detail: event.data.title,
        life: 3000,
      });
    },
    onNestedNestRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Service Taks Items Expanded",
        detail: event.data.title,
        life: 3000,
      });
    },
    onNestedNestRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Service Taks Items Collapsed",
        detail: event.data.title,
        life: 3000,
      });
    },
    handleChange() {
      let selectedService = this.serviceConfig.service;
      let selectedTemplate = this.serviceConfig.template;
      let selectedMasterServiceConfig = this.serviceConfig.masterServiceConfig;
      this.serviceConfig.serviceDescription = selectedService.description
        ? _.clone(selectedService.description)
        : _.clone(selectedService.title);
      this.serviceConfig.serviceTitle = _.clone(selectedService.title);
      this.serviceConfig.title = _.clone(selectedService.title);
      this.serviceConfig.description = _.clone(
        this.serviceConfig.serviceDescription
      );
      this.serviceConfig.slug =
        selectedMasterServiceConfig.slug + "-" + selectedService.slug;
      this.serviceConfig.serviceId = selectedService.slug
        ? selectedService.slug
        : selectedService.title;
      this.serviceConfig.masterServiceConfig = selectedMasterServiceConfig.slug
        ? selectedMasterServiceConfig.slug
        : selectedMasterServiceConfig.title;
    },
    handleAddTasks(serviceconfig) {
      let that = this;
      that.serviceConfig = serviceconfig;
      that.serviceTaskScopeDialog = true;
      that.addServiceConfigMode = true;
      that.editServiceTaskScope = false;
      that.isTask = true;
      that.taskHeader = "Add Tasks";
    },
    handleAddScopes(serviceconfig) {
      let that = this;
      that.serviceConfig = serviceconfig;
      that.serviceTaskScopeDialog = true;
      that.addServiceConfigMode = true;
      that.editServiceConfigMode = false;
      that.isTask = false;
      that.taskHeader = "Add Scopes";
    },
    handleAddPreSections(serviceconfig) {
      let that = this;
      that.isPresection = true;
      that.serviceSectionDialog = true;
      that.addServiceSectionMode = true;
      that.sectionHeader = "Add Presection";
      that.serviceConfig = serviceconfig;
    },
    handleAddPostSections(serviceconfig) {
      let that = this;
      that.isPresection = false;
      that.serviceSectionDialog = true;
      that.addServiceSectionMode = true;
      that.sectionHeader = "Add PostSection";
      that.serviceConfig = serviceconfig;
    },
    handleactivateDeactivateServiceConfig(serviceConfig) {
      let that = this;
      that.serviceConfig = serviceConfig;
      this.confirmationDialog = true;
    },
    hideDialog() {
      let that = this;
      that.serviceTaskItems = [];
      that.serviceTaskItem = {};
      that.serviceScopeInput = {};
      that.serviceScopeInputs = [];
      that.serviceConfig = {};
      that.serviceTaskScope = {};
      that.serviceScope = {};
      that.serviceSection = {};
      that.serviceTasksScopes = [];
      that.confirmationDialog = false;
      that.serviceConfigDialog = false;
      that.submitted = false;
      that.serviceSectionDialog = false;
      that.viewserviceSectionDialog = false;
      that.isModified = false;
      that.isTask = false;
      that.isTaskItem = false;
      that.confirmationDialog = false;
      that.serviceTaskScopeDialog = false;
      that.serviceTaskItemDialog = false;
      that.sectionconfirmationDialog = false;
      that.isServiceTaskNestedItem = false;
      that.addServiceConfigMode = false;
      that.editServiceConfigMode = false;
      that.viewserviceScopeDialog = false;
      that.addServiceScopeInputMode = false;
      that.editServiceScopeInputMode = false;
      that.serviceScopeinputDialog = false;
    },
    handleAddServiceConfig() {
      this.serviceConfig = {};
      this.submitted = false;
      this.editServiceConfigMode = false;
      this.addServiceConfigMode = true;
      this.serviceConfigDialog = true;
    },
    handleViewPreSections(serviceconfig) {
      let that = this;
      that.isPresection = true;
      that.serviceSections = [];
      that.viewserviceSectionDialog = true;
      that.sectionHeader = "View Presections";
      that.serviceConfig = serviceconfig;
      that.serviceSections = this.serviceConfig.preSections;
    },
    handleViewPostSections(serviceconfig) {
      let that = this;
      that.isPresection = false;
      that.serviceSections = [];
      that.viewserviceSectionDialog = true;
      that.sectionHeader = "View Postsections";
      that.serviceConfig = serviceconfig;
      that.serviceSections = this.serviceConfig.postSections;
    },
    editsection(serviceconfigsection) {
      let that = this;
      that.submitted = true;
      that.serviceSection = serviceconfigsection;
      that.sectionHeader = that.isPresection
        ? "Edit Presection Item"
        : "Edit Postsection Item";
      that.viewserviceSectionDialog = false;
      that.editServiceSectionMode = true;
      this.addServiceSectionMode = false;
      that.serviceSectionDialog = true;
      that.isModified = true;
    },
    activateDeactivateServiceConfig(serviceconfig) {
      let that = this;
      let serviceConfig = _.clone(serviceconfig);
      serviceConfig.isActive = !serviceConfig.isActive;
      that.updateServiceConfig(serviceConfig);
    },
    activateDeactivateTask() {
      let that = this;
      let serviceConfig = that.serviceConfig;
      let servicetask = that.serviceTask;
      servicetask.isActive = !servicetask.isActive;
      that.updateServiceConfig(serviceConfig);
    },
    activateDeactivateTaskItem() {
      let that = this;
      let serviceConfig = that.serviceConfig;
      let servicetaskitem = that.serviceTaskItem;
      servicetaskitem.isActive = !servicetaskitem.isActive;
      that.updateServiceConfig(serviceConfig);
    },
    addServiceTaskScope() {
      let that = this;
      that.submitted = true;
      let serviceTaskScope = _.clone(this.serviceTaskScope);
      if (that.isTaskScopeValid()) {
        that.serviceTasksScopes.push(serviceTaskScope);
        this.serviceTaskScope = {};
        that.submitted = false;
      }
    },
    isTaskScopeValid() {
      let that = this;
      let serviceTaskScope = _.clone(this.serviceTaskScope);
      return (
        serviceTaskScope.slug != "" &&
        serviceTaskScope.title != "" &&
        serviceTaskScope.slug != null &&
        serviceTaskScope.title != null &&
        serviceTaskScope.slug != undefined &&
        serviceTaskScope.title != undefined
      );
    },
    isServiceScopeInputValid() {
      let that = this;
      let scopeInput = _.clone(that.serviceScopeInput);
      return (
        scopeInput.slug != "" &&
        scopeInput.title != "" &&
        scopeInput.slug != null &&
        scopeInput.title != null &&
        scopeInput.slug != undefined &&
        scopeInput.title != undefined
      );
    },
    handleAddTaskItem(serviceconfig, servicetask) {
      let that = this;
      that.serviceTask = servicetask;
      that.serviceTaskItemDialog = true;
      that.addServiceTaskItemMode = true;
      that.editServiceTaskItemMode = false;
      that.isServiceTaskItem = true;
      that.sectionHeader = "Add Service Task Items";
      that.serviceConfig = serviceconfig;
    },
    addServiceTaskItem() {
      let that = this;
      that.submitted = true;
      let serviceTaskitem = _.clone(this.serviceTaskItem);
      if (Object.keys(serviceTaskitem).length >= 3) {
        that.serviceTaskItems.push(serviceTaskitem);
        this.serviceTaskItem = {};
      }
    },
    addServiceScopeInput() {
      let that = this;
      that.submitted = true;
      let scopeInput = _.clone(that.serviceScopeInput);
      scopeInput.typeval = _.clone(scopeInput.type);
      scopeInput.rateCardMappingval = _.clone(scopeInput.rateCardMapping);
      scopeInput.type = scopeInput.type.value;
      scopeInput.rateCardMapping = scopeInput.rateCardMapping?.value;
      if (that.isServiceScopeInputValid()) {
        scopeInput.value = scopeInput.value ? scopeInput.value.split(",") : [];
        that.serviceScopeInputs.push(scopeInput);
        that.serviceScopeInput = {};
      }
    },
    handleactivateDeactivateTask(serviceConfig, serviceConfigtask) {
      let that = this;
      that.serviceConfig = serviceConfig;
      that.serviceTask = serviceConfigtask;
      that.isTask = true;
      this.confirmationDialog = true;
    },
    handleActivateDeactiveTaskitem(serviceConfig, task, item) {
      let that = this;
      that.serviceConfig = serviceConfig;
      that.serviceTask = task;
      that.serviceTaskItem = item;
      that.isTaskItem = true;
      that.confirmationDialog = true;
    },
    handleActivateDeactiveNestedTaskitem(serviceConfig, task, nesteditem) {
      let that = this;
      that.serviceConfig = serviceConfig;
      that.serviceTask = task;
      that.serviceTaskItem = nesteditem;
      that.isTaskItem = true;
      that.confirmationDialog = true;
    },
    handleAddTaskNestedItem(serviceconfig, servicetask, servicenestedtask) {
      let that = this;
      that.serviceTask = servicenestedtask;
      that.serviceTaskItemDialog = true;
      that.addServiceTaskItemMode = true;
      that.editServiceTaskItemMode = false;
      that.isServiceTaskNestedItem = true;
      that.sectionHeader = "Add Service Task Nested Items";
      that.serviceConfig = serviceconfig;
    },
    handleViewScopes(serviceconfig) {
      let that = this;
      that.serviceConfig = serviceconfig;
      that.viewserviceScopeDialog = true;
      that.scopeHeader = "View Scopes";
      that.tableObject.records = serviceconfig.scopes;
    },
    handleAddScopeInputs(servicescope) {
      let that = this;
      let serviceScope = servicescope;
      that.serviceScope = serviceScope;
      that.addServiceScopeInputMode = true;
      that.editServiceScopeInputMode = false;
      that.sectionHeader = "Add Scope Inputs";
      that.serviceScopeinputDialog = true;
      that.viewserviceScopeDialog = false;
    },
    handleEditScope(servicescope) {
      let that = this;
      let serviceScope = servicescope;
      that.viewserviceScopeDialog = false;
      that.serviceTaskScope = serviceScope;
      that.addServiceConfigMode = false;
      that.editServiceConfigMode = true;
      that.taskHeader = "Edit Scope";
      that.isTask = false;
      that.serviceTaskScopeDialog = true;
    },
    handleEditScopeInput(servicescope) {
      let that = this;
      let serviceScopeinput = servicescope;
      serviceScopeinput.typeval = serviceScopeinput.type;
      serviceScopeinput.rateCardMappingval = serviceScopeinput.rateCardMapping;
      serviceScopeinput.type = that.formatType(serviceScopeinput.type);
      serviceScopeinput.rateCardMapping = that.formatcardType(
        serviceScopeinput.rateCardMapping
      );
      that.serviceScopeInput = serviceScopeinput;
      that.addServiceScopeInputMode = false;
      that.editServiceScopeInputMode = true;
      that.sectionHeader = "Edit Scope Input";
      that.serviceScopeinputDialog = true;
      that.viewserviceScopeDialog = false;
    },
    handleActivateDeactive(servicescope) {
      let that = this;
      let serviceScope = servicescope;
      that.serviceTaskScope = serviceScope;
      that.sectionconfirmationDialog = true;
      that.serviceSection = servicescope;
      that.isTask = false;
    },

    formatType(value) {
      let that = this;
      let type = _.filter(that.inputoptions, (mr) => mr.value === value);
      return (value = type.length ? type[0].text : value);
    },
    formatcardType(value) {
      let that = this;
      let type = _.filter(that.cardoptions, (mr) => mr.value === value);
      return (value = type.length ? type[0].text : value);
    },
    formatMasterServiceConfig(value) {
      let that = this;
      let type = _.filter(that.masterServiceConfigs, (mr) => mr.slug === value);
      return (value = type.length ? type[0].title : value);
    },
    saveServiceScope() {
      let that = this;
      let serviceconfig = that.serviceConfig;
      let servicescope = that.serviceScope;
      let scopeInput = that.serviceScopeInput;
      that.submitted = true;
      let servicescopeInputs = that.serviceScopeInputs;
      servicescope.inputs = servicescope.inputs || [];
      if (servicescopeInputs.length > 0) {
        _.each(servicescopeInputs, (servicescopeInput) => {
          servicescope.inputs.push(servicescopeInput);
        });
        that.updateServiceConfig(serviceconfig);
      } else if (scopeInput.title.length > 0 && scopeInput.slug.length > 0) {
        scopeInput.type =
          typeof scopeInput.type != "string"
            ? scopeInput.type.value
            : scopeInput.typeval;
        scopeInput.rateCardMapping =
          typeof scopeInput.rateCardMapping != "string"
            ? scopeInput.rateCardMapping.value
            : scopeInput.rateCardMappingval;
        scopeInput.value =
          typeof scopeInput.value != "string"
            ? scopeInput.value
            : scopeInput.value.split(",");
        that.serviceScopeinputDialog = false;
        that.updateServiceConfig(serviceconfig);
      }
    },
  },
  props: {},
  watch: {},
  data() {
    return {
      filters: {},
      lawfirms: null,
      subOfferingTypes: null,
      masterServiceConfigs: null,
      services: null,
      templates: null,
      serviceConfig: {},
      serviceTaskScope: {},
      serviceTaskItems: [],
      serviceTaskItem: {},
      serviceTask: {},
      serviceTasksScopes: [],
      serviceSection: {},
      serviceScopeInput: {},
      serviceScope: {},
      serviceScopeInputs: [],
      expandedRows: [],
      expandedNestRows: [],
      expandedNestexpandedRows: [],
      serviceSections: [],
      sectionHeader: null,
      serviceConfigs: null,
      scopeHeader: null,
      taskHeader: null,
      confirmationDialog: false,
      addServiceConfigMode: false,
      editServiceConfigMode: false,
      serviceConfigDialog: false,
      submitted: false,
      addServiceSectionMode: false,
      editServiceSectionMode: false,
      addServiceScopeInputMode: false,
      editServiceScopeInputMode: false,
      serviceSectionDialog: false,
      isloading: false,
      isPresection: false,
      isTask: false,
      isTaskItem: false,
      isModified: false,
      viewserviceSectionDialog: false,
      serviceTaskScopeDialog: false,
      addServiceTaskMode: false,
      editServiceTaskMode: false,
      addServiceTaskItemMode: false,
      editServiceTaskItemMode: false,
      serviceTaskItemDialog: false,
      isServiceTaskItem: false,
      isServiceTaskNestedItem: false,
      sectionconfirmationDialog: false,
      viewserviceScopeDialog: false,
      serviceScopeinputDialog: false,
      tableObject: {
        header: "ServiceScopes",
        records: null,
        isexpandable: false,
        isloading: true,
        component: "Service Configuration",
      },
      inputoptions: [
        { value: 0, text: "Dollar" },
        { value: 1, text: "Hour" },
        { value: 2, text: "Number" },
        { value: 3, text: "Text" },
        { value: 4, text: "Select" },
      ],
      cardoptions: [
        { value: 0, text: "Hours" },
        { value: 1, text: "TaskPrice" },
      ],
    };
  },
};
</script>

<style scoped>
</style>f