<template>
  <div id="confimationdialogComponent">
    <Dialog
      :visible.sync="flages.viewDialog"
      :style="data.actionType == 'Edit' ? { width: '40vw' } : { width: '70vw' }"
      :header="'Overviews of ' + data.record.title"
      :modal="true"
      :closable="false"
      :maximizable="true"
    >
      <div class="confirmation-content"></div>
      <NestedDataTable
        ref="nestedDataTable"
        :data="tableObject"
        :handleNestedSave="handlealert"
        :method="handleNestedItemEdit"
      />
      <template #footer>
        <Button
          label="Canel"
          icon="pi pi-times"
          class="p-button-warning"
          @click="handleCancel()"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
 import _ from "lodash";
import NestedDataTable from "./NestedDataTable";
export default {
  name: "confimationdialogComponent",
  components: { NestedDataTable },
  beforeMount() {
    let that = this;
    let type = that.data.type;
    let record =
      type == "overwritesview"
        ? that.data.record.overViewOverWrites
        : that.data.record.overView;
    that.tableObject.records = record;
    that.tableObject.rootObject = that.data.record;
    that.tableObject.isaddoverview = type == "overwritesview" ? false : true;
    that.tableObject.nestedtype =type;
    that.tableObject.columns =
      type == "overwritesview"
        ? _.reject(
            that.tableObject.columns,
            (column) => column.Type == ""
          )
        : _.reject(
            that.tableObject.columns,
            (column) => column.Type == "overview"
          );
    // _.each(that['data'].record['overView'], (record) => {
    //   record.iseditoverview = true;
    // });
  },

  created() {},
  methods: {
    handlealert() {
      this.$parent.handleSave();
    },
    handleNestedItemEdit(overwrite, type, data) {
      let nestedtype=this.tableObject.nestedtype;
      if (type == "nestededit") {
        this.$parent.toggleNestedDisplayDialog(overwrite, type, data,nestedtype);
      } else if (type == "nesteddelete") {
        this.$parent.toggleNestedConfirmationDialog(overwrite, type, data,nestedtype);
      } else if (type == "nestedadd") {
        this.$parent.toggleNestedDisplayDialog(overwrite, type, data.rootObject);
      }
    },
  },
  props: {
    flages: { type: Object },
    handleCancel: { type: Function },
    handleSave: { type: Function },
    data: { type: Object },
  },
  watch: {},
  data() {
    return {
      tableObject: {
        header: "",
        columns: [
          { field: "", header: "Title", Type: "" },
          { field: "title", header: "Title", Type: "overview" },
          {
            field: "incidentTypeSlug",
            header: "IncidentType",
            Type: "overview",
          },
          {
            field: "subofferingTypeSlug",
            header: "Suboffering",
            Type: "overview",
          },
          { field: "lawfirmSlug", header: "Lawfirm", Type: "overview" },
          { field: "isActive", header: "Status", Type: "overview" },
          { field: "actions", header: "Actions", Type: "overview" },
        ],
        records: [],
        iseditoverview: true,
        isaddoverview: true,
        nestedtype:""

      },
    };
  },
};
</script>
<style>
</style>