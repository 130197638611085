<template>
  <div id="componentExpanddatatable">
    >
    <DataTable
      :value="data.inputs"
      :expandedRows.sync="expandedRows"
      dataKey="title"
      sortMode="multiple"
      removableSort
    >
      <template #header>
        <div class="p-grid">
          <div class="p-col-4 p-text-left"></div>
          <div class="p-col-4">
            <span class="p-text-center"><b>Scope Inputs</b></span>
          </div>
          <div class="p-col-4 p-text-right"></div>
        </div>
      </template>
      <Column
        :expander="true"
        headerStyle="width: 3rem"
        :headerClass="'nthheader'"
      />
      <Column
        field="slug"
        header="Slug"
        :sortable="true"
        :headerClass="'nthheader'"
      >
      </Column>
      <Column
        field="type"
        header="Type"
        :sortable="true"
        :headerClass="'nthheader'"
      >
        <template #body="slotProps">
          {{ formatType(slotProps.data.type) }}
          </template >
      </Column>
      <Column
        field="defaultValue"
        header="DefaultValue"
        :sortable="true"
        :headerClass="'nthheader'"
      >
      </Column>
      <Column
        field="value"
        header="Values"
        :sortable="true"
        :headerClass="'nthheader'"
      >
      </Column>
      <Column
        field="rateCardMapping"
        header="RCard"
        :sortable="true"
        :headerClass="'nthheader'"
      >
      </Column>
      <Column
        field="notes"
        header="Notes"
        :sortable="true"
        :headerClass="'nthheader'"
      >
      </Column>
      <Column
        field="title"
        header="Title"
        :sortable="true"
        :headerClass="'nthheader'"
      >
      </Column>
      <Column
        field="description"
        header="Description"
        :sortable="true"
        :headerClass="'nthheader'"
      >
      </Column>
      <Column :headerClass="'nthheader'" header="Actions">
        <template #body="slotProps">
          <Button
            icon="pi pi-plus"
            v-tooltip.top="'Add Inputs'"
            @click="add(slotProps.data)"
            class="p-button-raised p-button-primary p-mr-3"
          />
          <Button
            icon="pi pi-pencil"
            v-tooltip.top="'Edit Section'"
            @click="edit(slotProps.data)"
            class="p-button-raised p-button-help p-mr-2"
          />
          <Button
            icon="pi pi-trash"
            v-if="slotProps.data.isActive"
            v-tooltip.top="'Deactivate Scope'"
            @click="activatedeactive(slotProps.data)"
            class="p-button-raised p-button-warning p-mr-2"
          />
          <Button
            v-else-if="!slotProps.data.isActive"
            icon="pi pi-check-circle"
            v-tooltip.top="'Activate Scope'"
            @click="activatedeactive(slotProps.data)"
            class="p-button-raised p-button-success p-mr-2"
          />
        </template>
      </Column>
      <template #expansion="slotPropsinputs">
        <div class="datatable-subtable p-m-4">
          <DataTable
            :expandedRows.sync="nestedexpandedRows"
            :value="slotPropsinputs.data.inputs"
            dataKey="slug"
            sortMode="multiple"
            removableSort
          >
            <template #header>
              <div class="p-grid">
                <div class="p-col-4 p-text-left"></div>
                <div class="p-col-4">
                  <span class="p-text-center"><b>service input inputs</b></span>
                </div>
                <div class="p-col-4 p-text-right"></div>
              </div>
            </template>
            <Column
              :expander="true"
              headerStyle="width: 3rem"
              :headerClass="'nthheader'"
            />
            <Column
              field="slug"
              header="Slug"
              :sortable="true"
              :headerClass="'nthheader'"
            >
            </Column>
            <Column
              field="type"
              header="Type"
              :sortable="true"
              :headerClass="'nthheader'"
            >
                    <template #body="slotProps">
          {{ formatType(slotProps.data.type) }}
          </template >
            </Column>
            <Column
              field="value"
              header="Values"
              :sortable="true"
              :headerClass="'nthheader'"
            ></Column>
            <Column
              field="rateCardMapping"
              header="RCMapping"
              :sortable="true"
              :headerClass="'nthheader'"
            >
            </Column>
            <Column
              field="notes"
              header="Notes"
              :sortable="true"
              :headerClass="'nthheader'"
            >
            </Column>
            <Column
              field="title"
              header="Title"
              :sortable="true"
              :headerClass="'nthheader'"
            >
            </Column>
            <Column
              field="description"
              header="Description"
              :sortable="true"
              :headerClass="'nthheader'"
            >
            </Column>
            <Column :headerClass="'nthheader'" header="Actions">
              <template #body="slotPropsinputs">
                <Button
                  icon="pi pi-plus"
                  v-tooltip.top="'Add Inputs'"
                  @click="add(slotPropsinputs.data)"
                  class="p-button-raised p-button-info p-mr-3"
                />
                <Button
                  icon="pi pi-pencil"
                  v-tooltip.top="'Edit ScopeInput'"
                  @click="edit(slotPropsinputs.data)"
                  class="p-button-raised p-button-secondary p-mr-2"
                />
                <Button
                  icon="pi pi-trash"
                  v-if="slotPropsinputs.data.isActive"
                  v-tooltip.top="'Deactivate Scope'"
                  @click="activatedeactive(slotPropsinputs.data)"
                  class="p-button-raised p-button-warning p-mr-2"
                />
                <Button
                  v-else-if="!slotPropsinputs.data.isActive"
                  icon="pi pi-check-circle"
                  v-tooltip.top="'Activate Scope'"
                  @click="activatedeactive(slotPropsinputs.data)"
                  class="p-button-raised p-button-success p-mr-2"
                />
              </template>
            </Column>
            <template #expansion="slotPropsnestedinputs">
              <div class="datatable-subtable p-m-4">
                <DataTable
                  :expandedRows.sync="nestedinputexpandedRows"
                  :value="slotPropsnestedinputs.data.inputs"
                  dataKey="title"
                  sortMode="multiple"
                  removableSort
                >
                  <template #header>
                    <div class="p-grid">
                      <div class="p-col-4 p-text-left"></div>
                      <div class="p-col-4">
                        <span class="p-text-center"
                          ><b>service input nested inputs</b></span
                        >
                      </div>
                      <div class="p-col-4 p-text-right"></div>
                    </div>
                  </template>
                  <Column
                    :expander="true"
                    headerStyle="width: 3rem"
                    :headerClass="'nthheader'"
                  />
                  <Column
                    field="slug"
                    header="Slug"
                    :sortable="true"
                    :headerClass="'thheader'"
                  >
                  </Column>
                  <Column
                    field="type"
                    header="Type"
                    :sortable="true"
                    :headerClass="'thheader'"
                  >
                          <template #body="slotProps">
          {{ formatType(slotProps.data.type) }}
          </template >
                  </Column>
                  <Column
                    field="value"
                    header="Values"
                    :sortable="true"
                    :headerClass="'nthheader'"
                  ></Column>
                  <Column
                    field="rateCardMapping"
                    header="RCMapping"
                    :sortable="true"
                    :headerClass="'thheader'"
                  >
                  </Column>
                  <Column
                    field="notes"
                    header="Notes"
                    :sortable="true"
                    :headerClass="'thheader'"
                  >
                  </Column>
                  <Column
                    field="title"
                    header="Title"
                    :sortable="true"
                    :headerClass="'thheader'"
                  >
                  </Column>
                  <Column
                    field="description"
                    header="Description"
                    :sortable="true"
                    :headerClass="'thheader'"
                  >
                  </Column>
                  <Column :headerClass="'thheader'" header="Actions">
                    <template #body="slotPropsnestedinputsitem">
                      <Button
                        icon="pi pi-plus"
                        v-tooltip.top="'Add Inputs'"
                        @click="add(slotPropsnestedinputsitem.data)"
                        class="p-button-raised p-button-info p-mr-3"
                      />
                      <Button
                        icon="pi pi-pencil"
                        v-tooltip.top="'Edit Section'"
                        @click="edit(slotPropsnestedinputsitem.data)"
                        class="p-button-raised p-button-section p-mr-2"
                      />
                      <Button
                        icon="pi pi-trash"
                        v-if="slotPropsnestedinputsitem.data.isActive"
                        v-tooltip.top="'Deactivate Scope'"
                        @click="
                          activatedeactive(slotPropsnestedinputsitem.data)
                        "
                        class="p-button-raised p-button-warning p-mr-2"
                      />
                      <Button
                        v-else-if="!slotPropsnestedinputsitem.data.isActive"
                        icon="pi pi-check-circle"
                        v-tooltip.top="'Activate Scope'"
                        @click="
                          activatedeactive(slotPropsnestedinputsitem.data)
                        "
                        class="p-button-raised p-button-success p-mr-2"
                      />
                    </template>
                  </Column>
                  <template #expansion="slotPropsnestedinputsNested">
                    <div class="datatable-subtable p-m-4">
                      <DataTable
                        :expandedRows.sync="nestedinputnestedexpandedRows"
                        :value="slotPropsnestedinputsNested.data.inputs"
                        dataKey="title"
                        sortMode="multiple"
                        removableSort
                      >
                        <template #header>
                          <div class="p-grid">
                            <div class="p-col-4 p-text-left"></div>
                            <div class="p-col-4">
                              <span class="p-text-center"
                                ><b>service input nested inputs</b></span
                              >
                            </div>
                            <div class="p-col-4 p-text-right"></div>
                          </div>
                        </template>
                        <Column
                          :expander="true"
                          headerStyle="width: 3rem"
                          :headerClass="'nthheader'"
                        />
                        <Column
                          field="slug"
                          header="Slug"
                          :sortable="true"
                          :headerClass="'thheader'"
                        >
                        </Column>
                        <Column
                          field="type"
                          header="Type"
                          :sortable="true"
                          :headerClass="'thheader'"
                        >
                                <template #body="slotProps">
          {{ formatType(slotProps.data.type) }}
          </template >
                          <Column
                            field="value"
                            header="Values"
                            :sortable="true"
                            :headerClass="'nthheader'"
                          ></Column>
                        </Column>
                        <Column
                          field="rateCardMapping"
                          header="RCMapping"
                          :sortable="true"
                          :headerClass="'thheader'"
                        >
                        </Column>
                        <Column
                          field="notes"
                          header="Notes"
                          :sortable="true"
                          :headerClass="'thheader'"
                        >
                        </Column>
                        <Column
                          field="title"
                          header="Title"
                          :sortable="true"
                          :headerClass="'thheader'"
                        >
                        </Column>
                        <Column
                          field="description"
                          header="Description"
                          :sortable="true"
                          :headerClass="'thheader'"
                        >
                        </Column>
                        <Column :headerClass="'thheader'" header="Actions">
                          <template #body="slotPropsnestedinputsNesteditem">
                            <Button
                              icon="pi pi-pencil"
                              v-tooltip.top="'Edit Section'"
                              @click="
                                edit(slotPropsnestedinputsNesteditem.data)
                              "
                              class="p-button-raised p-button-section p-mr-2"
                            />
                            <Button
                              icon="pi pi-trash"
                              v-if="
                                slotPropsnestedinputsNesteditem.data.isActive
                              "
                              v-tooltip.top="'Deactivate Scope'"
                              @click="
                                activatedeactive(
                                  slotPropsnestedinputsNesteditem.data
                                )
                              "
                              class="p-button-raised p-button-warning p-mr-2"
                            />
                            <Button
                              v-else-if="
                                !slotPropsnestedinputsNesteditem.data.isActive
                              "
                              icon="pi pi-check-circle"
                              v-tooltip.top="'Activate Scope'"
                              @click="
                                activatedeactive(
                                  slotPropsnestedinputsNesteditem.data
                                )
                              "
                              class="p-button-raised p-button-success p-mr-2"
                            />
                          </template>
                        </Column>
                        <template #expansion>
                          <div class="datatable-subtable p-m-4"></div>
                        </template>
                      </DataTable>
                    </div>
                  </template>
                </DataTable>
              </div>
            </template>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import _ from "lodash";
export default {
  name: "expandDataTableComponent",
  beforeMount() {},
  created() {},
  methods: {
    onRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: `Table Row Expanded`,
        detail: event.data.Title,
        life: 3000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: `Table Row Closed`,
        detail: event.data.Title,
        life: 3000,
      });
    },
    closeBasic() {
      this.displayBasic = false;
    },
    onNestRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Service Taks Items Expanded",
        detail: event.data.title,
        life: 3000,
      });
    },
    onNestRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Service Taks Items Collapsed",
        detail: event.data.title,
        life: 3000,
      });
    },
    onNestedNestRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Service Taks Items Expanded",
        detail: event.data.title,
        life: 3000,
      });
    },
    onNestedNestRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Service Taks Items Collapsed",
        detail: event.data.title,
        life: 3000,
      });
    },
    formatType(value) {
      let that = this;
      let type = _.filter(that.inputoptions, (mr) => mr.value === value);
      return (value = type.length ? type[0].text : value);
    },
  },
  props: {
    data: { type: Object },
    add: { type: Function },
    edit: { type: Function },
    activatedeactive: { type: Function },
  },
  watch: {},
  data() {
    return {
      inputoptions: [
        { value: 0, text: "Dollar" },
        { value: 1, text: "Hour" },
        { value: 2, text: "Number" },
        { value: 3, text: "Text" },
        { value: 4, text: "Select" },
      ],
      response: {},
      columns: [],
      expandedRows: [],
      nestedexpandedRows: [],
      nestedinputexpandedRows: [],
      nestedinputnestedexpandedRows: [],
    };
  },
};
</script>
<style scoped>
</style>