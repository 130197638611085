var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"componentExpanddatatable"}},[_c('DataTable',{attrs:{"value":_vm.data.records
        ? _vm.data.records
        : _vm.data.data.attorneys
        ? _vm.data.data.attorneys
        : _vm.data.data.titleOverWrites,"expandedRows":_vm.data.expandedRows,"dataKey":"title","sortMode":"multiple","removableSort":""},on:{"update:expandedRows":function($event){return _vm.$set(_vm.data, "expandedRows", $event)},"update:expanded-rows":function($event){return _vm.$set(_vm.data, "expandedRows", $event)}},scopedSlots:_vm._u([(_vm.data.isaddoverview)?{key:"header",fn:function(){return [_c('div',{staticClass:"p-grid"},[_c('div',{staticClass:"p-col-4 p-text-left"}),_c('div',{staticClass:"p-col-4"},[_c('span',{staticClass:"p-text-center"},[_c('b',[_vm._v(_vm._s(_vm.data.header))])])]),_c('div',{staticClass:"p-col-4 p-text-right"},[_c('Button',{staticClass:"p-button-raised p-button-info p-mr-3",attrs:{"icon":"pi pi-plus","label":"Add Overview Overwrite"},on:{"click":function($event){return _vm.method(_vm.data, 'nestedadd', _vm.data)}}})],1)])]},proxy:true}:null,{key:"expansion",fn:function(){return [_c('div',{staticClass:"datatable-subtable p-m-4"},[_c('p')])]},proxy:true}],null,true)},[(_vm.data.isexpandable)?_c('Column',{attrs:{"expander":true,"headerStyle":"width: 3rem","headerClass":'nthheader'}}):_vm._e(),_vm._l((_vm.data.columns),function(col){return _c('Column',{key:col.field,attrs:{"field":col.field,"header":col.header,"sortable":true,"headerClass":col.class,"bodyClass":col.colClass},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(col.field == 'isActive')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatStatus")(slotProps.data[col.field]))+" ")]):(col.field == 'isDisabled')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatStatus")(slotProps.data[col.field]))+" ")]):(col.field == 'actions')?_c('span',[(
              slotProps.data.iseditattorney ||
              slotProps.data.iseditoverwrite ||
              slotProps.data.iseditoverviewoverwrite
            )?_c('div',[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Edit Row'),expression:"'Edit Row'",modifiers:{"top":true}}],staticClass:"p-button-rounded p-button-info p-mr-3",attrs:{"icon":"pi pi-pencil"},on:{"click":function($event){return _vm.method(
                  slotProps.data,
                  'nestededit',
                  _vm.data,
                  slotProps.data.iseditoverviewoverwrite ? 'overview' : ''
                )}}}),(slotProps.data.isActive)?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Deactivate'),expression:"'Deactivate'",modifiers:{"top":true}}],staticClass:"p-button-rounded p-button-warning p-mr-3",attrs:{"icon":"pi pi-trash"},on:{"click":function($event){return _vm.method(
                  slotProps.data,
                  'nesteddelete',
                  _vm.data,
                  slotProps.data.iseditoverviewoverwrite ? 'overview' : ''
                )}}}):_vm._e(),(!slotProps.data.isActive)?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('Activate'),expression:"'Activate'",modifiers:{"right":true}}],staticClass:"p-button-rounded p-button-success p-mr-3",attrs:{"icon":"pi pi-check-circle"},on:{"click":function($event){return _vm.method(
                  slotProps.data,
                  'nesteddelete',
                  _vm.data,
                  slotProps.data.iseditoverviewoverwrite ? 'overview' : ''
                )}}}):_vm._e()],1):_c('div',[_c('Button',{staticClass:"p-button-rounded p-button-warning p-mr-3",attrs:{"icon":"pi pi-trash"},on:{"click":function($event){return _vm.method(slotProps.data, 'nesteddelete', _vm.data)}}})],1)]):(col.field != '')?_c('span',[_vm._v(" "+_vm._s(slotProps.data[col.field]))]):_c('span',[_vm._v(" "+_vm._s(slotProps.data)+" ")])]}}],null,true)})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }