<template>
    <div class="layout-topbar">
        <div id="logocontainer">
            <button class="p-link layout-menu-button" @click="onMenuToggle">
                <span class="pi pi-bars"></span>
            </button>
            <div class="arete-logo-container">
                <div class="text-logo">
                 <router-link to="/countries" >
                        <b>Sowlution</b> Admin App
                 </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                userMenuItems: [
                    {
                        label: 'Logout',
                        icon: 'pi pi-sign-out',
                        command: () => {
                            this.logout();
                        }
                    }
                ]
            };
        },
        methods: {
            onMenuToggle(event) {
                this.$emit('menu-toggle', event);
            },
            userProfiletoggle(event) {
                this.$refs.menu.toggle(event);
            },
            logout() {
                this.$store.dispatch('login/logout').then(() => {
                    this.$router.push({ name: 'login' });
                });
            },
        },
    };
</script>
<style lang="scss" scoped>
    .avatar {
        vertical-align: middle;
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    #logocontainer {
        display: inline-flex;
        align-items: center;
    }

        #logocontainer a {
            margin-left: 0.25rem;
        }

    .arete-logo-container a {
        margin-left: 0.25rem;
    }

    .layout-topbar .arete-logo-container .text-logo a {
        color: #fff;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        font-size: 20px;
        padding: 3px;
        text-decoration: none
    }

        .layout-topbar .arete-logo-container .text-logo a b {
            font-weight: 600;
        }
</style>