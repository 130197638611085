<template>
  <div id="componentExpanddatatable">
    <DataTable
      :value="
        data.records
          ? data.records
          : data.data.attorneys
          ? data.data.attorneys
          : data.data.titleOverWrites
      "
      :expandedRows.sync="data.expandedRows"
      dataKey="title"
      sortMode="multiple"
      removableSort
    >
      <template #header v-if="data.isaddoverview">
        <div class="p-grid">
          <div class="p-col-4 p-text-left"></div>
          <div class="p-col-4">
            <span class="p-text-center"
              ><b>{{ data.header }}</b></span
            >
          </div>
          <div class="p-col-4 p-text-right">
            <Button
              icon="pi pi-plus"
              class="p-button-raised p-button-info p-mr-3"
              label="Add Overview Overwrite"
              @click="method(data, 'nestedadd', data)"
            />
          </div>
        </div>
      </template>
      <Column
        :expander="true"
        v-if="data.isexpandable"
        headerStyle="width: 3rem"
        :headerClass="'nthheader'"
      />
      <Column
        v-for="(col) of data.columns"
        :field="col.field"
        :header="col.header"
        :sortable="true"
        :headerClass="col.class"
        :bodyClass="col.colClass"
        :key="col.field"
      >
        <template #body="slotProps">
          <span v-if="col.field == 'isActive'">
            {{ slotProps.data[col.field] | formatStatus }}
          </span>
          <span v-else-if="col.field == 'isDisabled'">
            {{ slotProps.data[col.field] | formatStatus }}
          </span>
          <span v-else-if="col.field == 'actions'">
            <div
              v-if="
                slotProps.data.iseditattorney ||
                slotProps.data.iseditoverwrite ||
                slotProps.data.iseditoverviewoverwrite
              "
            >
              <Button
                icon="pi pi-pencil"
                v-tooltip.top="'Edit Row'"
                class="p-button-rounded p-button-info p-mr-3"
                @click="
                  method(
                    slotProps.data,
                    'nestededit',
                    data,
                    slotProps.data.iseditoverviewoverwrite ? 'overview' : ''
                  )
                "
              />
              <Button
                icon="pi pi-trash"
                v-if="slotProps.data.isActive"
                v-tooltip.top="'Deactivate'"
                class="p-button-rounded p-button-warning p-mr-3"
                @click="
                  method(
                    slotProps.data,
                    'nesteddelete',
                    data,
                    slotProps.data.iseditoverviewoverwrite ? 'overview' : ''
                  )
                "
              />
              <Button
                icon="pi pi-check-circle"
                v-tooltip.right="'Activate'"
                v-if="!slotProps.data.isActive"
                class="p-button-rounded p-button-success p-mr-3"
                @click="
                  method(
                    slotProps.data,
                    'nesteddelete',
                    data,
                    slotProps.data.iseditoverviewoverwrite ? 'overview' : ''
                  )
                "
              />
            </div>
            <div v-else>
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning p-mr-3"
                @click="method(slotProps.data, 'nesteddelete', data)"
              />
            </div>
          </span>
          <span v-else-if="col.field != ''">
            {{ slotProps.data[col.field] }}</span
          >
          <span v-else> {{ slotProps.data }} </span>
        </template>
      </Column>
      <template #expansion>
        <div class="datatable-subtable p-m-4">
          <p></p>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "expandDataTableComponent",
  beforeMount() {
    let that = this;
    let dataobj = that.data.data ? that.data.data : that.data.records;
    let coltype = "attorneys" in dataobj ? "Service" : "Lawfirm";
    let columns = (that.columns = _.reject(
      that.columns,
      (column) => column.Type == coltype
    ));
    that.data.columns = that.data.columns || columns;
    //Action Buttouns
    if ("attorneys" in dataobj) {
      _.each(that["data"].data.attorneys, (attorney) => {
        attorney.iseditattorney = true;
      });
    } else if ("titleOverWrites" in dataobj) {
      _.each(that["data"].data.titleOverWrites, (Overwrite) => {
        Overwrite.iseditoverwrite = true;
      });
    } else if (that.data.nestedtype == "overwritesview") {
      _.each(that["data"].records, (record) => {
        record.iseditoverviewoverwrite = true;
      });
    }
  },
  created() {},
  methods: {
    onRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: `Table Row Expanded`,
        detail: event.data.Title,
        life: 3000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: `Table Row Closed`,
        detail: event.data.Title,
        life: 3000,
      });
    },
    closeBasic() {
      this.displayBasic = false;
    },
  },
  props: {
    data: { type: Object },
    method: { type: Function },
    handleNestedSave: { type: Function },
  },
  watch: {},
  data() {
    return {
      response: {},
      columns: [
        { field: "name", header: "Name", Type: "Lawfirm",class:"name nthheader" },
        { field: "email", header: "Email", Type: "Lawfirm",class:"email nthheader", colClass:"word-break" },
        { field: "streetAddress", header: "Address", Type: "Lawfirm",class:"address nthheader"},
        { field: "title", header: "Title", Type: "Lawfirm",class:"title nthheader" },
        { field: "city", header: "City", Type: "Lawfirm",class:"city nthheader" },
        { field: "state", header: "State", Type: "Lawfirm",class:"state nthheader"  },
        { field: "zipCode", header: "ZipCode", Type: "Lawfirm",class:"zipcode nthheader"},
        { field: "title", header: "Title Overwrite", Type: "Service",class:"overwrite nthheader"  },
        { field: "incidentTypeSlug", header: "IncidentType", Type: "Service",class:"incidenttype nthheader"},
        {
          field: "subofferingTypeSlug",
          header: "SubofferingType",
          Type: "Service",
          class:"incidenttype nthheader"
        },
        { field: "lawfirmSlug", header: "Lawfirm", Type: "Service",class:"lawfirmslug nthheader" },
        { field: "isDisabled", header: "Disabled", Type: "Service",class:"disabled nthheader" },
        {
          field: "isActive",
          header: "Status",
          Type: "Service",
          class: "status nthheader",
        },
        { field: "actions", header: "Actions", class:"nactions nthheader" },
      ],
    };
  },
};
</script>
<style scoped>
</style>