import Vue from "vue";
import Router from "vue-router";
import LoginComponent from "./components/Login.vue";
import DefaultComponent from "./components/Default.vue";
import CountriesComponent from "./components/SowlutionAdmin/Countries.vue";
import DocumentTypesComponent from "./components/SowlutionAdmin/DocumentTypes.vue";
import IncidentTypesComponent from "./components/SowlutionAdmin/IncidentTypes.vue";
import SubOfferingTypesComponent from "./components/SowlutionAdmin/SubOfferingTypes.vue";
import LawfirmsComponent from "./components/SowlutionAdmin/Lawfirms.vue";
import ServicesComponent from "./components/SowlutionAdmin/Services.vue";
import TemplateConfigComponent from "./components/SowlutionAdmin/TemplateConfigs.vue";
import BaaNdaConfigComponent from "./components/SowlutionAdmin/BaaNdaTemplateConfig.vue";
import ServiceConfigComponent from "./components/SowlutionAdmin/ServiceConfigs.vue";
import MasterTemplateComponent from "./components/SowlutionAdmin/MasterTemplates.vue";
import MasterServiceConfigComponent from "./components/SowlutionAdmin/MasterServiceConfig.vue";
import AuthoritiesComponent from "./components/SowlutionAdmin/Authorities.vue";
import AddressesComponent from "./components/SowlutionAdmin/Addresses.vue"
import store from "./store/index";

Vue.use(Router);

//function checkAuthorization(to, from, next) {
//    const requiredRole = to.meta.requiredRole;
//    if (to.meta && to.meta.requiredRole) {
//        const roles = store.getters['login/getUserRoles'];
//        //check if the user has required role
//        let userRoles = roles.filter(r => r == requiredRole);
//        if (userRoles.length == 1) {
//            //if user has the required role
//            next()
//        } else {
//            //if user doesn't have the required role then
//            //navigate to home page
//            next('errorpage');
//        }
//    } else {
//        next();
//    }
//}

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: LoginComponent,
    },
    {
      path: "/",
      redirect: "/login",
      component: DefaultComponent,
      children: [
        {
          name: "countries",
          path: "/countries",
          component: CountriesComponent,
          //beforeEnter: checkAuthorization,
          //meta: {
          //    requiredRole: 'GLOBALADMIN'
          //},
        },
        {
          name: "documenttypes",
          path: "/documenttypes",
          component: DocumentTypesComponent,
          //beforeEnter: checkAuthorization,
          //meta: {
          //    requiredRole: 'GLOBALADMIN'
          //},
        },
        {
          name: "incidenttypes",
          path: "/incidenttypes",
          component: IncidentTypesComponent,
          //beforeEnter: checkAuthorization,
          //meta: {
          //    requiredRole: 'GLOBALADMIN'
          //},
        },
        {
          name: "authorities",
          path: "/authorities",
          component: AuthoritiesComponent,
          //beforeEnter: checkAuthorization,
          //meta: {
          //    requiredRole: 'GLOBALADMIN'
          //},
        },
        {
          name: "addresses",
          path: "/addresses",
          component: AddressesComponent,
          //beforeEnter: checkAuthorization,
          //meta: {
          //    requiredRole: 'GLOBALADMIN'
          //},
        },
        {
          name: "subofferings",
          path: "/subofferings",
          component: SubOfferingTypesComponent,
          //beforeEnter: checkAuthorization,
          //meta: {
          //    requiredRole: 'GLOBALADMIN'
          //},
        },
        {
          name: "lawfirms",
          path: "/lawfirms",
          component: LawfirmsComponent,
          //beforeEnter: checkAuthorization,
          //meta: {
          //    requiredRole: 'GLOBALADMIN'
          //},
        },
        {
          name: "services",
          path: "/services",
          component: ServicesComponent,
          //beforeEnter: checkAuthorization,
          //meta: {
          //    requiredRole: 'GLOBALADMIN'
          //},
        },
        {
          name: "templateconfiguration",
          path: "/templateconfiguration",
          component: TemplateConfigComponent,
          //beforeEnter: checkAuthorization,
          //meta: {
          //    requiredRole: 'GLOBALADMIN'
          //},
        },
        {
          name: "baandaconfiguration",
          path: "/baandaconfiguration",
          component: BaaNdaConfigComponent,
          //beforeEnter: checkAuthorization,
          //meta: {
          //    requiredRole: 'GLOBALADMIN'
          //},
        },
        {
          name: "serviceconfiguration",
          path: "/serviceconfiguration",
          component: ServiceConfigComponent,
          //beforeEnter: checkAuthorization,
          //meta: {
          //    requiredRole: 'GLOBALADMIN'
          //},
        },
        {
          name: "mastertemplates",
          path: "/mastertemplates",
          component: MasterTemplateComponent,
          //beforeEnter: checkAuthorization,
          //meta: {
          //    requiredRole: 'GLOBALADMIN'
          //},
        },
        {
          name: "masterserviceconfigs",
          path: "/masterserviceconfig",
          component: MasterServiceConfigComponent,
          //beforeEnter: checkAuthorization,
          //meta: {
          //    requiredRole: 'GLOBALADMIN'
          //},
        },
        {
          path: "*",
          component: () => import("./views/404.vue"),
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch("login/acquireTokenSilent");
  const isAuthenticated = store.getters["login/getIsUserAuthenticated"];
  if (to.name !== "login" && !isAuthenticated) {
    next("/login");
  } else {
    next();
  }
});

export default router;
