<template>
  <div id="componentAuthorities" style="height: calc(100vh - 165px)">
    <DataTable
      :scrollable="true"
      scrollHeight="flex"
      :loading="isloading"
      :filters="filters"
      sortMode="multiple"
      removableSort
      :value="mainAuthorities"
      :paginator="true"
      :rows="20"
      :rowsPerPageOptions="[20, 40, 60, 80]"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      data-key="id"
      class="p-datatable-gridlines"
      :expandedRows.sync="expandedRows"
    >
      <template #header>
        <div class="p-grid">
          <div class="p-col-3 p-text-center mt-2 p-text-left">
            <span class=""><b>Authority Configuration</b></span>
          </div>
          <div class="p-col-6 text-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                style="width: 567px !important"
                v-model="filters['global']"
                placeholder="Global Search"
              />
            </span>
          </div>
          <div class="p-col-2">
            <Button
              icon="pi pi-plus"
              label="Add New"
              v-tooltip.top="'Add New'"
              class="p-button-raised p-button-info p-mr-3"
              @click="openNew()"
            />
          </div>
        </div>
      </template>
      <template #empty> No records found. </template>
      <Column
        field="name"
        header="Name"
        :sortable="true"
        :headerClass="'thheader'"
      ></Column>
      <Column
        field="title"
        header="Title"
        :sortable="true"
        :headerClass="'thheader'"
      ></Column>
      <Column
        field="email"
        header="Email"
        :sortable="true"
        :headerClass="'thheader'"
      ></Column>
      <Column
        field="contactNumber"
        header="Contact Number"
        :sortable="true"
        :headerClass="'thheader'"
      ></Column>
      <Column
        field="slug"
        header="Unique Code"
        :sortable="true"
        :headerClass="'thheader'"
      ></Column>
      <Column :headerClass="'thheader'" header="Actions">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            v-tooltip.top="'Edit Authority'"
            @click="editAuthority(slotProps.data)"
            class="p-button-raised p-button-info p-mr-2"
          />
          <Button
            icon="pi pi-trash"
            v-tooltip.top="'Deactivate Authority'"
            v-if="slotProps.data.isActive"
            @click="handleactivateDeactivateAuthority(slotProps.data)"
            class="p-button-raised p-button-warning p-mr-2"
          />
          <Button
            v-if="!slotProps.data.isActive"
            icon="pi pi-check-circle"
            v-tooltip.top="'Activate Authority'"
            @click="handleactivateDeactivateAuthority(slotProps.data)"
            class="p-button-raised p-button-success p-mr-2"
          />
        </template>
      </Column>
    </DataTable>
    <Dialog
      maximizable
      :visible.sync="authorityDialog"
      :style="{ width: '70vw' }"
      :header="editAuthorityMode ? 'Edit Authority' : 'Add Authority'"
      :modal="true"
      class="p-fluid"
    >
      <div>
        <br />
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="Name"
              v-model.trim="authority.name"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !authority.name }"
            />
            <label for="name">Name<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !authority.name"
            >Name is required.</span
          >
        </div>
        <div class="p-field p-col" v-if="addAuthorityMode">
          <span class="p-float-label">
            <InputText
              id="slug"
              v-model.trim="authority.slug"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !authority.slug }"
            />
            <label for="name">Slug<span class="p-invalid">*</span></label>
          </span>
          <small class="error-message" v-if="submitted && !authority.slug"
            >Slug is required.</small
          >
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="title"
              v-model.trim="authority.title"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !authority.title }"
            />
            <label for="name">Title<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !authority.title"
            >Title is required.</span
          >
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="email"
              v-model.trim="authority.email"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !authority.email }"
            />
            <label for="name">Email<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !authority.email"
            >Email is required.</span
          >
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="contactnumber"
              v-model.trim="authority.contactNumber"
              required="true"
              autofocus
            />
            <label for="name">Contact Number</label>
          </span>
        </div>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-warning"
          @click="hideDialog"
        />
        <Button label="Save" icon="pi pi-arrow-circle-up" @click="handleSave" />
      </template>
    </Dialog>
    <Dialog
      maximizable
      :visible.sync="confirmationDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span
          >Are you sure you want to
          {{ authority.isActive ? "delete" : "activate" }}
          <b>{{ authority.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideconDialog"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="activateDeactivateAuthority(authority)"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "authorityComponent",
  created() {
    this.fetchAuthorities();
  },
  components: {},
  methods: {
    onRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Master Authority Expanded",
        detail: event.data.title,
        life: 3000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Master Authority Collapsed",
        detail: event.data.title,
        life: 3000,
      });
    },
    fetchAuthorities() {
      var that = this;
      that.isloading = true;
      this.$store.dispatch("authority/fetchAuthorities").then(() => {
        that.tempresponse = this.$store.getters["authority/getAuthorities"];
        that.mainAuthorities = _.clone(that.tempresponse);
        that.isloading = false;
      });
    },
    openNew() {
      this.authority = {};
      this.submitted = false;
      this.editAuthorityMode = false;
      this.addAuthorityMode = true;
      this.authorityDialog = true;
    },
    editAuthority(authority) {
      console.log(authority);
      this.editAuthorityMode = true;
      this.addAuthorityMode = false;
      this.authority = _.clone(authority);
      this.authorityDialog = true;
    },
    hideDialog() {
      let that = this;
      this.authorityDialog = false;
      this.editAuthorityMode = true;
      this.addAuthorityMode = false;
      that.authority = {};
    },
    handleactivateDeactivateAuthority(authority) {
      let that = this;
      that.authority = authority;
      this.confirmationDialog = true;
    },
    activateDeactivateAuthority(authority) {
      let that = this;
      that.authority.isActive = !authority.isActive;
      this.handleSave();
      this.confirmationDialog = false;
    },
    hideconDialog() {
      let that = this;
      that.confirmationDialog = false;
    },
    handleSave() {
      let that = this;
      this.submitted = true;
      let authority = that.authority;
      if (
        that.authority.title &&
        that.authority.name &&
        that.authority.email &&
        that.authority.slug
      ) {
        let acttype = this.addAuthorityMode
          ? "saveAuthority"
          : "updateAuthority";
        this.$store
          .dispatch("authority/" + acttype, authority)
          .then(() => {
            that.fetchAuthorities();
            that.hideDialog();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  props: {},
  watch: {},
  data() {
    return {
      mainAuthorities: null,
      authority: {},
      submitted: false,
      addAuthorityMode: false,
      editAuthorityMode: false,
      authorityDialog: false,
      confirmationDialog: false,
      isloading: true,
      expandedRows: [],
      filters: {},
    };
  },
};
</script>

<style scoped>
</style>