<template>
  <div id="componentIncidentTypes">
    <IncidentTypesTable
      ref="incidentTypesTable"
      :data="tableObject"
      :method="incidenTypesActions"
      :syncmethod="syncIncidentTypes"
    />
    <IncidentTypeDialog
      v-if="flages.displayDialog"
      ref="incidentTypeDialog"
      :flages="flages"
      :handleCancel="toggleDialog"
      :handleSave="upsertIncidentType"
      :data="dialogObject"
    />
    <ConfirmationDialog
      v-if="flages.confirmdisplayDialog"
      ref="confirmdocumentTypeDialog"
      :flages="flages"
      :handleCancel="toggleConfirmationDialog"
      :handleSave="activateDeactivateIncidentType"
      :data="dialogObject"
    />
  </div>
</template>

<script>
import _ from "lodash";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import IncidentTypesTable from "../Shared/FetchDataTable";
import IncidentTypeDialog from "../Shared/UpsertDialog";
export default {
  name: "incidentTypesComponent",
  components: { IncidentTypesTable, IncidentTypeDialog, ConfirmationDialog },
  created() {
    this.fetchAuthorities();
    this.fetchIncidentTypes();
  },
  methods: {
    fetchAuthorities() {
      var that = this;
      that.isloading = true;
      this.$store.dispatch("authority/fetchAuthorities").then(() => {
        that.mainAuthorities = this.$store.getters["authority/getAuthorities"];
        that.isloading = false;
        that.dialogObject.options = _.clone(that.mainAuthorities);
      });
    },
    fetchIncidentTypes() {
      var that = this;
      that.tableObject.isloading = true;
      that.tableObject.records = null;
      this.$store.dispatch("authority/fetchAuthorities").then(() => {
        that.mainAuthorities = this.$store.getters["authority/getAuthorities"];
        this.$store
          .dispatch("incidentType/fetchincidentTypes")
          .then(() => {
            that.response =
              this.$store.getters["incidentType/getIncidentTypes"];
            that.tableObject.records = _.clone(that.response);
            _.each(that.response, (element) => {
              _.each(that.mainAuthorities, (melement) => {
                if (melement.slug == element.authority) {
                  element.fieldTitle = melement.name;
                }
              });
            });
            that.tableObject.isloading = false;
          })
          .catch((error) => {
            console.log(error);
          });
        that.dialogObject.options = _.clone(that.mainAuthorities);
      });
    },
    syncIncidentTypes() {
      var that = this;
      that.tableObject.isloading = true;
      that.tableObject.records = null;
      this.$store
        .dispatch("incidentType/syncincidentTypes")
        .then(() => {
          this.fetchIncidentTypes();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleConfirmationDialog() {
      this.flages.confirmdisplayDialog = !this.flages.confirmdisplayDialog;
    },
    toggleDialog() {
      this.flages.displayDialog = !this.flages.displayDialog;
    },
    incidenTypesActions(incidentType, type) {
      let that = this;
      incidentType.attachmentsemail = incidentType.notificationEmail
        ? incidentType.notificationEmail.join(",")
        : incidentType.notificationEmail;
      let selectedIncidentType = _.clone(incidentType);
      that.dialogObject.record = _.clone(selectedIncidentType);
      that.dialogObject.actionType = type;
      that.invokeIncidentType(type, incidentType, "");
    },
    activateDeactivateIncidentType(data) {
      let that = this;
      let incidentType = _.clone(data.record);
      incidentType.isActive = !incidentType.isActive;
      that.updateIncidentType(incidentType);
      that.toggleConfirmationDialog();
    },
    upsertIncidentType(data, records) {
      let that = this;
      let incidentType = _.clone(data);
      let record = incidentType.record;
      let incidentTypes = _.clone(records);
      let actionType = _.clone(incidentType.actionType);
      record.authority = record.fieldTitle.slug
        ? record.fieldTitle.slug
        : record.authority;
      record.notificationEmail = record.attachmentsemail
        ? record.attachmentsemail.split(",")
        : null;
      let type =
        actionType == "Add" ? "saveincidenttype" : "updateincidenttype";
      if (record.slug != "") {
        that.invokeIncidentType(type, incidentType.record, incidentTypes);
      }
    },
    invokeIncidentType(type, incidentType, incidentTypes) {
      let that = this;
      switch (type) {
        case "ActivateDeactive":
          that.toggleConfirmationDialog();
          break;
        case "updateincidenttype":
          that.updateIncidentType(incidentType);
          console.log(incidentTypes);
          break;
        default:
          that.toggleDialog();
          break;
      }
    },
    updateIncidentType(incidentType) {
      let that = this;
      this.$store
        .dispatch("incidentType/updateIncidentType", incidentType)
        .then(() => {
          that.toggleDialog();
          that.fetchIncidentTypes();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  props: {},
  watch: {},
  data() {
    return {
      response: null,
      mainAuthorities: null,
      tableObject: {
        header: "IncidentTypes With Sowlution Application",
        columns: [
          { field: "slug", header: "Slug" },
          { field: "title", header: "Name" },
          { field: "fieldTitle", header: "Authority" },
          { field: "notificationEmail", header: "Notification Email" },
          { field: "isActive", header: "Status" },
          { field: "actions", header: "Actions" },
        ],
        records: null,
        isexpandable: false,
        isloading: true,
        issync: true,
        isedit: true,
        component: "incidenttype",
      },
      flages: {
        displayDialog: false,
        confirmdisplayDialog: false,
      },
      dialogObject: {
        record: null,
        actionType: null,
        component: "IncidentType",
        isdrop: true,
      },
    };
  },
};
</script>

<style scoped>
</style>