/* eslint-disable no-unused-vars */

import {
    getcustomfields,
    getmastertemplates,
    postmastertemplates,
    putmastertemplates,
    downloadmastertemplates
} from '../../services/MasterTemplates.Service';

const state = {
    mastertemplates: {},
    customfields: {},
    downloadtemplate:{}
}
const getters = {
    getMasterTemplates(state) {
        return state.mastertemplates;
    },
    getCustomFields(state) {
        return state.customfields;
    },
    getDownloadMasterTemplates(){
        return state.downloadtemplate;
    }
}
const actions = {
    fetchMasterTemplates({ commit }) {
        return new Promise((resolve, reject) => {
            getmastertemplates().then(response => {
                if (response.status == 200) {
                    commit('setmastertemplates', response.data.data);
                    resolve();
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    fetchCustomFields({ commit }) {
        return new Promise((resolve, reject) => {
            getcustomfields().then(response => {
                if (response.status == 200) {
                    commit('setcustomfields', response.data.data);
                    resolve();
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    saveMasterTemplates({ commit }, template) {
        return new Promise((resolve, reject) => {
            postmastertemplates(template).then(response => {
                if (response.status== 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error)
            });
        });
    },
    updateMasterTemplate({ commit }, template) {
        return new Promise((resolve, reject) => {
            putmastertemplates(template).then(response => {
                if (response.status== 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error)
            });
        });
    },
    downloadMasterTemplate({ commit },fielinfo) {
        return new Promise((resolve, reject) => {
            downloadmastertemplates(fielinfo).then(response => {
                if (response.status == 200) {
                    commit('setdownloadtemplate', response);
                    resolve();
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
}
const mutations = {
    setmastertemplates(state, response) {
        state.mastertemplates = response;
    },
    setcustomfields(state, response) {
        state.customfields = response;
    },
    setdownloadtemplate(state, response) {
        state.downloadtemplate = response;
    }
}
export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}