<template>
  <div id="componentTemplateConfigs" style="height: calc(100vh - 165px)">
    <DataTable
      :scrollable="true"
      scrollHeight="flex"
      :loading="isloading"
      :filters="filters"
      sortMode="multiple"
      removableSort
      :value="mainTemplates"
      :paginator="true"
      :rows="20"
      :rowsPerPageOptions="[20, 40, 60, 80]"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      data-key="id"
      class="p-datatable-gridlines"
      :expandedRows.sync="expandedRows"
    >
      <template #header>
        <div class="p-grid">
          <div class="p-col-3 p-text-center mt-2 p-text-left">
            <span class=""><b>Template Configuration</b></span>
          </div>
          <div class="p-col-6 text-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                style="width: 567px !important"
                v-model="filters['global']"
                placeholder="Global Search"
              />
            </span>
          </div>
          <div class="p-col-2">
            <Button
              icon="pi pi-plus"
              label="Add New"
              v-tooltip.top="'Add New'"
              class="p-button-raised p-button-info p-mr-3"
              @click="openNew()"
            />
          </div>
        </div>
      </template>
      <template #empty> No records found. </template>
      <Column
        field="title"
        header="Title"
        :sortable="true"
        :headerClass="'thheader'"
      ></Column>
      <Column
        field="subOfferingType"
        header="SubOfferingType"
        :headerClass="'thheader'"
        sortable
      >
        <template #body="slotProps">{{
          formatSuboffering(slotProps.data.subOfferingType)
        }}</template>
      </Column>
      <Column
        field="lawfirm"
        header="Lawfirm"
        :headerClass="'thheader'"
        sortable
      >
        <template #body="slotProps">{{
          formatLawfirms(slotProps.data.lawfirm)
        }}</template>
      </Column>
      <Column
        field="masterTemplate"
        :bodyClass="'configtitle'"
        :headerClass="'configtitle thheader'"
        header="MasterTemplate"
        sortable
      >
        <template #body="slotProps">{{
          formatMastertemplate(slotProps.data.masterTemplate)
        }}</template>
      </Column>
      <Column
        field="masterServiceConfig"
        header="MasterServiceConfig"
        :headerClass="'thheader'"
        sortable
      >
        <template #body="slotProps">{{
          formatMasterServiceConfig(slotProps.data.masterServiceConfig)
        }}</template>
      </Column>
      <Column :headerClass="'thheader'" header="Actions">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            v-tooltip.top="'Edit Template'"
            @click="editTemplate(slotProps.data)"
            class="p-button-raised p-button-info p-mr-2"
          />
          <Button
            icon="pi pi-trash"
            v-tooltip.top="'Deactivate Template'"
            v-if="slotProps.data.isActive"
            @click="handleactivateDeactivateTemplate(slotProps.data)"
            class="p-button-raised p-button-warning p-mr-2"
          />
          <Button
            v-if="!slotProps.data.isActive"
            icon="pi pi-check-circle"
            v-tooltip.top="'Activate Template'"
            @click="handleactivateDeactivateTemplate(slotProps.data)"
            class="p-button-raised p-button-success p-mr-2"
          />
        </template>
      </Column>
    </DataTable>
    <Dialog
      maximizable
      :visible.sync="templateDialog"
      :style="{ width: '70vw' }"
      :header="editTemplateMode ? 'Edit Template' : 'Add Template'"
      :modal="true"
      class="p-fluid"
    >
      <div>
        <br />
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="title"
              v-model.trim="template.title"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !template.title }"
            />
            <label for="name">Title<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !template.title"
            >Name is required.</span
          >
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="title"
              v-model.trim="template.description"
              autofocus
            />
            <label for="name">Description</label>
          </span>
        </div>
        <div class="p-field p-col" v-if="addTemplateMode">
          <span class="p-float-label">
            <InputText
              id="slug"
              v-model.trim="template.slug"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !template.slug }"
            />
            <label for="name">Slug<span class="p-invalid">*</span></label>
          </span>
          <small class="error-message" v-if="submitted && !template.slug"
            >Slug is required.</small
          >
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <Dropdown
              appendTo="body"
              v-model.trim="template.masterTemplate"
              :options="masterTemplates"
              optionLabel="title"
              :filter="true"
              placeholder=""
              :class="{ 'p-invalid': submitted && !template.masterTemplate }"
            >
              <template #value="slotProps">
                <div class="p-dropdown-car-value" v-if="slotProps.value">
                  <span
                    >{{
                      slotProps.value.title
                        ? slotProps.value.title
                        : slotProps.value
                    }}
                  </span>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span>{{ slotProps.option.title }}</span>
                </div>
              </template>
            </Dropdown>
            <label for="name">Master Template</label>
          </span>
          <span
            class="error-message"
            v-if="submitted && !template.masterTemplate"
            >MasterTemplate is required.</span
          >
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <Dropdown
              appendTo="body"
              v-model.trim="template.masterServiceConfig"
              :options="masterServiceConfigs"
              optionLabel="title"
              :filter="true"
              placeholder=""
              :class="{
                'p-invalid': submitted && !template.masterServiceConfig,
              }"
            >
              <template #value="slotProps">
                <div class="p-dropdown-car-value" v-if="slotProps.value">
                  <span
                    >{{
                      slotProps.value.title
                        ? slotProps.value.title
                        : slotProps.value
                    }}
                  </span>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span>{{ slotProps.option.title }}</span>
                </div>
              </template>
            </Dropdown>
            <label for="name">Master Service Config</label>
          </span>
          <span
            class="error-message"
            v-if="submitted && !template.masterServiceConfig"
            >Master Service Config is required.</span
          >
        </div>
        <div class="p-field p-col" v-if="addTemplateMode">
          <span class="p-float-label">
            <Dropdown
              appendTo="body"
              v-model.trim="template.lawfirm"
              :options="lawfirms"
              optionLabel="title"
              :filter="true"
              :class="{ 'p-invalid': submitted && !template.lawfirm }"
            >
              <template #value="slotProps">
                <div class="p-dropdown-car-value" v-if="slotProps.value">
                  <span
                    >{{
                      slotProps.value.title
                        ? slotProps.value.title
                        : slotProps.value
                    }}
                  </span>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span>{{ slotProps.option.title }}</span>
                </div>
              </template>
            </Dropdown>
            <label for="name">Lawfirm</label>
          </span>
          <span class="error-message" v-if="submitted && !template.lawfirm"
            >Lawfirm is required.</span
          >
        </div>
        <div class="p-field p-col" v-if="addTemplateMode">
          <span class="p-float-label">
            <Dropdown
              appendTo="body"
              v-model.trim="template.subOfferingType"
              :options="subOfferingTypes"
              optionLabel="title"
              :filter="true"
              :class="{ 'p-invalid': submitted && !template.subOfferingType }"
            >
              <template #value="slotProps">
                <div class="p-dropdown-car-value" v-if="slotProps.value">
                  <span
                    >{{
                      slotProps.value.title
                        ? slotProps.value.title
                        : slotProps.value
                    }}
                  </span>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span>{{ slotProps.option.title }}</span>
                </div>
              </template>
            </Dropdown>
            <label for="name">SubOfferingType</label>
          </span>
          <span
            class="error-message"
            v-if="submitted && !template.subOfferingType"
            >SubOfferingType is required.</span
          >
        </div>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-warning"
          @click="hideDialog"
        />
        <Button label="Save" icon="pi pi-arrow-circle-up" @click="handleSave" />
      </template>
    </Dialog>
    <Dialog
      maximizable
      :visible.sync="confirmationDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span
          >Are you sure you want to
          {{ template.isActive ? "delete" : "activate" }}
          <b>{{ template.title }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideconDialog"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="activateDeactivateTemplate(template)"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "templateConfigsComponent",
  mounted(){
    this.fetchLawfirms();
    this.fetchSubOfferingTypes();
    this.fetchMasterTemplates();
    this.fetchMasterServiceConfigs();
  },
  created() {
    this.fetchTemplateConfigurations();
  },
  components: {},
  methods: {
    handleSlug() {
      let selectedService = this.template;
      let type = _.filter(
        this.mainTemplates,
        (mr) => mr.masterTemplate === selectedService.masterTemplate.slug
      );
      let trm = type[0].title
        .trim()
        .toLowerCase()
        .replace(/[^\w\s]|\d/gi, "")
        .replace(/\s+/g, " ");
      let words = trm.split(" ");
      let result = "";
      for (let i = 0; i < words.length; i++) {
        let word = words[i];
        result += "-" + word.substring(0, 3);
      }
      this.template.slug = "tcfg" + result;
    },
    onRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Master Template Expanded",
        detail: event.data.title,
        life: 3000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Master Template Collapsed",
        detail: event.data.title,
        life: 3000,
      });
    },
    fetchTemplateConfigurations() {
      var that = this;
      this.$store
        .dispatch("templateConfiguration/fetchTemplateConfigurations")
        .then(() => {
          that.tempresponse =
            this.$store.getters[
              "templateConfiguration/getTemplateConfigurations"
            ];
          that.mainTemplates = _.clone(that.tempresponse);
        });
    },
    fetchLawfirms() {
      this.$store.dispatch("lawfirm/fetchlawfirms").then(() => {
        let response = this.$store.getters["lawfirm/getlawfirms"];
        this.lawfirms = _.clone(response);
      });
    },
    fetchSubOfferingTypes() {
      this.$store.dispatch("subofferingType/fetchSubofferingTypes").then(() => {
        let response =
          this.$store.getters["subofferingType/getSubofferingTypes"];
        this.subOfferingTypes = _.clone(response);
      });
    },
    fetchMasterTemplates() {
      this.isloading = true;
      this.$store.dispatch("masterTemplate/fetchMasterTemplates").then(() => {
        let response =
          this.$store.getters["masterTemplate/getMasterTemplates"];
        this.masterTemplates = _.clone(response);
        this.isloading = false;
      });
    },
    fetchMasterServiceConfigs() {
      this.$store
        .dispatch("masterserviceconfig/fetchMasterServiceConfigs")
        .then(() => {
          let response =
            this.$store.getters["masterserviceconfig/getMasterServiceConfigs"];
          this.masterServiceConfigs = _.clone(response);
        });
    },
    formatMastertemplate(value) {
      let that = this;
      let type = _.filter(that.masterTemplates, (mr) => mr.slug === value);
      return (value = type.length ? type[0].title : value);
    },
    formatLawfirms(value) {
      let that = this;
      let type = _.filter(that.lawfirms, (mr) => mr.slug === value);
      return (value = type.length ? type[0].title : value);
    },
    formatSuboffering(value) {
      let that = this;
      let type = _.filter(that.subOfferingTypes, (mr) => mr.slug === value);
      return (value = type.length ? type[0].title : value);
    },
    formatMasterServiceConfig(value) {
      let that = this;
      let type = _.filter(that.masterServiceConfigs, (mr) => mr.slug === value);
      return (value = type.length ? type[0].title : value);
    },
    openNew() {
      this.template = {};
      console.log(this.lawfirms);
      this.submitted = false;
      this.editTemplateMode = false;
      this.addTemplateMode = true;
      this.templateDialog = true;
    },
    editTemplate(template) {
      console.log(template);
      this.editTemplateMode = true;
      this.addTemplateMode = false;
      template.masterTemplate = this.formatMastertemplate(
        template.masterTemplate
      );
      template.masterServiceConfig = this.formatMasterServiceConfig(
        template.masterServiceConfig
      );
      this.template = _.clone(template);
      this.templateDialog = true;
    },
    hideDialog() {
      let that = this;
      this.templateDialog = false;
      this.editTemplateMode = true;
      this.addTemplateMode = false;
      that.template = {};
    },
    handleactivateDeactivateTemplate(template) {
      let that = this;
      that.template = template;
      this.confirmationDialog = true;
    },
    activateDeactivateTemplate(template) {
      let that = this;
      that.template.isActive = !template.isActive;
      this.handleSave();
      this.confirmationDialog = false;
    },
    hideconDialog() {
      let that = this;
      that.confirmationDialog = false;
    },
    handleSave() {
      let that = this;
      this.submitted = true;
      let template = that.template;
      if (
        that.template.lawfirm &&
        that.template.masterTemplate &&
        that.template.subOfferingType &&
        that.template.title &&
        that.template.slug &&
        that.template.masterServiceConfig
      ) {
        that.template.lawfirm = that.template.lawfirm.slug
        ? that.template.lawfirm.slug
        : that.template.lawfirm;
      that.template.masterTemplate = that.template.masterTemplate.slug
        ? that.template.masterTemplate.slug
        : _.filter(
            that.masterTemplates,
            (mr) => mr.title === that.template.masterTemplate
          )[0].slug;
      that.template.masterServiceConfig = that.template.masterServiceConfig.slug
        ? that.template.masterServiceConfig.slug
        : _.filter(
            that.masterServiceConfigs,
            (mr) => mr.title === that.template.masterServiceConfig
          )[0].slug;
      that.template.subOfferingType = that.template.subOfferingType.slug
        ? that.template.subOfferingType.slug
        : that.template.subOfferingType;
        let x = this.addTemplateMode
          ? "saveTemplateConfiguration"
          : "updateTemplateConfiguration";
        this.$store
          .dispatch("templateConfiguration/" + x, template)
          .then(() => {
            that.fetchTemplateConfigurations();
            that.hideDialog();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  props: {},
  watch: {},
  data() {
    return {
      mainTemplates: null,
      lawfirms: null,
      subOfferingTypes: null,
      masterTemplates: null,
      masterServiceConfigs: null,
      template: {},
      submitted: false,
      addTemplateMode: false,
      editTemplateMode: false,
      templateDialog: false,
      confirmationDialog: false,
      isloading: true,
      expandedRows: [],
      filters: {},
    };
  },
};
</script>

<style scoped>
</style>