<template>
  <div id="componentAddresses" style="height: calc(100vh - 165px)">
    <DataTable
      :scrollable="true"
      scrollHeight="flex"
      :loading="isloading"
      :filters="filters"
      sortMode="multiple"
      removableSort
      :value="mainAddresses"
      :paginator="true"
      :rows="20"
      :rowsPerPageOptions="[20, 40, 60, 80]"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      data-key="id"
      class="p-datatable-gridlines"
      :expandedRows.sync="expandedRows">
          <template #header>
            <div class="p-grid">
              <div class="p-col-3 p-text-center mt-2 p-text-left">
                <span class=""><b>Address and Bank Details Configuration</b></span>
              </div>
              <div class="p-col-6 text-center">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 567px !important"
                    v-model="filters['global']"
                    placeholder="Global Search"
                  />
                </span>
              </div>
              <div class="p-col-2">
                <Button
                  icon="pi pi-plus"
                  label="Add New"
                  v-tooltip.top="'Add New'"
                  class="p-button-raised p-button-info p-mr-3"
                  @click="openNew('details')"
                />
              </div>
            </div>
          </template>
          <template #empty> No records found. </template>
          <Column
            :expander="true"
            headerStyle="width: 3rem"
            :headerClass="'thheader'"
          />
          <Column
            field="title"
            header="Title"
            :sortable="true"
            :headerClass="'thheader'"
          ></Column>
          <Column
            field="country Slug"
            header="Country"
            :sortable="true"
            :headerClass="'thheader'"
          >
          <template #body="slotProps">{{
              formatCountry(slotProps.data.countrySlug)
            }}</template>
        </Column>
          <Column :headerClass="'thheader'" header="Actions">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                v-tooltip.top="'Edit Details'"
                @click="editDetails(slotProps.data)"
                class="p-button-raised p-button-info p-mr-2"
              />
              <Button
                icon="pi pi-trash"
                v-tooltip.top="'Deactivate Details'"
                v-if="slotProps.data.isActive"
                @click="handleactivateDeactivateDetails('details', slotProps.data)"
                class="p-button-raised p-button-warning p-mr-2"
              />
              <Button
                v-if="!slotProps.data.isActive"
                icon="pi pi-check-circle"
                v-tooltip.top="'Activate Details'"
                @click="handleactivateDeactivateDetails('details', slotProps.data)"
                class="p-button-raised p-button-success p-mr-2"
              />
            </template>
          </Column>
          <template #expansion="slotProps">
              <div class="datatable-subtable pb-2 p-m-2">
                    <div class="p-col-12 p-d-flex p-jc-between">
                        <div class="p-text-left nested-header">Addresses</div>
                          <Button
                            icon="pi pi-plus"
                            v-tooltip.top="'Add New Address'"
                            class="p-button-raised nested-addNew p-button-info p-mr-3"
                            @click="openNew('address', slotProps.data)"
                          />
                    </div>
                    <DataTable 
                      :value="slotProps.data.addresses"
                      dataKey="title"
                      sortMode="multiple"
                      removableSort >
                          <Column
                            field="title"
                            header="Title"
                            :sortable="true"
                            :headerClass="'thheader'"
                          ></Column>
                          <Column
                            field="displayName"
                            header="Display Name"
                            :sortable="true"
                            :headerClass="'thheader'"
                          >
                        </Column>
                          <Column
                            field="streetAddress"
                            header="Street Address"
                            :sortable="true"
                            :headerClass="'thheader'"
                          ></Column>
                          <Column
                            field="city"
                            header="City"
                            :sortable="true"
                            :headerClass="'thheader'"
                          ></Column>
                          <Column
                            field="state"
                            header="State"
                            :sortable="true"
                            :headerClass="'thheader'"
                          ></Column>
                          <Column
                            field="zipCode"
                            header="Zip Code"
                            :sortable="true"
                            :headerClass="'thheader'"
                          ></Column>
                          <Column v-if="slotProps.data.countrySlug == 'ctry-ca-le'"
                            field="lockBoxNo"
                            header="Lock Box No"
                            :sortable="true"
                            :headerClass="'thheader'"
                          ></Column>
                          <Column v-if="slotProps.data.countrySlug == 'ctry-us-le'"
                            field="lockBoxCode"
                            header="Lock Box Code"
                            :sortable="true"
                            :headerClass="'thheader'"
                          ></Column>
                          <Column v-if="slotProps.data.countrySlug == 'ctry-us-le'"
                            field="lockBoxName"
                            header="Lock Box Name"
                            :sortable="true"
                            :headerClass="'thheader'"
                          ></Column>
                          <Column :headerClass="'thheader'" header="Actions">
                            <template #body="columData">
                              <Button
                                icon="pi pi-pencil"
                                v-tooltip.top="'Edit Address'"
                                @click="editAddress(columData.data, slotProps.data)" 
                                class="p-button-raised p-button-info p-mr-2"
                              />
                              <Button
                                icon="pi pi-trash"
                                v-tooltip.top="'Deactivate Address'"
                                v-if="columData.data.isActiveAddress"
                                @click="handleactivateDeactivateDetails('address', slotProps.data, columData.data)"
                                class="p-button-raised p-button-warning p-mr-2"
                              />
                              <Button
                                v-if="!columData.data.isActiveAddress"
                                icon="pi pi-check-circle"
                                v-tooltip.top="'Activate Address'"
                                @click="handleactivateDeactivateDetails('address', slotProps.data, columData.data)"
                                class="p-button-raised p-button-success p-mr-2"
                              />
                            </template>
                          </Column>
                    </DataTable>
                    <div class="p-col-12 p-d-flex p-jc-between">
                        <div class="p-text-left nested-header">Bank Details</div>
                          <Button
                            icon="pi pi-plus"
                            v-tooltip.top="'Add New Bank'"
                            class="p-button-raised nested-addNew p-button-info p-mr-3"
                            @click="openNew('bankDetails', slotProps.data)"
                          />
                    </div>
                  <DataTable 
                      :value="slotProps.data.bankDetails"
                      dataKey="title"
                      sortMode="multiple"
                      removableSort >
                      <Column
                        field="bankName"
                        header="Bank Name"
                        :sortable="true"
                        :headerClass="'thheader'"
                      ></Column>
                      <Column v-if="slotProps.data.countrySlug == 'ctry-in-le'"
                        field="branchName"
                        header="Branch Name"
                        :sortable="true"
                        :headerClass="'thheader'"
                      ></Column>
                      <Column
                        field="accountNo"
                        header="Account No"
                        :sortable="true"
                        :headerClass="'thheader'"
                      ></Column>
                      <Column v-if="slotProps.data.countrySlug == 'ctry-us-le'"
                        field="routingNo"
                        header="Routing No"
                        :sortable="true"
                        :headerClass="'thheader'"
                      ></Column>
                      <Column v-if="slotProps.data.countrySlug == 'ctry-ca-le'"
                        field="institutionNo"
                        header="Institution/Transit number"
                        :sortable="true"
                        :headerClass="'thheader'"
                      ></Column>
                      <Column v-if="slotProps.data.countrySlug == 'ctry-in-le'"
                        field="ifscCode"
                        header="IFSC Code"
                        :sortable="true"
                        :headerClass="'thheader'"
                      ></Column>
                      <Column v-if="slotProps.data.countrySlug == 'ctry-in-le' ||slotProps.data.countrySlug == 'ctry-us-le'"
                        field="swiftCode"
                        header="Swift Code"
                        :sortable="true"
                        :headerClass="'thheader'"
                      ></Column>
                      <Column :headerClass="'thheader'" header="Actions">
                        <template #body="columData">
                          <Button
                            icon="pi pi-pencil"
                            v-tooltip.top="'Edit Bank'"
                            @click="editBankDetails(columData.data ,slotProps.data)"
                            class="p-button-raised p-button-info p-mr-2"
                          />
                          <Button
                            icon="pi pi-trash"
                            v-tooltip.top="'Deactivate Bank'"
                            v-if="columData.data.isActiveBank"
                            @click="handleactivateDeactivateDetails('bankDetails', slotProps.data, columData.data)"
                            class="p-button-raised p-button-warning p-mr-2"
                          />
                          <Button
                            v-if="!columData.data.isActiveBank"
                            icon="pi pi-check-circle"
                            v-tooltip.top="'Activate Bank'"
                            @click="handleactivateDeactivateDetails('bankDetails', slotProps.data, columData.data)"
                            class="p-button-raised p-button-success p-mr-2"
                          />
                        </template>
                      </Column>
                    </DataTable>
              </div>
            </template>
    </DataTable>
    <Dialog
      maximizable
      :visible.sync="detailsDialog"
      :style="{ width: '40vw' }"
      :header="addDetailsMode ? 'Add Details' : 'Edit Details'"
      :modal="true"
      class="p-fluid"
    >
      <div>
        <br />
          <div class="p-field p-col">
            <span class="p-float-label">
              <InputText
                id="Title"
                v-model.trim="records.title"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !records.title }"
              />
              <label for="name">Title<span class="p-invalid">*</span></label>
            </span>
            <span class="error-message" v-if="submitted && !records.title"
              >Title is required.</span
            >
          </div>
          <div class="p-field p-col">
            <span class="p-float-label">
              <Dropdown
                appendTo="body"
                v-model="records.countrySlug"
                :class="{ 'p-invalid': submitted && !records.countrySlug }"
                :options="countries"
                :filter="true"
                optionLabel="title"
              >
              <template #value="slotProps">
                  <div class="" v-if="slotProps.value">
                    {{
                      formatCountry(slotProps.value.title
                        ? slotProps.value.title
                        : slotProps.value)
                    }}
                  </div>
                  <div class="p-field" v-else>
                    {{ slotProps.placeholder }}
                  </div>
                </template>
              </Dropdown>
              <label for="countries">Country<span class="p-invalid">*</span></label>
            </span>
            <span
              :class="{ 'p-invalid': !records.countrySlug }"
              v-if="submitted && !records.countrySlug"
              >Country is required.</span
            >
          </div>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-warning"
          @click="hideDialog('details')"
        />
        <Button label="Save" icon="pi pi-arrow-circle-up" @click="saveDetails" />
      </template>
    </Dialog>
    <Dialog
      maximizable
      :visible.sync="nestedAddressDialog"
      :style="{ width: '70vw' }"
      :header="addAddressMode ? 'Add Address' : 'Edit Address'"
      :modal="true"
      class="p-fluid"
    >
      <div>
        <br />
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="Title"
              v-model.trim="address.title"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !address.title }"
            />
            <label for="name">Title<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !address.title"
            >Title is required.</span
          >
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="Title"
              v-model.trim="address.displayName"
              required="true"
              autofocus
            />
            <label for="name">Display Name</label>
          </span>
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="StreetAddress"
              v-model.trim="address.streetAddress"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !address.streetAddress }"
            />
            <label for="name">Street Address<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !address.streetAddress"
            >Street Address is required.</span
          >
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="City"
              v-model.trim="address.city"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !address.city }"
            />
            <label for="name">City<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !address.city"
            >City is required.</span
          >
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="State"
              v-model.trim="address.state"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !address.state }"
            />
            <label for="name">State<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !address.state"
            >State is required.</span
          >
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="zipCode"
              v-model.trim="address.zipCode"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !address.zipCode }"
            />
            <label for="name">Zip Code<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !address.zipCode"
            >Zip Code is required.</span
          >
        </div>
        <div class="p-field p-col" v-if="countrySlug == 'ctry-ca-le'">
          <span class="p-float-label">
            <InputText
              id="LockBoxNo"
              v-model.trim="address.lockBoxNo"
              required="true"
              autofocus
            />
            <label for="name">Lock Box Number</label>
          </span>
        </div>
        <div v-if="countrySlug == 'ctry-us-le'">
          <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="LockBoxCode"
              v-model.trim="address.lockBoxCode"
              required="true"
              autofocus
            />
            <label for="name">Lock Box Code</label>
          </span>
        </div>
        <div class="p-field p-col" v-if="countrySlug == 'ctry-us-le'">
          <span class="p-float-label">
            <InputText
              id="LockBoxName"
              v-model.trim="address.lockBoxName"
              required="true"
              autofocus
            />
            <label for="name">Lock Box Name</label>
          </span>
        </div>
        </div>
      <div class="p-d-flex">
            <div class="p-field p-col-4">
            <span class="toggle-container">
              <input type="radio" name="addressType" :checked="address.isWorkAddress" class="toggle-switch" id="isWorkAddress" @click="togglebtn('work')">
              <label for="addressToggle" class="toggle-label">Is this a Work Address</label>
            </span>
          </div>
          <div class="p-field p-col-4">
            <span class="toggle-container">
              <input type="radio" name="addressType" :checked="address.isDropAddress" class="toggle-switch" id="isDropAddress" @click="togglebtn('drop')">
              <label for="addressToggle" class="toggle-label">Is this a Drop Address</label>
            </span>
          </div>
          <div class="p-field p-col-4">
            <span class="toggle-container">
              <input type="radio" name="addressType" :checked="address.isPostAddress" class="toggle-switch" id="isPostAddress" @click="togglebtn('post')">
              <label for="addressToggle" class="toggle-label">Is this a Post Address</label>
            </span>
          </div>
        </div>
        
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-warning"
          @click="hideDialog('address')"
        />
        <Button label="Save" icon="pi pi-arrow-circle-up" @click="saveAddress" />
      </template>
    </Dialog>
    <Dialog
      maximizable
      :visible.sync="nestedBankDialog"
      :style="{ width: '70vw' }"
      :header="addBankMode ? 'Add Bank Details': 'Edit Bank Details' "
      :modal="true"
      class="p-fluid"
    >
      <div>
        <br />
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="Title"
              v-model.trim="bank.bankName"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !bank.bankName }"
            />
            <label for="name">Bank Name<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !bank.bankName"
            >Bank name is required.</span
          >
        </div>
        <div class="p-field p-col" v-if="countrySlug == 'ctry-in-le'">
          <span class="p-float-label">
            <InputText
              id="Title"
              v-model.trim="bank.branchName"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !bank.branchName }"
            />
            <label for="name">Branch Name<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !bank.branchName"
            >branch Name is required.</span
          >
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="StreetAddress"
              v-model.trim="bank.accountNo"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !bank.accountNo }"
            />
            <label for="name">Account Number<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !bank.accountNo"
            >Account Number is required.</span
          >
        </div>
        <div class="p-field p-col" v-if="countrySlug == 'ctry-us-le'">
          <span class="p-float-label">
            <InputText
              id="City"
              v-model.trim="bank.routingNo"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !bank.routingNo }"
            />
            <label for="name">Routing Number<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !bank.routingNo"
            >Routing Number is required.</span
          >
        </div>
        <div class="p-field p-col" v-if="countrySlug == 'ctry-ca-le'">
          <span class="p-float-label">
            <InputText
              id="Title"
              v-model.trim="bank.institutionNo"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !bank.institutionNo }"
            />
            <label for="name">Institution/Transit number<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !bank.institutionNo"
            >Institution/Transit number is required.</span
          >
        </div>
        <div class="p-field p-col" v-if="countrySlug == 'ctry-in-le'">
          <span class="p-float-label">
            <InputText
              id="Title"
              v-model.trim="bank.ifscCode"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !bank.ifscCode }"
            />
            <label for="name">IFSC Code<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !bank.ifscCode"
            >IFSC Code is required.</span
          >
        </div>
        <div class="p-field p-col" v-if="countrySlug == 'ctry-in-le' || countrySlug == 'ctry-us-le'">
          <span class="p-float-label">
            <InputText
              id="Title"
              v-model.trim="bank.swiftCode"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !bank.swiftCode }"
            />
            <label for="name">Swift Code<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !bank.swiftCode"
            >Swift Code is required.</span
          >
        </div>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-warning"
          @click="hideDialog('bankDetails')"
        />
        <Button label="Save" icon="pi pi-arrow-circle-up" @click="saveBankDetails" />
      </template>
    </Dialog>

    <Dialog
      maximizable
      :visible.sync="confirmationDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="conFlag == 'details'"
          >Are you sure you want to
          {{ records.isActive ? "delete" : "activate" }}
          <b>{{ records.title }}</b
          >?</span>
          <span v-else-if=" conFlag == 'address'"
          >Are you sure you want to
          {{ address.isActiveAddress ? "delete" : "activate" }}
          <b>{{ address.title }}</b
          >?</span>
          <span v-else
          >Are you sure you want to
          {{ bank.isActiveBank ? "delete" : "activate" }}
          <b>{{ bank.bankName }}</b
          >?</span>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog('confirmation')"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="activateDeactivateDetails"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "addressComponent",
  created() {
    this.fetchCountries();
    this.fetchAddresses();
  },
  data() {
    return {
      mainAddresses: null,
      countries: {},
      address: {},
      bank: {},
      records: {},
      countrySlug: null,
      submitted: false,
      addDetailsMode: false,
      addAddressMode: false,
      addBankMode: false,
      detailsDialog: false,
      nestedAddressDialog: false,
      nestedBankDialog: false,
      confirmationDialog: false,
      isloading: true,
      conFlag: null,
      expandedRows: [],
      filters: {},
    };
  },
  components: {},
  methods: {
    fetchAddresses() {
      var that = this;
      that.isloading = true;
      this.$store.dispatch("addressandbankdetails/fetchDetails")
      .then(() => {
        that.tempresponse = this.$store.getters["addressandbankdetails/getDetail"];
        that.mainAddresses = _.clone(that.tempresponse);
        that.isloading = false;
      });
    },
    fetchCountries() {
      let that = this;
      this.$store.dispatch("country/fetchCountries").then(() => {
        var countryResponse = this.$store.getters["country/getCountries"];
        that.countries = _.clone(countryResponse); 
      });
    },
    openNew(prop, objData) {
      switch (prop) {
        case 'details': {
          this.records = {
            addresses: [],
            bankDetails: []
          };
          this.submitted = false;
          this.addDetailsMode = true;
          this.detailsDialog = true;
          }
              break;
        case 'address': {
          this.address = {};
          this.records = _.cloneDeep(objData); 
          this.submitted = false;
          this.addAddressMode= true;
          this.nestedAddressDialog = true;
          this.countrySlug = objData.countrySlug;
        }
        break;
        case 'bankDetails': {
          this.bank = {};
          this.records = _.cloneDeep(objData);
          this.submitted = false;
          this.addBankMode = true;
          this.nestedBankDialog = true;
          this.countrySlug = objData.countrySlug;
        }
      }
    },
    editDetails(details) {
      this.addDetailsMode = false; // removable
      this.records = _.clone(details);
      this.detailsDialog = true;
    },
    editAddress(address, tableObject) {
      this.addAddressMode = false;
      this.address = _.clone(address);
      this.records = _.cloneDeep(tableObject);
      this.nestedAddressDialog = true;
      this.countrySlug = tableObject.countrySlug;
    },
    editBankDetails(details, tableObject) {
      this.addBankMode = false;
      this.bank = _.clone(details);
      this.records = _.cloneDeep(tableObject); 
      this.nestedBankDialog = true;
      this.countrySlug = tableObject.countrySlug;
    },
    hideDialog(prop) {
      let that = this;
      switch (prop) {
        case 'details' : {
          this.detailsDialog = false;
          this.addDetailsMode = false;
          that.records = {};
        }
        break;
        case 'address' : {
          this.nestedAddressDialog = false;
          this.records = {};
          this.addAddressMode = false;
          that.address = {};
          that.countrySlug = '';
        }
        break;
        case 'bankDetails' : {
          this.nestedBankDialog = false;
          this.addBankMode = false;
          that.records = {};
          that.bank = {};
          that.countrySlug = '';
        }
        break;
        case 'confirmation' : {
          that.records = {};
          that.address = {};
          that.bank = {};
          that.confirmationDialog = false;
        }
        break;
        default: break;
      }
    },
    handleactivateDeactivateDetails(acttype, tableObject, details) {
      let that = this;
      that.conFlag = acttype;
      that.records = _.cloneDeep(tableObject);
      if(that.conFlag === 'address') {
        that.address = _.clone(details);
      } else if(that.conFlag === 'bankDetails') {
        that.bank = _.clone(details);
      }
      this.confirmationDialog = true;
    },
    activateDeactivateDetails() {
      let that = this;
      switch(that.conFlag){
        case 'details': 
          that.records.isActive = !that.records.isActive;
          that.saveDetails();
          break;
        case 'address':
          that.address.isActiveAddress = !that.address.isActiveAddress;
          that.saveAddress();
          break;
        case 'bankDetails':
          that.bank.isActiveBank = !that.bank.isActiveBank;
          that.saveBankDetails();
          break;
        default: break;
      }
      this.confirmationDialog = false;
    },
    saveDetails() {
      let that = this;
      this.submitted = true;
      that.records.countrySlug = that.records.countrySlug.slug ? that.records.countrySlug.slug : that.records.countrySlug;
      if (that.records.title && that.records.countrySlug) {
        let acttype = this.addDetailsMode ?  "saveDetails": "updateDetails";
        console.log(this.records);
        this.$store
          .dispatch("addressandbankdetails/" + acttype, that.records)
          .then(() => {
            that.fetchAddresses();
            that.hideDialog('details');
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    saveAddress() {
      let that = this;
      this.submitted = true;
      if(this.addAddressMode) {
        var addressRecords =  (that.records.addresses);
        addressRecords.push(that.address);
      } else {
        var addressToSave = that.records.addresses.find(obj => obj.slug === that.address.slug);
        if(addressToSave) {
          Object.assign(addressToSave, that.address)
        }
      }
      if (that.address.title &&
          that.address.streetAddress &&
          that.address.city &&
          that.address.state &&
          that.address.zipCode) {
            this.$store
          .dispatch("addressandbankdetails/updateDetails", that.records)
          .then(() => {
            that.fetchAddresses();
            that.hideDialog('address');
          })
          .catch((error) => {
            console.log(error);
          });
          }
    },
    saveBankDetails() {
      let that = this;
      this.submitted = true;
      if(this.addBankMode) {
        var bankRecords = that.records.bankDetails;
        bankRecords.push(that.bank);
      } else {
        var bankToSave = that.records.bankDetails.find(obj => obj.slug === that.bank.slug);
        if(bankToSave) {
          Object.assign(bankToSave, that.bank)
        }
      }
      if (that.bank.bankName &&
          that.bank.accountNo) {
            this.$store
          .dispatch("addressandbankdetails/updateDetails", that.records)
          .then(() => {
            that.fetchAddresses();
            that.hideDialog('bankDetails');
          })
          .catch((error) => {
            console.log(error);
          });
          }
    },
    formatCountry(customval) {
      let that = this;
      let ctry = _.filter(that.countries, (mr) => mr.slug === customval);
      return (customval = ctry.length ? ctry[0].title : customval);
    },
    togglebtn(type)
    {
      this.address.isPostAddress = type === 'post' ? true : false;
      this.address.isWorkAddress = type === 'work' ? true : false;
      this.address.isDropAddress = type === 'drop' ? true : false;
    }
  },
  props: {},
  watch: {},
};

</script>

<style scoped>
.nested-header{
  font-weight: 500;
  padding: 15px;
}
.nested-addNew{
border-radius: 50%;
width: 3rem !important ;
height: 3rem;
}
.toggle-container{
  display: flex;
  align-items: center;
 }
.toggle-switch{
  height: 18px;
  width: 25px;
}
.toggle-label{
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0 !important;
  padding-left: 2px;
}
</style>
