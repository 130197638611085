<template>
  <div id="componentDialog">
    <Dialog
      :header="data.actionType + ' ' + data.component"
      :visible.sync="flages.displayDialog"
      :style="data.actionType == 'Edit' ? { width: '40vw' } : { width: '70vw' }"
      :modal="true"
      :closable="false"
      :maximizable="true"
    >
      <div class="p-grid">
        <div class="p-col-12 p-mt-3">
          <div class="editlawfirm p-fluid" v-if="data.actionType == 'Edit'">
            <div
              class="p-field p-col"
              v-if="
                data.component != 'Lawfirm' && data.component != 'IncidentType'
              "
            >
              <span class="p-float-label">
                <InputText
                  id="username"
                  type="text"
                  v-model="data.record.title"
                  :class="{ 'p-invalid': !data.record.title }"
                />
                <label for="attorneyName">{{ data.component }} Name</label>
              </span>
              <span
                :class="{ 'p-invalid': !data.record.title }"
                v-if="!data.record.title"
                class="error-message"
              >
                {{ data.component }} Name is Required</span
              >
            </div>
            <div class="p-field p-col" v-if="data.component == 'Service'">
              <span class="p-float-label">
                <InputText
                  id="username"
                  type="text"
                  v-model="data.record.order"
                />
                <label for="attorneyName">{{ data.component }} Order</label>
              </span>
            </div>
            <div class="p-field p-col" v-if="data.component == 'Service'">
              <label class="p-mb-3">Is Optional</label>
              <div class="p-formgrid p-grid">
                <div class="p-field-radiobutton p-col-6">
                  <RadioButton
                    name="IsReaonly"
                    :value=true
                    v-model="data.record.isOptional"
                  />
                  <label for="yes">Yes</label>
                </div>
                <div class="p-field-radiobutton p-col-6" v-if="data.component == 'Service'">
                  <RadioButton
                    name="IsReaonly"
                    :value=false
                    v-model="data.record.isOptional"
                  />
                  <label for="No">No</label>
                </div>
              </div>
            </div>
            <div class="p-field p-col" v-if="data.component == 'Service'">
              <label class="p-mb-3">Is Selected</label>
              <div class="p-formgrid p-grid">
                <div class="p-field-radiobutton p-col-6">
                  <RadioButton
                    name="IsSelected"
                    :value=true
                    v-model="data.record.isSelected"
                  />
                  <label for="yes">Yes</label>
                </div>
                <div class="p-field-radiobutton p-col-6">
                  <RadioButton
                    name="IsSelected"
                    :value=false
                    v-model="data.record.isSelected"
                  />
                  <label for="No">No</label>
                </div>
              </div>
            </div>
            <div class="p-field p-col" v-if="data.isdrop">
              <span class="p-float-label">
                <Dropdown
                  appendTo="body"
                  v-model="data.record.fieldTitle"
                  :options="data.options"
                  :filter="true"
                  :optionLabel="
                    data.component == 'IncidentType' ? 'name' : 'title'
                  "
                >
                  <template #value="slotProps">
                    <div class="" v-if="slotProps.value">
                      {{
                        data.component == "IncidentType"
                          ? slotProps.value.name
                            ? slotProps.value.name
                            : slotProps.value
                          : slotProps.value.title
                          ? slotProps.value.title
                          : slotProps.value
                      }}
                    </div>
                    <div class="p-field" v-else>
                      {{ slotProps.placeholder }}
                    </div>
                  </template>
                </Dropdown>
                <label for="countries"
                  >Select {{ data.component | changeComponent }}</label
                >
              </span>
            </div>
            <div class="p-field p-col" v-if="data.component == 'IncidentType'">
              <span class="p-float-label">
                <InputText
                  id="username"
                  type="text"
                  v-model="data.record.attachmentsemail"
                />
                <label for="attorneyName">Notification Emails</label>
              </span>
            </div>
          </div>
          <div
            class="addattorney p-fluid"
            v-else-if="
              data.actionType == 'Add' ||
              data.actionType == 'Add Title Overwrite' ||
              data.actionType == 'Add Overview' ||
              data.actionType == 'nestedadd'
            "
          >
            <div v-if="data.component == 'Lawfirm'" class="mt-4">
              <div class="p-grid">
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      :class="{ 'p-invalid': submitted && !newrecord.name }"
                      v-model="newrecord.name"
                    />
                    <label for="attorneyName">Name</label>
                  </span>
                  <span
                    :class="{ 'p-invalid': submitted && !newrecord.name }"
                    v-if="submitted && !newrecord.name"
                    class="error-message"
                    >Attorney Name is Required</span
                  >
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      v-model="newrecord.title"
                      :class="{ 'p-invalid': submitted && !newrecord.title }"
                    />
                    <label for="attorneyTitle">Title</label>
                  </span>
                  <span
                    :class="{ 'p-invalid': !newrecord.title }"
                    v-if="submitted && !newrecord.title"
                    class="error-message"
                    >Attorney Title is Required</span
                  >
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      v-model="newrecord.email"
                      :class="{ 'p-invalid': submitted && !newrecord.email }"
                    />
                    <label for="attorneyEmail">Email</label>
                  </span>
                  <span
                    :class="{ 'p-invalid': !newrecord.email }"
                    v-if="submitted && !newrecord.email"
                    class="error-message"
                    >Attorney Email is Required</span
                  >
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      v-model="newrecord.streetAddress"
                      :class="{
                        'p-invalid': submitted && !newrecord.streetAddress,
                      }"
                    />
                    <label for="attorneyStreetAddress">StreetAddress</label>
                  </span>
                  <span
                    :class="{ 'p-invalid': !newrecord.streetAddress }"
                    v-if="submitted && !newrecord.streetAddress"
                    class="error-message"
                    >Attorney StreetAddress is Required</span
                  >
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      v-model="newrecord.city"
                      :class="{ 'p-invalid': submitted && !newrecord.city }"
                    />
                    <label for="attorneyCity">City</label>
                  </span>
                  <span
                    :class="{ 'p-invalid': !newrecord.city }"
                    v-if="submitted && !newrecord.city"
                    class="error-message"
                    >Attorney City is Required</span
                  >
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      v-model="newrecord.state"
                      :class="{ 'p-invalid': submitted && !newrecord.state }"
                    />
                    <label for="attorneyState">State</label>
                  </span>
                  <span
                    :class="{ 'p-invalid': !newrecord.state }"
                    v-if="submitted && !newrecord.state"
                    class="error-message"
                    >Attorney State is Required</span
                  >
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      v-model="newrecord.zipcode"
                      :class="{ 'p-invalid': submitted && !newrecord.zipcode }"
                    />
                    <label for="attorneyZipcode">Zipcode</label>
                  </span>
                  <span
                    :class="{ 'p-invalid': !newrecord.zipcode }"
                    v-if="submitted && !newrecord.zipcode"
                    class="error-message"
                    >Attorney ZipCode is Required</span
                  >
                </div>
              </div>
            </div>
            <div v-else-if="data.component == 'Service'" class="p-mt-3">
              <div
                v-if="
                  data.actionType == 'Add Title Overwrite' ||
                  data.actionType == 'nestedadd'
                "
              >
                <div class="p-grid">
                  <div class="p-field p-col-4">
                    <span class="p-float-label">
                      <Dropdown
                        appendTo="body"
                        v-model="newrecord.incidentTypeSlug"
                        :options="data.options"
                        optionLabel="title"
                        :filter="true"
                      >
                        <template #value="slotProps">
                          <div class="" v-if="slotProps.value">
                            {{ slotProps.value.title }}
                          </div>
                          <div class="p-field" v-else>
                            {{ slotProps.placeholder }}
                          </div>
                        </template>
                      </Dropdown>
                      <label for="countries">Select IncidentType</label>
                    </span>
                    <span
                      :class="{ 'p-invalid': !newrecord.incidentTypeSlug }"
                      v-if="submitted && !newrecord.incidentTypeSlug"
                      class="error-message"
                      >Select IncidentType is Required</span
                    >
                  </div>
                  <div class="p-field p-col-4">
                    <span class="p-float-label">
                      <Dropdown
                        appendTo="body"
                        v-model="newrecord.subofferingTypeSlug"
                        :options="data.subofferingTypes"
                        optionLabel="title"
                        :filter="true"
                      >
                        <template #value="slotProps">
                          <div class="" v-if="slotProps.value">
                            {{ slotProps.value.title }}
                          </div>
                          <div class="p-field" v-else>
                            {{ slotProps.placeholder }}
                          </div>
                        </template>
                      </Dropdown>
                      <label for="countries">Select SubofferingType</label>
                    </span>
                  </div>
                  <div class="p-field p-col-4">
                    <span class="p-float-label">
                      <Dropdown
                        appendTo="body"
                        v-model="newrecord.lawfirmSlug"
                        :options="data.lawfirms"
                        optionLabel="title"
                        :filter="true"
                      >
                        <template #value="slotProps">
                          <div class="" v-if="slotProps.value">
                            {{ slotProps.value.title }}
                          </div>
                          <div class="p-field" v-else>
                            {{ slotProps.placeholder }}
                          </div>
                        </template>
                      </Dropdown>
                      <label for="countries">Select Lawfirm</label>
                    </span>
                  </div>
                  <div class="p-field p-col-8">
                    <span class="p-float-label">
                      <InputText
                        id="title"
                        type="text"
                        :class="{ 'p-invalid': submitted && !newrecord.title }"
                        v-model="newrecord.title"
                      />
                      <label for="titleoverwrite"
                        >{{ data.component }} Title</label
                      >
                    </span>
                    <span
                      :class="{ 'p-invalid': !newrecord.title }"
                      v-if="submitted && !newrecord.title"
                      class="error-message"
                    >
                      {{ data.component }} Title is Required</span
                    >
                  </div>
                  <div class="p-field p-col-4">
                    <span class="p-float-label">
                      <Dropdown
                        appendTo="body"
                        v-model="newrecord.isDisabled"
                        :options="['Active', 'InActive']"
                        :class="{
                          'p-invalid': submitted && !newrecord.isDisabled,
                        }"
                      >
                      </Dropdown>
                      <label for="countries">Select Disabled</label>
                    </span>
                    <span
                      :class="{ 'p-invalid': !newrecord.isDisabled }"
                      v-if="submitted && !newrecord.isDisabled"
                      class="error-message"
                      >Select Disabled is Required</span
                    >
                  </div>
                </div>
              </div>
              <div v-else-if="data.actionType == 'Add Overview'">
                <div class="p-field p-col-8">
                  <span class="p-float-label">
                    <InputText
                      id="title"
                      type="text"
                      :class="{ 'p-invalid': submitted && !newrecord.title }"
                      v-model="newrecord.title"
                    />
                    <label for="titleoverwrite"
                      >{{ data.component }} Overview</label
                    >
                  </span>
                  <span
                    :class="{ 'p-invalid': !newrecord.title }"
                    v-if="submitted && !newrecord.title"
                    class="error-message"
                  >
                    {{ data.component }} Overview is Required</span
                  >
                </div>
              </div>
            </div>
            <div v-else>
              <div class="p-field p-col">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    type="text"
                    :class="{ 'p-invalid': submitted && !newrecord.slug }"
                    v-model="newrecord.slug"
                  />
                  <label for="attorneyName">{{ data.component }} Slug</label>
                </span>
                <span
                  :class="{ 'p-invalid': !newrecord.slug }"
                  v-if="submitted && !newrecord.slug"
                  class="error-message"
                >
                  {{ data.component }} Slug is Required</span
                >
              </div>
              <div class="p-field p-col">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    type="text"
                    :class="{ 'p-invalid': submitted && !newrecord.title }"
                    v-model="newrecord.title"
                  />
                  <label for="attorneyName">{{ data.component }} Name </label>
                </span>
                <span
                  :class="{ 'p-invalid': !newrecord.title }"
                  v-if="submitted && !newrecord.title"
                  class="error-message"
                  >{{ data.component }} Name is Required</span
                >
              </div>
              <div class="p-field p-col" v-if="data.isdrop">
                <span class="p-float-label">
                  <Dropdown
                    appendTo="body"
                    v-model="newrecord.incidentTypeSlug"
                    :options="data.options"
                    :class="{
                      'p-invalid': submitted && !newrecord.incidentTypeSlug,
                    }"
                    optionLabel="title"
                    :filter="true"
                  >
                    <template #value="slotProps">
                      <div class="" v-if="slotProps.value">
                        {{ slotProps.value.title }}
                      </div>
                      <div class="p-field" v-else>
                        {{ slotProps.placeholder }}
                      </div>
                    </template>
                  </Dropdown>
                  <label for="countries"
                    >Select {{ data.component | changeComponent }}</label
                  >
                </span>
                <span
                  :class="{ 'p-invalid': !newrecord.incidentTypeSlug }"
                  v-if="submitted && !newrecord.incidentTypeSlug"
                  class="error-message"
                  >{{ data.component | changeComponent }} is Required</span
                >
              </div>
            </div>
            <div class="p-col-4 p-offset-4">
              <Button
                :label="
                  data.component == 'Service'
                    ? data.actionType == 'nestedadd'
                      ? 'Add Overview Overwrites'
                      : data.actionType
                    : 'Add'
                "
                icon="pi pi-plus-circle"
                @click="savenewrecord"
                autofocus
              />
            </div>
          </div>
          <div
            class="addattorney p-fluid"
            v-else-if="data.actionType == 'nestededit'"
          >
            <div id="nestedattorney" class="p-mt-4">
              <div class="p-grid" v-if="data.component == 'Service'">
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      :class="{ 'p-invalid': !data.record.title }"
                      v-model="data.record.title"
                    />
                    <label for="attorneyName">TitleOverwrite</label>
                  </span>
                  <span
                    :class="{ 'p-invalid': !data.record.title }"
                    v-if="!data.record.title"
                    class="error-message"
                    >TitleOverwrite is Required</span
                  >
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <Dropdown
                      appendTo="body"
                      v-model="data.record.incidentTypeSlug"
                      :class="{ 'p-invalid': !data.record.incidentTypeSlug }"
                      :options="data.incidentTypes"
                      :filter="true"
                      optionLabel="title"
                    >
                      <template #value="slotProps">
                        <div class="" v-if="slotProps.value">
                          {{
                            slotProps.value.title
                              ? slotProps.value.title
                              : slotProps.value
                          }}
                        </div>
                        <div class="p-field" v-else>
                          {{ slotProps.placeholder }}
                        </div>
                      </template>
                    </Dropdown>
                    <label for="incidenttype">Select IncidentType</label>
                  </span>
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <Dropdown
                      appendTo="body"
                      v-model="data.record.subofferingTypeSlug"
                      :options="data.subofferingTypes"
                      :filter="true"
                      optionLabel="title"
                    >
                      <template #value="slotProps">
                        <div class="" v-if="slotProps.value">
                          {{
                            slotProps.value.title
                              ? slotProps.value.title
                              : slotProps.value
                          }}
                        </div>
                        <div class="p-field" v-else>
                          {{ slotProps.placeholder }}
                        </div>
                      </template>
                    </Dropdown>
                    <label for="subofferingtype">Select SubofferingType</label>
                  </span>
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <Dropdown
                      appendTo="body"
                      v-model="data.record.lawfirmSlug"
                      :options="data.lawfirms"
                      :filter="true"
                      optionLabel="title"
                    >
                      <template #value="slotProps">
                        <div class="" v-if="slotProps.value">
                          {{
                            slotProps.value.title
                              ? slotProps.value.title
                              : slotProps.value
                          }}
                        </div>
                        <div class="p-field" v-else>
                          {{ slotProps.placeholder }}
                        </div>
                      </template>
                    </Dropdown>
                    <label for="lawfirm">Select Lawfirm</label>
                  </span>
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <Dropdown
                      appendTo="body"
                      v-model="data.record.isDisabled"
                      :options="['Active', 'InActive']"
                      :class="{
                        'p-invalid': submitted && !newrecord.isDisabled,
                      }"
                    >
                    </Dropdown>
                    <label for="attorneyName">IsDisabled</label>
                  </span>
                </div>
              </div>
              <div class="p-grid" v-else>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      :class="{ 'p-invalid': !data.record.name }"
                      v-model="data.record.name"
                    />
                    <label for="attorneyName">Name</label>
                  </span>
                  <span
                    :class="{ 'p-invalid': !data.record.name }"
                    v-if="!data.record.name"
                    class="error-message"
                    >Attorney Name is Required</span
                  >
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      v-model="data.record.title"
                      :class="{ 'p-invalid': !data.record.title }"
                    />
                    <label for="attorneyTitle">Title</label>
                  </span>
                  <span
                    :class="{ 'p-invalid': !data.record.title }"
                    v-if="!data.record.title"
                    class="error-message"
                    >Attorney Title is Required</span
                  >
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      v-model="data.record.email"
                      :class="{ 'p-invalid': !data.record.email }"
                    />
                    <label for="attorneyEmail">Email</label>
                  </span>
                  <span
                    :class="{ 'p-invalid': !data.record.email }"
                    v-if="!data.record.email"
                    class="error-message"
                    >Attorney Email is Required</span
                  >
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      v-model="data.record.streetAddress"
                      :class="{ 'p-invalid': !data.record.streetAddress }"
                    />
                    <label for="attorneyStreetAddress">StreetAddress</label>
                  </span>
                  <span
                    :class="{ 'p-invalid': !data.record.streetAddress }"
                    v-if="!data.record.streetAddress"
                    class="error-message"
                    >Attorney StreetAddress is Required</span
                  >
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      v-model="data.record.city"
                      :class="{ 'p-invalid': !data.record.city }"
                    />
                    <label for="attorneyCity">City</label>
                  </span>
                  <span
                    :class="{ 'p-invalid': !data.record.city }"
                    v-if="!data.record.city"
                    class="error-message"
                    >Attorney City is Required</span
                  >
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      v-model="data.record.state"
                      :class="{ 'p-invalid': !data.record.state }"
                    />
                    <label for="attorneyState">State</label>
                  </span>
                  <span
                    :class="{ 'p-invalid': !data.record.state }"
                    v-if="!data.record.state"
                    class="error-message"
                    >Attorney State is Required</span
                  >
                </div>
                <div class="p-field p-col-4">
                  <span class="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      v-model="data.record.zipCode"
                      :class="{ 'p-invalid': !data.record.zipCode }"
                    />
                    <label for="attorneyZipcode">Zipcode</label>
                  </span>
                  <span
                    :class="{ 'p-invalid': !data.record.zipCode }"
                    v-if="!data.record.zipCode"
                    class="error-message"
                    >Attorney ZipCode is Required</span
                  >
                </div>
              </div>
            </div>
            <div
              class="p-col-4 p-offset-4"
              v-if="data.actionType != 'nestededit'"
            >
              <Button
                :label="'Add ' + data.component"
                icon="pi pi-plus-circle"
                @click="savenewrecord"
                autofocus
              />
            </div>
          </div>
        </div>
        <div
          class="p-col-12"
          v-if="data.actionType == 'Add' || 'Add Title Overwrite'"
        >
          <Table
            v-if="tableObject.records.length"
            ref="DialogTable"
            :data="tableObject"
            :method="deletenewrecord"
          />
        </div>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          @click="handleCancel(data)"
          class="p-button-warning"
        />
        <Button
          v-if="
            tableObject.records.length ||
            data.actionType == 'Edit' ||
            data.actionType == 'nestededit'
          "
          label="Save"
          icon="pi pi-arrow-circle-up"
          @click="
            handleSave(
              data,
              data.rootObject
                ? data.rootObject['data']
                  ? data.rootObject['data'].attorneys
                  : data.rootObject
                : tableObject.records,
              tableObject.records,
              data.nestedtype
            )
          "
          autofocus
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import _ from "lodash";
import Table from "./NestedDataTable";
export default {
  name: "dialogComponent",
  components: {
    Table,
  },
  beforeMount() {
    let that = this;
    that.componentName = this.data.component;
    that.type = this.data.type;
    if (this.data.component == "Lawfirm") {
      that.tableObject.columns = _.reject(
        that.tableObject.columns,
        (column) =>
          column.Type == "SubofferingType" ||
          column.Type == "Service" ||
          column.Type == ""
      );
    } else if (this.data.component == "DocumentType") {
      that.tableObject.columns = _.reject(
        that.tableObject.columns,
        (column) =>
          column.Type == "Lawfirm" ||
          column.Type == "SubofferingType" ||
          column.Type == "Service"
      );
    } else if (this.type == "Add Overview") {
      that.tableObject.columns = _.reject(
        that.tableObject.columns,
        (column) =>
          column.Type == "Lawfirm" ||
          column.Type == "SubofferingType" ||
          column.Type == "Service" ||
          column.Type == ""
      );
    } else if (this.data.component == "Service") {
      that.tableObject.columns = _.reject(
        that.tableObject.columns,
        (column) => column.Type == "Lawfirm"
      );
    } else {
      that.tableObject.columns = _.reject(
        that.tableObject.columns,
        (column) => column.Type == "Lawfirm" || column.Type == "Service"
      );
    }
  },
  methods: {
    errorMessage() {
      this.$toast.add({
        severity: "error",
        summary: "Error Message",
        detail: `${this.error}`,
        life: 2000,
      });
    },
    isDuplicate(type) {
      let that = this;
      let record = that.newrecord;
      let oldrecords = that.tableObject.records;
      let isDuplicate = _.some(oldrecords, (oldrecord) =>
        oldrecord.slug
          ? oldrecord.slug == record.slug || type != "lawfirm"
            ? oldrecord.title == record.title
            : false
          : type != "lawfirm"
          ? oldrecord.title == record.title
          : false
      );
      if (isDuplicate) {
        let that = this;
        that.error = "Title or Slug should be unique";
        that.errorMessage();
      }
      return isDuplicate;
    },
    isDocumentValid() {
      let that = this;
      let record = that.newrecord;
      return (
        record.slug != "" &&
        record.title != "" &&
        record.slug != undefined &&
        record.title != undefined &&
        record.slug != null &&
        record.title != null &&
        !that.isDuplicate("document")
      );
    },
    isSubofferingTypeValid() {
      let that = this;
      let record = that.newrecord;
      return (
        record.slug != "" &&
        record.title != "" &&
        record.incidentTypeSlug != "" &&
        record.slug != undefined &&
        record.title != undefined &&
        record.incidentTypeSlug.slug != undefined &&
        record.slug != null &&
        record.title != null &&
        record.incidentTypeSlug.slug != null &&
        !that.isDuplicate("suboffering")
      );
    },
    isLawfirmValid() {
      let that = this;
      let record = that.newrecord;
      return (
        record.city != "" &&
        record.email != "" &&
        record.name != "" &&
        record.state != "" &&
        record.streetAddress != "" &&
        record.title != "" &&
        record.zipcode != "" &&
        record.city != undefined &&
        record.email != undefined &&
        record.name != undefined &&
        record.state != undefined &&
        record.streetAddress != undefined &&
        record.title != undefined &&
        record.zipcode != undefined &&
        record.city != null &&
        record.email != null &&
        record.name != null &&
        record.state != null &&
        record.streetAddress != null &&
        record.title != null &&
        record.zipcode != null &&
        !that.isDuplicate("lawfirm")
      );
    },
    isServiceValid() {
      let that = this;
      let record = that.newrecord;
      return (
        record.incidentTypeSlug.slug != "" &&
        record.title != "" &&
        record.isDisabled != "" &&
        record.incidentTypeSlug.slug != undefined &&
        record.title != undefined &&
        record.isDisabled != undefined &&
        record.incidentTypeSlug.slug != null &&
        record.title != null &&
        record.isDisabled != null
      );
    },
    isOverviewValid() {
      let that = this;
      let record = that.newrecord;
      return (
        record.title != "" &&
        record.title != undefined &&
        record.title != null &&
        !that.isDuplicate("overview")
      );
    },
    savenewrecord() {
      let that = this;
      that.submitted = true;
      let isValid =
        this.componentName == "DocumentType"
          ? that.isDocumentValid()
          : this.componentName == "SubofferingType"
          ? that.isSubofferingTypeValid()
          : this.componentName == "Lawfirm"
          ? that.isLawfirmValid()
          : this.componentName == "nestededit"
          ? that.isLawfirmValid()
          : this.componentName == "Service" && this.type != "Add Overview"
          ? that.isServiceValid()
          : this.componentName == "Service" && this.type == "Add Overview"
          ? that.isOverviewValid()
          : false;

      if (isValid) {
        that.newrecord.subofferingTypeSlug
          ? (that.newrecord.subofferingTypeSlug =
              that.newrecord.subofferingTypeSlug.slug)
          : that.newrecord;
        that.newrecord.lawfirmSlug
          ? (that.newrecord.lawfirmSlug = that.newrecord.lawfirmSlug.slug)
          : that.newrecord;
        that.newrecord.incidentTypeSlug
          ? (that.newrecord.incidentTypeSlug =
              that.newrecord.incidentTypeSlug.slug)
          : that.newrecord;
        let record = _.clone(that.newrecord);
        if (record.isDisabled) {
          record.isDisabled =
            record.isDisabled == "Active"
              ? true
              : record.isDisabled == "InActive"
              ? false
              : record.isDisabled;
        }
        that.tableObject.records.push(record);
        that.cleardialog();
      }
    },
    deletenewrecord(record) {
      let that = this;
      that.tableObject.records = _.reject(
        that.tableObject.records,
        (r) => r.title == record.title
      );
    },
    cleardialog() {
      let that = this;
      that.newrecord = {};
      that.submitted = false;
    },
  },
  props: {
    flages: {
      type: Object,
    },
    handleCancel: {
      type: Function,
    },
    handleSave: {
      type: Function,
    },
    data: {
      type: Object,
    },
  },
  watch: {},
  data() {
    return {
      tableObject: {
        isedit: false,
        header: "",
        columns: [
          // { field: "slug", header: "Slug", Type: "" },
          { field: "title", header: "Title" },
          {
            field: "incidentTypeSlug",
            header: "IncidentType",
            Type: "SubofferingType",
          },
          {
            field: "subofferingTypeSlug",
            header: "SubofferingTypeSlug",
            Type: "Service",
          },
          { field: "lawfirmSlug", header: "LawfirmSlug", Type: "Service" },
          { field: "isDisabled", header: "IsDisabled", Type: "Service" },
          { field: "name", header: "Name", Type: "Lawfirm" },
          { field: "email", header: "Email", Type: "Lawfirm" },
          { field: "streetAddress", header: "Address", Type: "Lawfirm" },
          { field: "city", header: "City", Type: "Lawfirm" },
          { field: "state", header: "State", Type: "Lawfirm" },
          { field: "zipcode", header: "ZipCode", Type: "Lawfirm" },
          { field: "actions", header: "Actions" },
        ],
        records: [],
        iseditattorney: false,
      },
      countries: null,
      selectedoption: null,
      newrecord: {},
      componentName: null,
      type: null,
      submitted: false,
    };
  },
};
</script>

<style>
.modaleditwidth {
  width: "40vw";
}

.modaladdwidth {
  width: "40vw";
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  font-weight: bold !important;
  color: #225083 !important;
}
</style>
