import Api from './Api';
const endPoint = "subofferingtypes";

const getSubofferingTypes = subofferingTypes => Api.get(`${endPoint}/subofferingtypes`, subofferingTypes);
const postSubofferingTypes =subofferingTypes => Api.post(`${endPoint}`, subofferingTypes);
const putSubofferingType =subofferingType => Api.put(`${endPoint}`, subofferingType);

export {
    getSubofferingTypes,
    postSubofferingTypes,
    putSubofferingType
}