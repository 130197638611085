/* eslint-disable no-unused-vars */
import {
    getAuthorities,
    postAuthorities,
    putAuthorities
} from '../../services/Authorities.Service'

const state = {
    authorities: {}
}
const getters = {
    getAuthorities(state) {
        return state.authorities;
    }
}
const actions = {
    fetchAuthorities({ commit }) {
        return new Promise((resolve, reject) => {
            getAuthorities().then(response => {
                if (response.status == 200) {
                    commit('setAuthorities', response.data.data);
                    resolve();
                }
            }).catch((error) => {
                reject(error)
            })
        });
    },
    saveAuthority({ commit }, authoritiy) {
        return new Promise((resolve, reject) => {
            postAuthorities(authoritiy).then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error)
            });
        });
    },
    updateAuthority({ commit }, authoritiy) {
        return new Promise((resolve, reject) => {
            putAuthorities(authoritiy).then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error);
            })
        });
    }

}
const mutations = {
    setAuthorities(state, response) {
        state.authorities = response;
    }
}
export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}