import Api from './Api';
const endPoint = "services";

const getServices = Services => Api.get(`${endPoint}/services`, Services);
const syncServices = syncServices => Api.get(`${endPoint}`, syncServices);
const putService = service => Api.put(`${endPoint}`, service);

export {
    getServices,
    syncServices,
    putService
}