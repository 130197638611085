<template>
  <div id="componentDataTable">
    <div class="p-grid">
      <div class="p-col-12" style="height: calc(100vh - 155px)">
        <DataTable
          :scrollable="true"
          scrollHeight="flex"
          :loading="data.isloading"
          :filters="filters"
          sortMode="multiple"
          removableSort
          :value="data.records"
          :expandedRows.sync="data.expandedRows"
          :paginator="true"
          :rows="20"
          :rowsPerPageOptions="[20, 40, 60, 80]"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          dataKey="title"
          class="p-datatable-gridlines"
        >
          <template #header>
            <div class="p-grid">
              <div class="p-col-3 p-text-center mt-2 p-text-left">
                <span class=""
                  ><b>{{ data.header }}</b></span
                >
              </div>
              <div class="p-col-6 text-center">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 567px !important"
                    v-model="filters['global']"
                    placeholder="Global Search"
                  />
                </span>
              </div>
              <div class="p-col-2">
                <Button
                  v-if="data.issync"
                  :label="data.syncbtn ? data.syncbtn : 'Sync Fields'"
                  v-tooltip.right="
                    data.syncbtn ? data.syncbtn : 'Sync Maven-Fields'
                  "
                  class="p-button-raised p-button-info p-mr-3"
                  @click="syncmethod()"
                />
                <Button
                  v-else
                  icon="pi pi-plus"
                  label="Add New"
                  v-tooltip.top="'Add New'"
                  class="p-button-raised p-button-info p-mr-3"
                  @click="method('', 'Add')"
                />
              </div>
            </div>
          </template>
          <Column
            :expander="true"
            headerStyle="width: 3rem"
            v-if="data.isexpandable"
            :headerClass="'thheader'"
          />
          <template #empty> No records found. </template>
          <Column
            v-for="col of data.columns"
            :field="col.field"
            :header="col.header"
            :sortable="true"
            :bodyClass="col.class"
            :headerClass="col.class + ' thheader'"
            :key="col.field"
          >
            <template #body="slotProps">
              <span v-if="col.field == 'isActive'">
                {{ slotProps.data[col.field] | formatStatus }}
              </span>
              <span v-else-if="col.field == 'countrySlug'">
                {{ slotProps.data[col.field] }}
              </span>
              <span v-else-if="col.field == 'isDisabled'">
                {{ slotProps.data[col.field] | formatStatus }}
              </span>
              <span v-else-if="col.field == 'actions'">
                <Button
                  v-if="
                    data.component == 'lawfirm' || data.component == 'service'
                  "
                  icon="pi pi-plus"
                  v-tooltip.top="
                    data.addbtntooltip ? data.addbtntooltip : 'Add New Attorney'
                  "
                  class="p-button-raised p-button-primary p-mr-3"
                  @click="
                    method(
                      slotProps.data,
                      data.addbtntooltip ? 'Add Title Overwrite' : 'Add'
                    )
                  "
                />
                <Button
                  v-if="data.component == 'service'"
                  icon="pi pi-plus"
                  v-tooltip.top="'Add Overview'"
                  class="p-button-raised p-button-help p-mr-3"
                  @click="method(slotProps.data, 'Add Overview')"
                />
                <Button
                  v-if="data.isedit"
                  icon="pi pi-pencil"
                  v-tooltip.top="'Edit ' + data.component"
                  class="p-button-raised p-button-info p-mr-3"
                  @click="method(slotProps.data, 'Edit')"
                />
                <Button
                  v-if="data.isview"
                  icon="pi pi-eye"
                  v-tooltip.top="'View Overviews'"
                  class="p-button-raised p-button-secondary p-mr-3"
                  @click="method(slotProps.data, 'view')"
                />
                <Button
                  v-if="data.isview"
                  icon="pi pi-eye"
                  v-tooltip.top="'View Overview Overwrites'"
                  class="p-button-raised p-button-help p-mr-3"
                  @click="method(slotProps.data, 'overwritesview')"
                />
                <Button
                  icon="pi pi-trash"
                  v-tooltip.top="'Deactivate'"
                  v-if="slotProps.data.isActive"
                  class="p-button-raised p-button-warning p-mr-3"
                  @click="method(slotProps.data, 'ActivateDeactive')"
                />
                <Button
                  icon="pi pi-check-circle"
                  v-tooltip.right="'Activate'"
                  v-if="!slotProps.data.isActive"
                  class="p-button-raised p-button-success p-mr-3"
                  @click="method(slotProps.data, 'ActivateDeactive')"
                />
              </span>
              <span v-else> {{ slotProps.data[col.field] }}</span>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div class="datatable-subtable p-m-4">
              <NestedDataTable
                ref="nestedDataTable"
                :data="slotProps"
                :handleNestedSave="handlealert"
                :method="handleNestedItemEdit"
              />
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import NestedDataTable from "./NestedDataTable";
import _ from "lodash";
export default {
  name: "dataTableComponent",
  created() {
    this.toastsumary =
      this.data["component"] == "lawfirm"
        ? "Lawfirm Attornyes are "
        : "Service Title Overwrites are ";
  },
  mounted() {},
  components: { NestedDataTable },
  methods: {
    onRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: `${this.toastsumary} Expanded`,
        detail: event.data.Title,
        life: 3000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: `${this.toastsumary} Closed`,
        detail: event.data.Title,
        life: 3000,
      });
    },
    handlealert() {
      this.$parent.handleSave();
    },
    handleNestedItemEdit(attorney, type, data, nestedtype) {
      if (type == "nestededit") {
        let newattorney = attorney;
        newattorney.isDisabled = _.clone(
          newattorney.isDisabled ? "Active" : "InActive"
        );
        this.$parent.toggleNestedDisplayDialog(newattorney, type, data);
      } else if (type == "nesteddelete") {
        this.$parent.toggleNestedConfirmationDialog(attorney, type, data);
      } else if (type == "nestedadd") {
        this.$parent.toggleNestedDisplayDialog(attorney, type, data);
      }
    },
  },
  props: {
    data: { type: Object },
    syncmethod: { type: Function },
    method: { type: Function },
    handleSave: { type: Function },
  },
  watch: {},
  data() {
    return {
      toastsumary: null,
      filters: {},
    };
  },
};
</script>
<style>
.p-datatable-flex-scrollable .p-datatable-scrollable-body {
  background-color: white !important;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  background-color: #f8f9fa !important;
}
</style>