<template>
  <div id="componentMasterServiceConfigs" style="height: calc(100vh - 165px)">
    <DataTable
      :scrollable="true"
      scrollHeight="flex"
      :loading="isloading"
      :filters="filters"
      sortMode="multiple"
      removableSort
      :value="mainTemplates"
      :paginator="true"
      :rows="20"
      :rowsPerPageOptions="[20, 40, 60, 80]"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      data-key="id"
      class="p-datatable-gridlines"
      :expandedRows.sync="expandedRows"
    >
      <template #header>
        <div class="p-grid">
          <div class="p-col-3 p-text-center mt-2 p-text-left">
            <span class=""><b>Master Service Configuration</b></span>
          </div>
          <div class="p-col-6 text-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                style="width: 567px !important"
                v-model="filters['global']"
                placeholder="Global Search"
              />
            </span>
          </div>
          <div class="p-col-2">
            <Button
              icon="pi pi-plus"
              label="Add New"
              v-tooltip.top="'Add New'"
              class="p-button-raised p-button-info p-mr-3"
              @click="openNew()"
            />
          </div>
        </div>
      </template>
      <template #empty> No records found. </template>
      <Column
        field="title"
        header="Title"
        :sortable="true"
        :headerClass="'thheader'"
      ></Column>
      <Column
        field="description"
        header="Description"
        :sortable="true"
        :headerClass="'thheader'"
      ></Column>
      <Column :headerClass="'thheader'" header="Actions">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            v-tooltip.top="'Edit Master Service Config'"
            @click="editTemplate(slotProps.data)"
            class="p-button-raised p-button-info p-mr-2"
          />
          <Button
            icon="pi pi-trash"
            v-tooltip.top="'Deactivate Master Service Config'"
            v-if="slotProps.data.isActive"
            @click="handleactivateDeactivateTemplate(slotProps.data)"
            class="p-button-raised p-button-warning p-mr-2"
          />
          <Button
            v-if="!slotProps.data.isActive"
            icon="pi pi-check-circle"
            v-tooltip.top="'Activate Master Service Config'"
            @click="handleactivateDeactivateTemplate(slotProps.data)"
            class="p-button-raised p-button-success p-mr-2"
          />
        </template>
      </Column>
    </DataTable>
    <Dialog
      maximizable
      :visible.sync="templateDialog"
      :style="{ width: '70vw' }"
      :header="editTemplateMode ? 'Edit Template' : 'Add Template'"
      :modal="true"
      class="p-fluid"
    >
      <div>
        <br />
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="title"
              v-model.trim="template.title"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !template.title }"
            />
            <label for="name">Title<span class="p-invalid">*</span></label>
          </span>
          <span class="error-message" v-if="submitted && !template.title"
            >Name is required.</span
          >
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="title"
              v-model.trim="template.description"
              autofocus
            />
            <label for="name">Description</label>
          </span>
        </div>
        <div class="p-field p-col" v-if="addTemplateMode">
          <span class="p-float-label">
            <InputText
              id="slug"
              v-model.trim="template.slug"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !template.slug }"
            />
            <label for="name">Slug<span class="p-invalid">*</span></label>
          </span>
          <small class="error-message" v-if="submitted && !template.slug"
            >Slug is required.</small
          >
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <Dropdown
              appendTo="body"
              v-model.trim="template.isProjectManagementPercentage"
              :options="projectManagementPriceType"
              optionLabel="name"
              :filter="true"
              placeholder=""
            >
              <template #value="slotProps">
                <div class="p-dropdown-car-value" v-if="slotProps.value">
                  <span
                    >{{
                      slotProps.value.name
                        ? slotProps.value.name
                        : slotProps.value
                    }}
                  </span>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span>{{ slotProps.option.name }}</span>
                </div>
              </template>
            </Dropdown>
            <label for="name">Project Management Price Type</label>
          </span>
        </div>
        <div class="p-field p-col">
          <span class="p-float-label">
            <InputText
              id="slug"
              v-model.trim="template.projectManagementValue"
            />
            <label for="name">Project Management Price Value</label>
          </span>
        </div>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-warning"
          @click="hideDialog"
        />
        <Button label="Save" icon="pi pi-arrow-circle-up" @click="handleSave" />
      </template>
    </Dialog>
    <Dialog
      maximizable
      :visible.sync="confirmationDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span
          >Are you sure you want to
          {{ template.isActive ? "delete" : "activate" }}
          <b>{{ template.title }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideconDialog"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="activateDeactivateTemplate(template)"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "masterserviceConfigsComponent",
  created() {
    this.fetchMasterServiceConfigurations();
  },
  components: {},
  methods: {
    handleSlug() {
      let selectedService = this.template;
      let type = _.filter(
        this.mainTemplates,
        (mr) => mr.masterTemplate === selectedService.masterTemplate.slug
      );
      let trm = type[0].title
        .trim()
        .toLowerCase()
        .replace(/[^\w\s]|\d/gi, "")
        .replace(/\s+/g, " ");
      let words = trm.split(" ");
      let result = "";
      for (let i = 0; i < words.length; i++) {
        let word = words[i];
        result += "-" + word.substring(0, 3);
      }
      this.template.slug = "tcfg" + result;
    },
    onRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Master Template Expanded",
        detail: event.data.title,
        life: 3000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Master Template Collapsed",
        detail: event.data.title,
        life: 3000,
      });
    },
    fetchMasterServiceConfigurations() {
      var that = this;
      that.isloading = true;
      this.$store
        .dispatch("masterserviceconfig/fetchMasterServiceConfigs")
        .then(() => {
          that.tempresponse =
            this.$store.getters["masterserviceconfig/getMasterServiceConfigs"];
          that.mainTemplates = _.clone(that.tempresponse);
          that.isloading = false;
        });
    },
    fetchLawfirms() {
      var that = this;
      this.$store.dispatch("lawfirm/fetchlawfirms").then(() => {
        that.response = this.$store.getters["lawfirm/getlawfirms"];
        this.lawfirms = _.clone(that.response);
      });
    },
    openNew() {
      this.template = {};
      this.submitted = false;
      this.editTemplateMode = false;
      this.addTemplateMode = true;
      this.templateDialog = true;
    },
    editTemplate(template) {
      console.log(template);
      this.editTemplateMode = true;
      this.addTemplateMode = false;
      template.isProjectManagementPercentage = this.formatProject(
        template.isProjectManagementPercentage
      );
      this.template = _.clone(template);
      this.templateDialog = true;
    },
    formatProject(value) {
      let that = this;
      let type = _.filter(that.projectManagementPriceType, (mr) => mr.code === value);
      return (value = type.length ? type[0].name : value);
    },
    hideDialog() {
      let that = this;
      this.templateDialog = false;
      this.editTemplateMode = true;
      this.addTemplateMode = false;
      that.template = {};
    },
    handleactivateDeactivateTemplate(template) {
      let that = this;
      that.template = template;
      this.confirmationDialog = true;
    },
    activateDeactivateTemplate(template) {
      let that = this;
      that.template.isActive = !template.isActive;
      this.handleSave();
      this.confirmationDialog = false;
    },
    hideconDialog() {
      let that = this;
      that.confirmationDialog = false;
    },
    handleSave() {
      let that = this;
      this.submitted = true;
      that.template.isProjectManagementPercentage = that.template.isProjectManagementPercentage.name
        ? that.template.isProjectManagementPercentage.code
        :  _.filter(
            that.projectManagementPriceType,
            (mr) => mr.name === that.template.isProjectManagementPercentage
          )[0].code;
          that.template.projectManagementValue=parseInt(that.template.projectManagementValue);
      let template = that.template;
      if (that.template.title && that.template.slug) {
        this.$store
          .dispatch("masterserviceconfig/saveMasterServiceConfig", template)
          .then(() => {
            that.fetchMasterServiceConfigurations();
            that.hideDialog();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  props: {},
  watch: {},
  data() {
    return {
      mainTemplates: null,
      lawfirms: null,
      subOfferingTypes: null,
      masterTemplates: null,
      template: {},
      projectManagementPriceType: [
        { name: "Percentage", code: true },
        { name: "FixedFee", code: false },
      ],
      submitted: false,
      addTemplateMode: false,
      editTemplateMode: false,
      templateDialog: false,
      confirmationDialog: false,
      isloading: true,
      expandedRows: [],
      filters: {},
    };
  },
};
</script>

<style scoped>
</style>