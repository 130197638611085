import Api from './Api';
const endPoint = "addressAndBankDetails";

const getDetails = details => Api.get(`${endPoint}/details`, details);
const postDetails = details => Api.post(`${endPoint}`, details);
const putDetails = details => Api.put(`${endPoint}`, details);

export{
    getDetails,
    postDetails,
    putDetails
}