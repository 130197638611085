import Api from './Api';
const endPoint = "documenttypes";

const getDocumentType = documents => Api.get(`${endPoint}/documenttypes`, documents);
const postDocumentType = documents => Api.post(`${endPoint}`, documents);
const putDocumentType = documents => Api.put(`${endPoint}`, documents);

export{
    getDocumentType,
    postDocumentType,
    putDocumentType
}