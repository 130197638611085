import Api from './Api';
const endPoint = "templateconfiguration";

const getTemplateConfiguration = template => Api.get(`${endPoint}/templateconfigurations`, template);
const postTemplateConfiguration = template => Api.post(`${endPoint}`, template);
const putTemplateConfiguration = template => Api.put(`${endPoint}`, template);

export{
    getTemplateConfiguration,
    postTemplateConfiguration,
    putTemplateConfiguration
}