import * as msal from "@azure/msal-browser";

const state = {
  user: {},
};

const getters = {
  getToken(state) {
    return state.user.token;
  },
  getUser(state) {
    return state.user;
  },
  getUsername(state) {
    if (state.user.name && state.user.name.trim()) return state.user.name;
    return state.user.email;
  },
  getIsUserAuthenticated(state) {
    return state.user && !!state.user.token;
  },
};

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_clientId,
    authority: process.env.VUE_APP_tenant,
    redirectUri: process.env.VUE_APP_redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

const updateStore = (commit, response) => {
  const userDetails = {
    token: response.accessToken,
    name: response.account.name,
    email: response.account.userName,
  };
  commit("setUser", userDetails);
};

const loginRequest = {
  scopes: process.env.VUE_APP_scopes.split(","),
};

const actions = {
  async login({ commit }) {
    try {
      const tokenResponse = await msalInstance.loginPopup(loginRequest);
      updateStore(commit, tokenResponse);
    } catch (error) {
      console.log(error);
    }
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      sessionStorage.clear();
      commit("logout");
      resolve();
    });
  },
  async acquireTokenSilent({ commit, dispatch }) {
    try {
      const silentRequest = {
        scopes: process.env.VUE_APP_scopes.split(","),
        account: msalInstance.getAllAccounts()[0],
      };
      const tokenResponse = await msalInstance.acquireTokenSilent(silentRequest);
      updateStore(commit, tokenResponse);
    } catch (error) {
      if (error.name === "InteractionRequiredAuthError") {
        dispatch("login");
      } else {
        console.log(error);
      }
    }
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    state.user.token = token;
  },
  logout(state) {
    state.user = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
