import Api from './Api';
const endPoint = "mastertemplates";
const getcustomfields = customfields => Api.get(`${endPoint}/customfields`, customfields);
const getmastertemplates = mastertemplates => Api.get(`${endPoint}/mastertemplates`, mastertemplates);
const postmastertemplates = mastertemplates => Api.post(`${endPoint}`, mastertemplates);
const putmastertemplates = mastertemplates => Api.put(`${endPoint}`, mastertemplates);
const downloadmastertemplates = mastertemplates => Api.post(`${endPoint}/downloadtemplate`, mastertemplates,{
    responseType: "blob",
});
export {
    getcustomfields,
    getmastertemplates,
    postmastertemplates,
    putmastertemplates,
    downloadmastertemplates
}