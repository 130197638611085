<template>
  <div id="ComponentBaaNdaTemplateConfig">
    <DataTable
      :scrollable="true"
      scrollHeight="flex"
      :loading="isloading"
      :filters="filters"
      sortMode="multiple"
      removableSort
      :value="baandaTemplates"
      :paginator="true"
      :rows="20"
      :rowsPerPageOptions="[20, 40, 60, 80]"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      data-key="id"
      class="p-datatable-gridlines"
      :expandedRows.sync="expandedRows"
    >
      <template #header>
        <div class="p-grid">
          <div class="p-col-3 p-text-center mt-2 p-text-left">
            <span class=""><b>BaaNda Template</b></span>
          </div>
          <div class="p-col-6 text-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                style="width: 567px !important"
                v-model="filters['global']"
                placeholder="Global Search"
              />
            </span>
          </div>
          <div class="p-col-2">
            <Button
              icon="pi pi-plus"
              label="Add New"
              v-tooltip.left="'BaaNdaTemplate'"
              class="p-button-raised p-button-info p-mr-3"
              @click="handleAddTemplate()"
            />
          </div>
        </div>
      </template>
      <template #empty> No records found. </template>
      <Column
        :expander="true"
        headerStyle="width: 3rem"
        :headerClass="'thheader'"
      />
      <Column
        field="title"
        header="Title"
        :sortable="true"
        :headerClass="'thheader'"
      ></Column>
      <Column
        field="fileType"
        header="FileType"
        :sortable="true"
        :headerClass="'thheader'"
      ></Column>
      <Column
        field="countrySlug"
        header="Country"
        :sortable="true"
        :headerClass="'thheader'"
      >
        <template #body="slotProps">{{
          formatCountry(slotProps.data.countrySlug)
        }}</template>
      </Column>
      <Column header="View Document" :headerClass="'thheader'">
        <template #body="slotProps">
          <Button
            icon="pi pi-eye"
            v-tooltip.top="'View Document'"
            :label="slotProps.data.fileType == 'Nda' ? 'NDA' : 'BAA'"
            @click="documentView(slotProps.data.fileInfo.webURl)"
            class="p-button-raised p-button-secondary p-mr-2"
          />
          <Button
            icon="pi pi-download"
            v-tooltip.top="'Download Document'"
            :label="slotProps.data.fileType == 'Nda' ? 'NDA' : 'BAA'"
            @click="handleDownload(slotProps.data.fileInfo)"
            class="p-button-raised p-button-secondary p-mr-2"
          />
        </template>
      </Column>
      <Column :headerClass="'thheader'"  header="Actions">
        <template #body="slotProps">
          <Button
            icon="pi pi-plus"
            v-tooltip.top="'Add New Placehoder'"
            class="p-button-raised p-button-primary p-mr-2"
            @click="addPlaceholder(slotProps.data, 'addphmode')"
          />
          <Button
            icon="pi pi-pencil"
            v-tooltip.top="'Edit Template'"
            @click="editTemplate(slotProps.data)"
            class="p-button-raised p-button-info p-mr-2"
          />
          <Button
            icon="pi pi-trash"
            v-tooltip.top="'Deactivate Template'"
            v-if="slotProps.data.isActive"
            @click="handleactivateDeactivateTemplate(slotProps.data)"
            class="p-button-raised p-button-warning p-mr-2"
          />
          <Button
            v-if="!slotProps.data.isActive"
            icon="pi pi-check-circle"
            v-tooltip.top="'Activate Template'"
            @click="handleactivateDeactivateTemplate(slotProps.data)"
            class="p-button-raised p-button-success p-mr-2"
          />
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="placeholdertable">
          <DataTable :value="slotProps.data.placeHolders">
            <Column
              field="title"
              header="Title"
              sortable
              :headerClass="'nthheader'"
            ></Column>
            <Column
              field="slug"
              header="Slug"
              sortable
              :headerClass="'nthheader'"
            ></Column>
            <Column
              field="type"
              header="Type"
              sortable
              :headerClass="'nthheader'"
            >
              <template #body="slotProps">
                {{ formatType(slotProps.data.type) }}
              </template>
            </Column>
            <Column
              field="customField"
              header="Custom Field"
              :headerClass="'nthheader'"
              sortable
            >
              <template #body="slotProps">{{
                formatCustomFields(slotProps.data.customField)
              }}</template>
            </Column>
            <Column
              field="values"
              header="Values"
              sortable
              :headerClass="'nthheader'"
            ></Column>
            <Column :headerClass="'nthheader'">
              <template #body="slotPropPlaceholder">
                <Button
                  icon="pi pi-pencil"
                  v-tooltip.top="'Edit Placeholder'"
                  @click="editPlaceholder(slotProps, slotPropPlaceholder)"
                  class="p-button-rounded p-button-help p-mr-2"
                />
                <Button
                  icon="pi pi-trash"
                  v-tooltip.top="'Deactivate Placeholder'"
                  v-if="slotPropPlaceholder.data.isActive"
                  @click="
                    handleactivateDeactivatePlaceholder(
                      slotProps,
                      slotPropPlaceholder
                    )
                  "
                  class="p-button-rounded p-button-warning p-mr-2"
                />
                <Button
                  v-if="!slotPropPlaceholder.data.isActive"
                  icon="pi pi-check-circle"
                  v-tooltip.top="'Activate Placeholder'"
                  @click="
                    handleactivateDeactivatePlaceholder(
                      slotProps,
                      slotPropPlaceholder
                    )
                  "
                  class="p-button-rounded p-button-success p-mr-2"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
    <Dialog
      v-if="baandatemplateDialog"
      maximizable
      :visible.sync="baandatemplateDialog"
      :style="{ width: '70vw' }"
      :closable="false"
      :header="editTemplateMode ? 'Edit Template' : 'Add Template'"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field p-col p-mt-3">
        <span class="p-float-label">
          <InputText
            id="title"
            v-model.trim="template.title"
            :class="{ 'p-invalid': submitted && !template.title }"
          />
          <label for="name">Title</label>
        </span>
        <span
          :class="{ 'p-invalid': !template.title }"
          v-if="submitted && !template.title"
          >Name is required.</span
        >
      </div>
      <div class="p-field p-col">
        <span class="p-float-label">
          <Dropdown
            appendTo="body"
            v-model="template.countrySlug"
            :class="{ 'p-invalid': submitted && !template.countrySlug }"
            :options="ctryoptions"
            :filter="true"
            optionLabel="title"
          >
            <template #value="slotProps">
              <div class="" v-if="slotProps.value">
                {{
                  slotProps.value.title
                    ? slotProps.value.title
                    : slotProps.value
                }}
              </div>
              <div class="p-field" v-else>
                {{ slotProps.placeholder }}
              </div>
            </template>
          </Dropdown>
          <label for="countries">Select Country</label>
        </span>
        <span
          :class="{ 'p-invalid': !template.countrySlug }"
          v-if="submitted && !template.countrySlug"
          >Country is required.</span
        >
      </div>

      <div class="p-field p-col" v-if="addTemplateMode">
        <span class="p-float-label">
          <InputText
            id="slug"
            v-model.trim="template.slug"
            :class="{ 'p-invalid': submitted && !template.slug }"
          />
          <label for="name">Slug</label>
        </span>
        <span
          :class="{ 'p-invalid': !template.slug }"
          v-if="submitted && !template.slug"
          >Slug is required.</span
        >
      </div>
      <div class="p-field p-col">
        <span class="p-float-label">
          <Dropdown
            appendTo="body"
            v-model="template.fileType"
            :options="FileTypeoptions"
            optionLabel="title"
            @change="handleChange"
            :class="{ 'p-invalid': submitted && !template.fileType }"
          >
            <template #value="slotProps">
              <div class="p-dropdown-car-value" v-if="slotProps.value">
                <span>
                  {{
                    slotProps.value["title"]
                      ? slotProps.value["title"]
                      : slotProps.value
                  }}
                </span>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
          </Dropdown>
          <label for="countries">Select FileType</label>
        </span>
        <span
          :class="{ 'p-invalid': !template.fileType }"
          v-if="submitted && !template.fileType"
          >FileType is required.</span
        >
      </div>
      <div class="p-field p-col">
        <div>
          <b-form-file
            v-model="template.uploadFile"
            accept=".docx, .doc"
            placeholder="Choose a template file or drop it here..."
            drop-placeholder="Drop file here..."
            :class="{
              'p-invalid':
                submitted &&
                (!template.uploadFile || template.fileInfo) &&
                (template.uploadFile || !template.fileInfo),
            }"
          ></b-form-file>
          <span
            class="p-error"
            v-if="
              submitted &&
              (!template.uploadFile || template.fileInfo) &&
              (template.uploadFile || !template.fileInfo)
            "
            >Upload is required.</span
          >
        </div>
      </div>
      <div class="p-col">
        <h6 v-if="this.placeHolders">Default PlaceHolders</h6>
        <DataTable :value="this.placeHolders" v-if="this.placeHolders">
          <Column field="title" header="Title" sortable></Column>
          <Column field="slug" header="Slug" sortable></Column>
          <Column field="type" header="Type" sortable></Column>
        </DataTable>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-warning"
          @click="hideDialog"
        />
        <Button
          label="Save"
          icon="pi pi-arrow-circle-up"
          @click="handleSave()"
        />
      </template>
    </Dialog>
    <Dialog
      maximizable
      v-if="placeholdersDialog"
      :visible.sync="placeholdersDialog"
      :closable="false"
      :style="{ width: '60vh' }"
      header="Placeholders"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field p-col p-mt-4">
        <span class="p-float-label">
          <InputText
            id="title"
            v-model.trim="placeHolder.title"
            :class="{ 'p-invalid': plsubmitted && !placeHolder.title }"
          />
          <label for="name">Title</label>
        </span>
        <span class="p-error" v-if="plsubmitted && !placeHolder.title"
          >Title is required.</span
        >
      </div>
      <div class="p-field p-col" v-if="addPlaceholderFlag">
        <span class="p-float-label">
          <InputText
            id="title"
            v-model.trim="placeHolder.slug"
            :class="{ 'p-invalid': plsubmitted && !placeHolder.slug }"
          />
          <label for="Slug">Slug</label>
        </span>
        <span class="p-error" v-if="plsubmitted && !placeHolder.slug"
          >Slug is required.</span
        >
      </div>
      <div class="p-field p-col">
        <span class="p-float-label">
          <InputText id="description" v-model.trim="placeHolder.description" />
          <label for="name">Description</label>
        </span>
      </div>
      <div class="p-field p-col">
        <span class="p-float-label">
          <Dropdown
            appendTo="body"
            v-model="placeHolder.type"
            :options="placeholderoptions"
            optionLabel="Type"
            :filter="true"
            :class="{ 'p-invalid': plsubmitted && !placeHolder.type }"
          >
            <template #value="slotProps">
              <div class="p-dropdown-car-value" v-if="slotProps.value">
                <span>
                  {{
                    slotProps.value.text
                      ? slotProps.value.text
                      : slotProps.value
                  }}
                </span>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="p-dropdown-car-option">
                <span>{{ slotProps.option.text }}</span>
              </div>
            </template>
          </Dropdown>
          <label for="name">Placeholder Type</label>
        </span>
        <span class="p-error" v-if="plsubmitted && !placeHolder.type"
          >Placeholder Type is required.</span
        >
      </div>
      <div class="p-field p-col">
        <span class="p-float-label">
          <InputText
            id="DefaultValue"
            v-model.trim="placeHolder.defaultValue"
          />
          <label for="name">DefaultValue</label>
        </span>
      </div>
      <div class="p-field p-col" v-if="placeHolder.type.text == 'Select'">
        <span class="p-float-label">
          <InputText id="DefaultValue" v-model.trim="placeHolder.values" />
          <label for="name">Values</label>
        </span>
      </div>
      <div class="p-field p-col">
        <span class="p-float-label">
          <Dropdown
            appendTo="body"
            v-model.trim="placeHolder.customField"
            :options="customFields"
            optionLabel="name"
            :filter="true"
          >
            <template #value="slotProps">
              <div class="p-dropdown-car-value" v-if="slotProps.value">
                <span>
                  {{
                    slotProps.value.name
                      ? slotProps.value.name
                      : slotProps.value
                  }}
                </span>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="p-dropdown-car-option">
                <span>{{ slotProps.option.name }}</span>
              </div>
            </template>
          </Dropdown>
          <label for="name">Custom Field</label>
        </span>
      </div>

      <div class="p-field p-col">
        <label class="p-mb-3">Is Readonly</label>
        <div class="p-formgrid p-grid">
          <div class="p-field-radiobutton p-col-6">
            <RadioButton
              name="IsReaonly"
              value="true"
              v-model="placeHolder.isReadonly"
            />
            <label for="yes">Yes</label>
          </div>
          <div class="p-field-radiobutton p-col-6">
            <RadioButton
              name="IsReaonly"
              value="false"
              v-model="placeHolder.isReadonly"
            />
            <label for="No">No</label>
          </div>
        </div>
      </div>
      <div class="p-field p-col">
        <label class="p-mb-3">Is Optional</label>
        <div class="p-formgrid p-grid">
          <div class="p-field-radiobutton p-col-6">
            <RadioButton
              name="IsReaonly"
              value="true"
              v-model="placeHolder.isOptional"
            />
            <label for="yes">Yes</label>
          </div>
          <div class="p-field-radiobutton p-col-6">
            <RadioButton
              name="IsReaonly"
              value="false"
              v-model="placeHolder.isOptional"
            />
            <label for="No">No</label>
          </div>
        </div>
      </div>
      <div class="p-field p-col">
        <label class="p-mb-3">Is Customfield</label>
        <div class="p-formgrid p-grid">
          <div class="p-field-radiobutton p-col-6">
            <RadioButton
              name="IsReaonly"
              value="true"
              v-model="placeHolder.isCustomfield"
            />
            <label for="yes">Yes</label>
          </div>
          <div class="p-field-radiobutton p-col-6">
            <RadioButton
              name="IsReaonly"
              value="false"
              v-model="placeHolder.isCustomfield"
            />
            <label for="No">No</label>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-warning"
          @click="hideplaceholderDialog()"
        />
        <Button
          label="Save"
          icon="pi pi-arrow-circle-up"
          @click="handlePlaceholderUpsert()"
        />
      </template>
    </Dialog>
    <Dialog
      v-if="confirmationDialog"
      maximizable
      :visible.sync="confirmationDialog"
      :closable="false"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span
          >Are you sure you want to
          {{ template.isActive ? "Deactivate" : "Activate" }}
          <b>{{ template.title }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideconDialog"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="activateDeactivateTemplate(template)"
        />
      </template>
    </Dialog>
    <Dialog
      maximizable
      v-if="confirmationPlaceholderDialog"
      :closable="false"
      :visible.sync="confirmationPlaceholderDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span
          >Are you sure you want to
          {{ selectedplaceHolder.isActive ? "Deactivate" : "Activate" }}
          <b>{{ selectedplaceHolder.title }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="confirmationPlaceholderDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="activateDeactivatePlaceholder()"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import _ from "lodash";
export default {
  name: "BaaNdaTemplateConfig",
  created() {
    let that = this;
    that.fetchCustomFields();
    that.fetchTemplates();
    that.fetchCountries();
  },
  components: {},
  methods: {
    fetchCountries() {
      let that = this;
      this.$store.dispatch("country/fetchCountries").then(() => {
        that.countryResponse = this.$store.getters["country/getCountries"];
        that.ctryoptions = _.clone(that.countryResponse);
      });
    },
    handleChange() {
      let that = this;
      let fileType = that.template.fileType.Val;
      this.placeHolders =
        fileType == "Baa" ? that.BAAPlaceHolders : that.NDAPlaceHolders;
    },
    hideDialog() {
      let that = this;
      that.baandatemplateDialog = false;
      that.placeholdersDialog = false;
      that.placeHolders = null;
      that.submitted = false;
    },
    handleSave() {
      let that = this;
      that.submitted = true;
      if (
        that.template.title &&
        (that.template.uploadFile || that.template.fileInfo)
      ) {
        that.isloading = true;
        that.placeholdersDialog = false;
        that.baandatemplateDialog = false;
        that.template.countrySlug = that.template.countrySlug.slug
          ? that.template.countrySlug.slug
          : this.formatCountryTitle(that.template.countrySlug);

        const formData = new FormData();
        if (that.template.id) {
          formData.append("Id", that.template.id);
        }
        if (that.template.isActive != undefined) {
          formData.append("IsActive", that.template.isActive);
        }
        formData.append(
          "FileType",
          that.template.fileType.Val
            ? that.template.fileType.Val
            : that.template.fileType
        );
        formData.append("Title", that.template.title);
        formData.append("countrySlug", that.template.countrySlug);
        formData.append("Slug", that.template.slug);
        if (that.template.uploadFile) {
          formData.append("UploadFile", that.template.uploadFile);
        }
        if (that.template.fileInfo) {
          formData.append("FileInfo.FileId", that.template.fileInfo.fileId);
          formData.append("FileInfo.WebURL", that.template.fileInfo.webURl);
          formData.append(
            "FileInfo.OriginalName",
            that.template.fileInfo.originalName
          );
        }
        that.placeholders = that.placeHolders || that.template.placeHolders;
        this.placeholders.forEach((plholders, index) => {
          formData.append(`PlaceHolders[${index}].Type`, plholders.type);
          formData.append(
            `PlaceHolders[${index}].DefaultValue`,
            plholders.defaultValue ? plholders.defaultValue : ""
          );
          formData.append(
            `PlaceHolders[${index}].IsReadonly`,
            plholders.isReadonly
          );
          formData.append(
            `PlaceHolders[${index}].IsOptional`,
            plholders.isOptional
          );
          formData.append(`PlaceHolders[${index}].Slug`, plholders.slug);
          formData.append(`PlaceHolders[${index}].Title`, plholders.title);
          formData.append(
            `PlaceHolders[${index}].Description`,
            plholders.description ? plholders.description : ""
          );
          formData.append(
            `PlaceHolders[${index}].IsActive`,
            plholders.isActive
          );
          formData.append(
            `PlaceHolders[${index}].IsCustomfield`,
            plholders.isCustomfield
          );
          formData.append(
            `PlaceHolders[${index}].CustomField`,
            parseInt(plholders.customField)
          );
          formData.append(
            `PlaceHolders[${index}].Order`,
            plholders.order ? parseInt(plholders.order) : index + 1
          );
        });
        this.$store
          .dispatch("baanda/upsertBaaNdaTemplate", formData)
          .then(() => {
            that.isloading = false;
            that.placeHolders = null;
            that.fetchTemplates();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    onRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Template Expanded",
        detail: event.data.title,
        life: 3000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Template Collapsed",
        detail: event.data.title,
        life: 3000,
      });
    },
    handleAddTemplate() {
      this.template = {};
      this.submitted = false;
      this.editTemplateMode = false;
      this.addTemplateMode = true;
      this.template.placeHolders = this.DefaultPlaceholders;
      this.baandatemplateDialog = true;
    },
    fetchTemplates() {
      var that = this;
      that.isloading = true;
      this.$store
        .dispatch("baanda/fetchBaaNdaTemplates")
        .then(() => {
          that.response = this.$store.getters["baanda/getBaaNdaTemplates"];
          that.baandaTemplates = _.clone(that.response);
          that.isloading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addPlaceholder(template) {
      let that = this;
      that.placeholdersDialog = true;
      that.addPlaceholderFlag = true;
      that.template = template;
      that.placeHolder = {
        type: "",
        defaultValue: "",
        isReadonly: "false",
        isOptional: "false",
        slug: "",
        title: "",
        description: "",
        isActive: true,
        isCustomfield: "false",
        customField: "",
        values: "",
        order: "",
      };
      that.fetchCustomFields();
      that.editPlaceholderFlag = false;
    },
    hideplaceholderDialog() {
      let that = this;
      that.placeholdersDialog = false;
    },
    handlePlaceholderUpsert() {
      let that = this;
      that.plsubmitted = true;
      if (
        that.placeHolder.title &&
        that.placeHolder.slug &&
        that.placeHolder.type
      ) {
        let trecord = _.filter(
          that.template.placeHolders,
          (m) => m.title == that.placeHolder.title
        );
        let tcrecord = _.filter(
          that.template.placeHolders,
          (m) => m.slug == that.placeHolder.slug
        );
        that.placeHolder.values = that.placeHolder.values
          ? JSON.stringify(that.placeHolder.values.split(","))
          : "";
        that.placeHolder.type = that.placeHolder.type.text
          ? that.placeHolder.type.text
          : that.placeHolder.type
          ? that.placeHolder.type
          : "";
        that.placeHolder.customField = that.placeHolder.customField.id
          ? that.placeHolder.customField.id
          : that.placeHolder.customField
          ? this.formatCustomFieldsId(that.placeHolder.customField)
          : 0;
        that.placeHolder.isCustomfield =
          that.placeHolder.isCustomfield == ("true" || true) ? true : false;
        that.placeHolder.isOptional =
          that.placeHolder.isOptional == ("true" || true) ? true : false;
        that.placeHolder.isReadonly =
          that.placeHolder.isReadonly == ("true" || true) ? true : false;
        if (that.addPlaceholderFlag) {
          that.template.placeHolders = that.template.placeHolders || [];
          that.template.placeHolders.push(that.placeHolder);
        } else {
          let setindex = that.template.placeHolders.findIndex(
            (mtmpl) => mtmpl.slug === that.placeHolder.slug
          );
          that.template.placeHolders.splice(setindex, 1, that.placeHolder);
        }
        that.isloading = true;
        that.handleSave();
      }
    },
    handleactivateDeactivateTemplate(template) {
      let that = this;
      that.template = template;
      this.confirmationDialog = true;
    },
    hideconDialog() {
      let that = this;
      that.confirmationDialog = false;
      that.submitted = false;
    },
    activateDeactivateTemplate(template) {
      let that = this;
      that.template.isActive = !template.isActive;
      this.handleSave();
      this.confirmationDialog = false;
    },
    editPlaceholder(template, placeholder) {
      let that = this;
      that.addPlaceholderFlag = false;
      that.editPlaceholderFlag = true;
      that.placeHolder = Object.assign({}, placeholder.data);
      that.template = _.clone(template.data);
      that.placeholdersDialog = true;
      that.placeHolder.values = that.placeHolder.values
        ? JSON.parse(placeholder.data.values).toString()
        : that.placeHolder.values;
      that.placeHolder.type = this.formatType(that.placeHolder.type);
      that.placeHolder.isOptional =
        that.placeHolder.isOptional == true ? "true" : "false";
      that.placeHolder.isReadonly =
        that.placeHolder.isReadonly == true ? "true" : "false";
    },
    handleactivateDeactivatePlaceholder(template, placeholder) {
      let that = this;
      that.selectedplaceHolder = placeholder.data;
      that.template = template.data;
      that.confirmationPlaceholderDialog = true;
    },
    activateDeactivatePlaceholder() {
      let that = this;
      that.selectedplaceHolder.isActive = !that.selectedplaceHolder.isActive;
      that.handleSave();
      that.confirmationPlaceholderDialog = false;
    },
    formatType(value) {
      let that = this;
      let type = _.filter(that.placeholderoptions, (mr) => mr.value === value);
      return (value = type.length ? type[0].text : value);
    },
    documentView(url) {
      window.open(url);
    },
    handleDownload(fileInfo) {
      let that = this;
      // let FileName = fileInfo.fileName;
      this.$store
        .dispatch("masterTemplate/downloadMasterTemplate", fileInfo)
        .then(() => {
          that.response = this.$store.getters[
            "masterTemplate/getDownloadMasterTemplates"
          ];
          var fileURL = window.URL.createObjectURL(
            new Blob([that.response.data], { type: "application/octet-stream" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileInfo.originalName);
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editTemplate(template) {
      console.log(template);
      this.editTemplateMode = true;
      this.addTemplateMode = false;
      template.countrySlug = this.formatCountry(template.countrySlug);
      this.template = _.clone(template);
      this.baandatemplateDialog = true;
    },
    formatCustomFields(customval) {
      let that = this;
      let type = _.filter(that.customFields, (mr) => mr.id === customval);
      return (customval = type.length ? type[0].name : "");
    },
    formatCountry(customval) {
      let that = this;
      let ctry = _.filter(that.ctryoptions, (mr) => mr.slug === customval);
      return (customval = ctry.length ? ctry[0].title : customval);
    },
    formatCountryTitle(customval) {
      let that = this;
      let ctry = _.filter(that.ctryoptions, (mr) => mr.title === customval);
      return (customval = ctry.length ? ctry[0].slug : "");
    },
    formatCustomFieldsId(vals) {
      let that = this;
      let type = _.filter(that.customFields, (mr) => mr.name === vals);
      return (vals = type.length ? type[0].id : "");
    },
    fetchCustomFields() {
      var that = this;
      this.$store
        .dispatch("masterTemplate/fetchCustomFields")
        .then(() => {
          that.response = this.$store.getters["masterTemplate/getCustomFields"];
          that.customFields = _.clone(that.response);
          console.log(that.customFields);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  props: {},
  watch: {},
  data() {
    return {
      filters: {},
      customFields: null,
      confirmationPlaceholderDialog: false,
      baandatemplateDialog: false,
      expandedRows: [],
      baandaTemplates: null,
      isloading: false,
      editTemplateMode: false,
      submitted: false,
      plsubmitted: false,
      template: {},
      confirmationDialog: false,
      placeHolder: {
        type: "",
        defaultValue: "",
        isReadonly: false,
        isOptional: false,
        slug: "",
        title: "",
        description: "",
        isActive: true,
        isCustomfield: false,
        customField: "",
        values: "",
        order: null,
      },
      addTemplateMode: false,
      placeholdersDialog: false,
      addPlaceholderFlag: false,
      FileTypeoptions: [
        { title: "BAA", Val: "Baa" },
        { title: "NDA", Val: "Nda" },
      ],
      placeholderoptions: [
        { value: 0, text: "Text" },
        { value: 1, text: "Description" },
        { value: 2, text: "Select" },
        { value: 3, text: "Bool" },
        { value: 4, text: "Email" },
        { value: 5, text: "PhNo" },
        { value: 6, text: "Date" },
      ],
      BAAPlaceHolders: [
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "entityname",
          title: "Entity Name",
          description: null,
          isCustomfield: false,
          customField: 0,
          isActive: true,
          order: null,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientaddressline",
          title: "Client Address Line",
          description: "e.g., 500 N. Meridian Street, Suite 400",
          isActive: true,
          order: null,
          isCustomfield: false,
          customField: 0,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientaddresscity",
          title: "Client Address City",
          description: "e.g., Indianapolis",
          isActive: true,
          order: null,
          isCustomfield: false,
          customField: 0,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientaddressstate",
          title: "Client Address State",
          description: "e.g., IN",
          isActive: true,
          order: null,
          isCustomfield: false,
          customField: 0,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "clientaddresszip",
          title: "Client Address Zip",
          description: "e.g., 46204",
          isActive: true,
          order: null,
          isCustomfield: false,
          customField: 0,
        },
      ],
      NDAPlaceHolders: [
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "companyname",
          title: "Company Name",
          description: null,
          isActive: true,
          order: null,
          isCustomfield: false,
          customField: 0,
        },

        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "companyaddressline",
          title: "Company Address Line",
          description: "e.g., 500 N. Meridian Street, Suite 400",
          isActive: true,
          order: null,
          isCustomfield: false,
          customField: 0,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "companyaddresscity",
          title: "Company Address City",
          description: "e.g., Indianapolis",
          isActive: true,
          order: null,
          isCustomfield: false,
          customField: 0,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "companyaddressstate",
          title: "Company Address State",
          description: "e.g., IN",
          isActive: true,
          order: null,
          isCustomfield: false,
          customField: 0,
        },
        {
          type: "Text",
          defaultValue: null,
          isReadonly: false,
          isOptional: false,
          slug: "companyaddresszip",
          title: "Company Address Zip",
          description: "e.g., 46204",
          isActive: true,
          order: null,
          isCustomfield: false,
          customField: 0,
        },
      ],
      placeHolders: null,
      selectedplaceHolder: null,
      ctryoptions: null,
    };
  },
};
</script>

<style scoped>
</style>