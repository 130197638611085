/* eslint-disable no-unused-vars */
import { getServiceConfigs, postServiceConfig, putServiceConfig } from '../../services/ServiceConfigs.Service';

const state = {
    serviceconfigs: {}
}
const getters = {
    getServiceConfigs(state) {
        return state.serviceconfigs
    }
}
const actions = {
    saveServiceConfig({ commit }, serviceconfig) {
        return new Promise((resolve, reject) => {
            postServiceConfig(serviceconfig).then(response => {
                if (response.status== 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error)
            });
        });
    },
    fetchServiceConfigs({ commit }) {
        return new Promise((resolve, reject) => {
            getServiceConfigs().then(response => {
                if (response.status ==200) {
                    commit('setServiceConfigs', response.data.data);
                    resolve();
                }
            }).catch(error => {
                reject(error);
            })
        });
    },
    updateServiceConfig({ commit }, serviceconfig) {
        return new Promise((resolve, reject) => {
            putServiceConfig(serviceconfig).then(response => {
                if (response.status == 200) {
                    resolve();
                }
            }).catch(error => {
                reject(error);
            })
        });
    }
}
const mutations = {
    setServiceConfigs(state, response) {
        state.serviceconfigs = response;
    },

}

export default {
    namespaced: true,
    actions,
    mutations,
    state,
    getters
}